import TemplatedPermissions from 'models/policy_generator/templated_permissions'


// Pre-built Customized permission set that acts as a base for customization
export default function(){
  TemplatedPermissions.call(this)
  this.templates = [
    {
      name: 'RepresentableField',
      permissions: [
        { target_id: 'a4s_employee_ssn', target_type: 'RepresentableField',  action: 'view',   grant: false },
        { target_id: 'a4s_employee_ssn', target_type: 'RepresentableField',  action: 'update',   grant: false },
        { target_id: null,               target_type: 'RepresentableField',  action: 'view',   grant: true },
        { target_id: null,               target_type: 'RepresentableField',  action: 'update', grant: false },
      ],
    },
    {
      name: 'Document',
      permissions: [
        { target_id: 'w4', target_type: 'Document', action: 'view_submission',    grant: false },
        { target_id: 'w4', target_type: 'Document', action: 'unlock_submission',  grant: false },
        { target_id: 'w4', target_type: 'Document', action: 'accept_submission',  grant: false },
        { target_id: 'w4', target_type: 'Document', action: 'upload_submission',  grant: false },
        { target_id: 'w4', target_type: 'Document', action: 'edit_attachments',   grant: false },

        { target_id: 'i9', target_type: 'Document', action: 'view_submission',    grant: false },
        { target_id: 'i9', target_type: 'Document', action: 'unlock_submission',  grant: false },
        { target_id: 'i9', target_type: 'Document', action: 'accept_submission',  grant: false },
        { target_id: 'i9', target_type: 'Document', action: 'upload_submission',  grant: false },
        { target_id: 'i9', target_type: 'Document', action: 'edit_attachments',   grant: false },
        { target_id: 'i9', target_type: 'Document', action: 'countersign_submission', grant: false },

        { target_id: 'w9', target_type: 'Document', action: 'view_submission',    grant: false },
        { target_id: 'w9', target_type: 'Document', action: 'unlock_submission',  grant: false },
        { target_id: 'w9', target_type: 'Document', action: 'accept_submission',  grant: false },
        { target_id: 'w9', target_type: 'Document', action: 'upload_submission',  grant: false },
        { target_id: 'w9', target_type: 'Document', action: 'edit_attachments',   grant: false },

        { target_id: null, target_type: 'Document', action: 'view_submission',    grant: true },
        { target_id: null, target_type: 'Document', action: 'unlock_submission',  grant: false },
        { target_id: null, target_type: 'Document', action: 'accept_submission',  grant: false },
        { target_id: null, target_type: 'Document', action: 'upload_submission',  grant: false },
        { target_id: null, target_type: 'Document', action: 'edit_attachments',   grant: false },
      ],
    },
  ]
}
