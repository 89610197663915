import RepresentableField from 'models/representable_field'

export default class Filter extends Backbone.Model.extend({

}) {
  parse(data) {
    return {
      field: new RepresentableField(data.field),
      op: data.op,
      value: data.value,
    }
  }

  validate() {
    // This can be set by UI if backend informs us that a field is no longer available
    if (this.get('fieldError')) {
      return 'This field is no longer available. Select a different field or remove this filter.'
    }

    if (_.isEmpty(this.get('field')) || _.isEmpty(this.get('field').get('field_key')))
    {return 'You must select a field to filter.'}

    if (_.isEmpty(this.get('op')))
    {return 'You must select an operator from the dropdown.'}

    if (!this.isUnaryOp() && _.isEmpty(this.get('value')))
    {return 'You must enter a value to use with this filter.'}
  }

  // Overriding toJSON so that instead of returning the whole RepresentableField structure, we just return the field
  // key in its place, which is what the backend API expects.
  toJSON() {
    const attrs = _.clone(this.attributes)
    attrs['field'] = attrs['field'].get('field_key')
    return attrs
  }

  // Whether or not the current op is unary (does not need a value).
  // Currently, only the following ops are unary:
  //    'is blank' (blank / null)
  //    'is not blank' (present / not_null)
  //
  // Returns Boolean
  isUnaryOp() {
    const UNARY_OPS = _(['present', 'blank', 'null', 'not_null'])
    return UNARY_OPS.contains(this.get('op'))
  }
}
