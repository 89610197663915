import PrerenderedTableCollectionView from 'views/common/prerendered_table_collection_view'
import EmployeeGroupRowItemView from 'views/employee_groups/employee_group_row_item_view'

export default PrerenderedTableCollectionView.extend({
  childView: EmployeeGroupRowItemView,

  onChildviewEmployeeGroupToggleArchive: function(args) {
    const value = !args.model.get("archived")
    App.vent.trigger(`employee_groups:${value ? 'archive' : 'unarchive'}`, args.model.id)
  },

  onChildviewEmployeeGroupDelete: function(args) {
    App.vent.trigger('employee_groups:delete', args.model.id)
  },

  onChildviewModelChangeArchived: function() {
    this.getDataTableObject().draw()
  },
})
