<template>
  <div>
    <PageHeader
      title="Bulk Staff Update"
    />
    <BulkStaffUpdateWizard />
  </div>
</template>
<script>
  import BulkStaffUpdateWizard from 'components/user_updates/BulkStaffUpdateWizard'
  import PageHeader from 'components/common/PageHeader'

  export default {
    name: 'the-user-updates-new-root',
    components: {
      BulkStaffUpdateWizard,
      PageHeader,
    },
    provide() {
      return {
        fields: this.fields,
      }
    },
    props: {
      fields: {
        type: Array,
        required: true,
      },
    },
  }
</script>
