<template>
  <tr>
    <td>
      <WbSimpleDateTime :value="bulkUpdateItem.created_at" />
    </td>
    <td>
      --
    </td>
    <td
      ref="sfaffCount"
    >
      {{ userCountText }}
    </td>
    <td
      ref="status"
      class="text-strong"
    >
      {{ status }}
    </td>
  </tr>
</template>

<script>
  import WbSimpleDateTime from 'components/common/WbSimpleDateTime'

  export default {
    name: 'user-update-table-row',
    components: {
      WbSimpleDateTime,
    },
    props: {
      bulkUpdateItem: {
        type: Object,
        required: true,
      },
    },
    computed: {
      status () {
        return this.bulkUpdateItem.current_state === 'complete' ? 'Complete' : 'Incomplete'
      },
      userCountText () {
        return `${this.bulkUpdateItem.user_count ?? 0} staff`
      },
    },
  }
</script>
