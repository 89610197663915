/* global Turbolinks */

import UdfFieldTableRowItemView from './udf_field_table_row_item_view'
import PrerenderedTableCollectionView from 'views/common/prerendered_table_collection_view'
import Util from 'lib/util'

export default class UdfFieldsListTableCollectionView extends PrerenderedTableCollectionView.extend({
  childView: UdfFieldTableRowItemView,
}) {
  onChildviewFieldEdit(args) {
    Turbolinks.visit(`${args.model.url()}/edit`)
  }

  onChildviewFieldDelete(args) {
    args.model.destroy({
      wait: true,
      wbGenericFailureMsg: "Sorry, we couldn't delete this field.",
    }).done(function(data, _status, xhr) {
      $(`._udf_${data.id}`).remove() // Remove field from Preview on delete
      Util.showAjaxFlashNotice(xhr)
    })
  }
}
