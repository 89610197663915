import Animations from 'lib/util/animations'
import PrerenderedTableRowItemView from 'views/common/prerendered_table_row_item_view'

export default PrerenderedTableRowItemView.extend({
  behaviors: {
    TableRowClickable: { checkForModel: true },
  },

  modelEvents: {
    'change:accepted': 'onModelAcceptedChanged',
    'change:tags': 'onModelTagsChanged',
  },

  _ui: {
    statusIndicatorCell: 'td.status-indicator',
    tagsList: '.tags-list',
  },
  ui: function() {
    return _.extend({}, App.PrerenderedTableRowItemView.prototype.ui, this._ui)
  },

  onRowClick: function() {
    App.vent.trigger('inbox:submission:show', this.model)
  },

  onModelAcceptedChanged: function() {
    // Change the status indicator
    const accepted = this.model.get("accepted")
    let icon_html
    if (accepted == true) {
      icon_html = '<i class="fa fa-check text-success" title="Accepted"></i>'
    } else {
      icon_html = '<i class="fa fa-ban text-danger" title="Rejected"></i>'
    }

    this.ui.statusIndicatorCell.html(icon_html)
    this.ui.statusIndicatorCell.find('i').tooltip()

    Animations.temporaryHighlight($('td', this.$el))
  },

  // Update the tags shown
  onModelTagsChanged: function() {
    this.ui.tagsList.empty()

    const tagsValue = this.model.get('tags')
    const tags = (typeof tagsValue === 'string') ? _(tagsValue.split(',')) : _(tagsValue)

    const self = this
    tags.each(function(tag) {
      $('<span>').attr('class', 'label label-tag').text(tag).appendTo(self.ui.tagsList)
    })
  },
})
