import UserImportsController from 'controllers/user_imports_controller'

// eslint-disable-next-line no-unused-vars
export default function(Sector, App, Backbone, Marionette, $, _) {
  Sector.addInitializer(function() {
    Sector.controller = new UserImportsController()

    Sector.listenTo(App.vent, 'user_imports:delete', function (id) {
      Sector.controller.deleteImport(id)
    })
  })
}
