import Routes from 'lib/routes'
import ApiOperation from 'lib/api_operation'

export default Backbone.Model.extend({

  destroy: function () {
    return new ApiOperation({
      path: Routes.employee_supplemental_file_path({ employee_id: this.get('employee_id'), id: this.id }),
      httpMethod: 'DELETE',
      ajaxOptions: {
        wbGenericFailureMsg: "Sorry, we could not delete this file.",
      },
    }).done((_data, _status, xhr) => {
      App.Util.showAjaxFlashNotice(xhr)
    })
  },
})
