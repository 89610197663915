/* global Marionette */
/* global NProgress */

// If a form is set up to be submitted remotely via Rails UJS (i.e. data-remote="true"), include this behavior in the
// View to get triggers when the AJAX responses are received.
//
// Options
//
//  wbGenericFailureMsg (String) - passed to $.ajax options for global error handling (see default in code below)
//
// Callbacks
//
//  onRemoteFormSuccess
//  onRemoteFormError
//  onRemoteFormComplete
export default Marionette.Behavior.extend({
  onRender: function() {
    this._bindAjaxCallbacks()
  },

  _bindAjaxCallbacks: function() {
    const view = this.view;

    // Unbind anything, incase this is a re-render
    ['ajax:beforeSend', 'ajax:success', 'ajax:error', 'ajax:complete'].forEach(eventStr => this.$el.off(eventStr))

    this.$el.on('ajax:beforeSend', (_e, _xhr, settings) => {
      settings.wbGenericFailureMsg = this.options.wbGenericFailureMsg || "Sorry, we couldn't save your changes."
    })

    this.$el.on('ajax:success', function(e, data, status, xhr) {
      view.triggerMethod('remoteForm:success', e, data, status, xhr)
    })

    this.$el.on('ajax:error', function(e, xhr) {
      view.triggerMethod('remoteForm:error', e, xhr)
    })

    this.$el.on('ajax:complete', function() {
      NProgress.done()
      view.triggerMethod('remoteForm:complete')
    })
  },
})
