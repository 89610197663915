// XHR Pool
// Keep track of every AJAX request. If any are outstanding during a Turbolinks page change, abort it.
// This prevents callbacks making unpredicatable changes (e.g. rendering destroyed elements) if the page is changed
// while a request is in progress.
const xhrPool = [] // Array of every active AJAX request

// Identify when AJAX started
$(document).ajaxSend(function(_event, xhr){
  xhrPool.push(xhr)
})

// Identify when AJAX ended
$(document).ajaxComplete(function(_event, xhr){
  // Find the XHR request in the pool, and set it to Null
  const index = _.indexOf(xhrPool, xhr)
  if (index >= 0){ xhrPool.splice(index, 1) }
})

// Turbolinks page change started
$(document).on('page:fetch',   function() {
  for (let i = 0; i < xhrPool.length; i++){
    const xhr = xhrPool[i]
    if (xhr){ xhr.abort() }
  }
})
// End XHR Pool
