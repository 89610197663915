import HalApiConcern from 'models/concerns/hal_api_concern'

const Report = Backbone.Model.extend({
  urlRoot: "/reports",
  defaults: {
    name: null,
    description: null,
    filters: new Array(),
    columns: new Array(),
  },

  parse: function(data) {
    this.parseHAL(data)

    // Deep initialize filters
    const filtersArray = $.map(data.filters || [], function(filter) {
      return new App.Models.Filter(filter, { parse: true })
    })
    data['filters'] = new Backbone.Collection(filtersArray)

    // Deep initialize columns
    const columnsArray = $.map(data.columns || [], function(field) {
      return new App.Models.RepresentableField(field)
    }) 
    data['columns'] = new Backbone.Collection(columnsArray)

    return data
  },

  toJSON: function() {
    const attrs = _.clone(this.attributes)
    attrs['columns'] = attrs['columns'].map(function(field) {
      return field.get('field_key')
    })
    return attrs
  },

  // NOTES: 
  //   * Don't validate `name` here. It is a required attribute for saving but not for merely running reports.
  //   * The validationMessage returned here is expected to be suffixed at the end of a sentence, e.g. 'Before saving this report, ...'
  //     As such, the leading word should be lowercased.
  validate: function() {
    if (_.isEmpty(this.get('columns'))) {
      return 'you must select at least one field to be outputted. Click on "Add a Field" underneath "Output Data."'
    }
  },
})
$.extend(Report.prototype, HalApiConcern)
export default Report
