import { createVueApp, mountApp } from 'lib/vue/createVueApp'
import rootComponents from 'components/rootComponents'

function getRootComponentFromName (componentName) {
  return Object.values(rootComponents)
    .find(c => {
      return c.name === componentName
    })
}

export default function (componentName, {
  pageContext,
  initialData,
  propsData,
}) {
  const component = getRootComponentFromName(componentName)

  const app = createVueApp(component, {
    pageContext,
    initialData,
    propsData,
  })

  const mountPoint = document.querySelector(`component[id='${componentName}']`)
  const vNode = mountApp(app, mountPoint)

  return vNode
}
