<template>
  <div class="wb-search-input form-group">
    <label class="small" ref="label" v-if="!!label">
      {{ label }}
    </label>
    <div class="input-group">
      <span class="input-group-addon">
        <i class="fa fa-search" />
      </span>
      <div class="d-flex align-items-center">
        <input
          ref="input"
          class="form-control rounded-0"
          type="search"
          :value="value"
          @input="handleInput"
          :placeholder="placeholder"
          :autofocus="autofocus"
        />
        <button
          v-if="value"
          class="btn-icon"
          @click="handleClearInput"
          title="Clear search input"
        >
          <WbIcon type="close" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import Constants from 'lib/constants'
  import { throttle } from 'underscore'
  import WbIcon from 'components/common/WbIcon'

  export default {
    name: 'wb-search-input',
    components: { WbIcon },
    props: {
      label: {
        type: String,
        default: 'Search',
      },

      placeholder: {
        type: String,
        default: 'Search',
      },

      autofocus: {
        type: Boolean,
        default: true,
      },

      value: {
        required: true,
      },

      throttle: {
        type: Boolean,
        default: false,
      },
    },

    created() {
      this.handleInput = 
        this.throttle ? throttle(this.handleInput, Constants.DEFAULT_REQUEST_THROTTLE) :
        this.handleInput
    },

    methods: {
      handleClearInput() {
        this.$emit('input', '')
      },
      handleInput(e) {
        const inputValue = e.target.value.trim()
        if (inputValue.length == 0 || inputValue.length >= Constants.MIN_QUERY_LENGTH) {
          this.$emit('input', inputValue)
        }
      },
    },
  }
</script>
