import EmployeeGroupsController from 'controllers/employee_groups_controller'

// eslint-disable-next-line no-unused-vars
export default function(Sector, App, Backbone, Marionette, $, _) {
  Sector.addInitializer(function() {
    Sector.controller = new EmployeeGroupsController()

    Sector.listenTo(App.vent, 'employee_groups:archive', function(id) {
      Sector.controller.toggleArchiveEmployeeGroup(id, true)
    })

    Sector.listenTo(App.vent, 'employee_groups:unarchive', function(id) {
      Sector.controller.toggleArchiveEmployeeGroup(id, false)
    })

    Sector.listenTo(App.vent, 'employee_groups:delete', function(id) {
      Sector.controller.deleteEmployeeGroup(id)
    })
  })

  Sector.listenTo(App.globalVent, 'employee_groups:new:inline', function(options) {
    const $multiselectEl = Marionette.getOption(options, 'multiselectEl')

    bootbox.prompt("Enter a name for your new employee group:", function(result) {
      if (result === null)
      {return}

      if ($multiselectEl) {
        // we already have that option? Go ahead and select it
        let existingOption = false
        $('option', $multiselectEl).each(function() {
          if ($(this).text().toLowerCase().indexOf(result.toLowerCase()) != -1) {
            $multiselectEl.multiselect('select', $(this).val())
            existingOption = true
            return false
          }
        })
        if (existingOption) {return}
      }

      const empGroup = new App.Models.EmployeeGroup({ name: result })
      empGroup.save({
        wait: true,
        wbGenericFailureMsg: "Sorry, we couldn't create the new employee group.",
      }).done(function() {
        if ($multiselectEl) {
          $multiselectEl.append($("<option></option>")
            .attr("value", empGroup.get("id"))
            .text(empGroup.get("name"))
            .prop("selected", "selected"))
          $multiselectEl.multiselect('rebuild')
        }
      })
    })
  })

}
