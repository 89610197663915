<template>
  <table class="table table-striped">
    <thead>
      <tr>
        <th>
          Created Date
        </th>
        <th>
          Created By
        </th>
        <th>
          Staff Count
        </th>
        <th>
          Status
        </th>
      </tr>
    </thead>
    <tbody>
      <UserUpdatesTableRow
        v-for="(bulkUpdateItem, index) in bulkUpdates"
        :key="index"
        :bulkUpdateItem="bulkUpdateItem"
      />
    </tbody>
  </table>
</template>
<script>
  import UserUpdatesTableRow from 'components/user_updates/UserUpdatesTableRow'

  export default {
    name: 'user-updates-table',
    components: {
      UserUpdatesTableRow,
    },
    props: {
      bulkUpdates: {
        type: Array,
        default: () => [],
      },
    },
  }
</script>
