/* global NProgress */

import WBRequestXHRDecorator from 'decorators/wb_request_xhr_decorator'
import Constants from 'lib/constants'
import Util from 'lib/util'
import ErrorService from 'lib/error_service'

// AJAX Global Setup
//
// $.ajax Options
//    progressBar - (Boolean) shows the NProgress progress bar at the top of the page EXCEPT when set to `false`
//    wbGenericFailureMsg - (String) short error message to show when internal server error occurs

// Defaults
//
$.ajaxSetup({
  timeout: 15000, /* ms */
})

$(document).ajaxSend((_e, _xhr, options) => {
  // Show progress bar unless explicitly asked not to or it has already been started
  const showProgress = (options.progressBar !== false)
  if (!NProgress.isStarted() && showProgress) {
    NProgress.start()
  }
})

$(document).ajaxStop(() => NProgress.done())

$(document).ajaxError((_e, xhr, settings) => {
  const xhrDecorated = WBRequestXHRDecorator(xhr, settings)

  // NOOP if aborted.
  if (xhrDecorated.isAborted()) {
    return
  }

  // Handle timeouts gracefully
  //
  if (xhrDecorated.isTimeout()) {
    const timeoutSeconds = settings.timeout / 1000
    bootbox.alert({
      title: 'Request Timed Out',
      message: `Sorry, the last operation timed out after ${timeoutSeconds} seconds. Our servers may be experiencing
                some difficulty, or it may have just been a small network hiccup.<br/><br/>
                Please try your request again, and if you continue to experience difficulty, please open a
                <a href="${Constants.SUPPORT_URL}" target="_blank">support ticket</a>.`,
      className: 'modal-danger',
      buttons: {
        ok: {
          label: 'Close',
          className: 'btn-primary',
        },
      },
    })
    return
  }

  // Handle network errors gracefully
  //
  if (xhrDecorated.isNetworkError()) {
    bootbox.alert({
      title: 'No Internet Connection',
      message: `Hmm, it doesn't look like you are connected to the Internet. Please double-check your connection and try again.`,
      className: 'modal-danger',
      buttons: {
        ok: {
          label: 'Close',
          className: 'btn-primary',
        },
      },
    })
    return
  }

  switch (xhrDecorated.status) {
  // NOOP on cases that are handled contextually.
  //
  case 201:   // "Created" is not normally an error condition, but in some cases where Rails sends a content type of
    // JSON but no actual content, jQuery "errors out" on trying to parse a blank response.
  case 409:   // Conflict. Used in a few contexts as a special kind of validation error.
  case 422:   // Unprocessable entity represents a standard form validation error.
  case 429:   // Rate limiting. Messaging should be handled within sender context.
    break

    // HTTP Unauthorized (i.e. not authenticated)
  case 401:
    NProgress.done()
    bootbox.alert({
      title: 'Session Expired',
      message: "Sorry, your session has expired due to inactivity. Click OK to Sign In again.",
      className: "modal-danger",
      callback: function() {
        window.location.reload()
      },
    })
    break

    // HTTP Forbidden (i.e. not allowed based on user permissions)
  case 403:
    NProgress.done()
    bootbox.alert({
      title: "You're not authorized",
      message: xhrDecorated.getFlashMessage('alert'),
      className: "modal-danger",
      buttons: {
        ok: { label: "Close" },
      },
    })
    break

    // Unexpected errors
    //
  case 400:   // Bad Request
  case 500:   // Internal server error
  case 502:   // Bad gateway
  case 504:   // Gateway timeouts
    // NOTE: For now, don't report 504 (backend timeouts). Re-enable this once we resolve all slow pages in the app
    if (xhrDecorated.status != 504) {
      new ErrorService('Global handler', 'AjaxError').report(xhrDecorated.getRequestMetadata())
    }

    // Only do this if it's truthy. Otherwise, we can assume that the initiator of the AJAX request intends to handle
    // the error message within their own context.
    if (settings.wbGenericFailureMsg) {
      Util.genericAjaxError(settings.wbGenericFailureMsg, xhr)
    }

    break

  case 404:   // Resource Not Found
    if (xhr.responseJSON.error) {
      Util.ajaxErrorDialog(
        xhr.responseJSON.error.message,
        xhr,
        {
          title: xhr.responseJSON.error.title,
        },
      )
    }
    break
  }
})
