import AnimUtil from 'lib/util/animations'
import Locale from 'lib/locale'

export default {
  // Converts a Hash containing a graph of form validation errors into a flattened (non deeply-nested) Hash where each
  // key corresponds to the Rails input field naming conventions. NOTE that this does not take into consideration the
  // _attributes prefix which Rails appends when using `fields_for`.
  //
  // object - the errors hash as a PJSO
  // options -
  //   namespace - String to prefix all error keys; typically this is the resource name of the form (e.g. i9_submission).
  //               If omitted, root keys will be left as-is; otherwise, each root key, KEY, will yield "namespace[KEY]".
  //   mergeWith - (PJSO) Technically, this is used internally as an accumulator, but if provided, final flattened
  //               hash will merged with this value. This value will not be manipulated in any way.
  //
  // Example
  //
  //     var errors = {
  //       title: ["empty"],
  //       songs: {
  //         "1": {
  //           title: ["empty"]
  //         }
  //       },
  //       band: {
  //         label: {
  //           title: ["empty"]
  //         }
  //       }
  //     }
  //     flattenErrors(errors, { namespace: "abum" });
  //
  //     // becomes:
  //     {
  //       "album[songs][1][title]": ["empty"],
  //       "album[title]": ["empty"],
  //       "album[band][label][title]": ["empty"],
  //     }
  //
  //
  // Returns - Object
  flattenErrors: function(object, options) {
    options = _.extend({}, {
      namespace: '',
      mergeWith: {},
    }, options)

    return _.reduce(object, function(result, value, key) {
      let newKey
      if (_.isEmpty(options.namespace)) {
        newKey = key
      } else {
        newKey = `${options.namespace}[${key}]`
      }

      if ($.isPlainObject(value)) {   // AFAIK UnderscoreJS does not provide isPlainObject, but both lodash and jQuery do
        this.flattenErrors(value, { namespace: newKey, mergeWith: result })
      } else {
        result[newKey] = value
      }
      return result
    }.bind(this), options.mergeWith)
  },

  // Shows a standardized notification that the form contains errors. Mimics SimpleForm's #error_notification method.
  // The message is prepended to the $formEl element. Hence, you should place your <FORM> tag in a reasonable location
  // where an alert block can be rendered.
  //
  // Noop if the notification element already exists (ie. it's safe to call this method blindly).
  //
  // $formEl - (jquery) the form element where the message should be prepended.
  // options -
  //   scroll - (Boolean, default 'true') scroll up to the notification
  showFormInvalidNotification: function($formEl, options) {
    options = _.extend({
      scroll: true,
      message: Locale.t('errors.form.generic'),
    }, options)

    if (options.scroll) {
      AnimUtil.scrollTo($formEl)
    }

    const $existingAlertEl = $formEl.find('.error-notification')
    if ($existingAlertEl.length > 0) {
      $existingAlertEl.text(options.message)
      return
    }

    const $errorAlertNode = $('<div>')
    $errorAlertNode.addClass('alert alert-danger error-notification fade')
      .text(options.message)
      .prependTo($formEl)
      // setTimeout because of race condition with prependTo DOM manipulation (and no way to get a callback when
      // prependTo finishes)
    setTimeout(function() { $errorAlertNode.addClass('in') }, 1)
  },

  // Clears out a standardized error notification placed on `$formEl` via showFormInvalidNotification. Noop if the
  // error notification does not exist (i.e. it's safe to call this method blindly).
  //
  // $formEl - (jQuery) the form element where the notification exists
  clearFormInvalidNotification: function($formEl) {
    let $errorAlertEl
    if (($errorAlertEl = $formEl.find('.error-notification')).length == 0) {
      return
    }

    AnimUtil.fade($errorAlertEl, false, { callback: function() {
      $errorAlertEl.remove()
    } })
  },
}
