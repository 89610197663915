import SupplementalFile from 'models/supplemental_file'
import ApiOperation from 'lib/api_operation'
import Routes from 'lib/routes'

export default Marionette.ItemView.extend({
  template: '#supplemental-file-modal-tmpl',
  ui: {
    modal: '.modal',
    finalizing: '.finalizing',
  },

  bindings: {
    'input[name="supplemental_file[file_url]"]': 'file_url',
    'input[name="supplemental_file[file_cache]"]': 'file_cache',
    'input[name="supplemental_file[file_content_type]"]': 'file_content_type',

    // Bind "Finalizing" message visibility to the "file_url" property, which indicates that the Dropzone to AWS has completed
    '.finalizing': {
      observe: 'file_url',
      visible: function(val){return val && val.length > 0},
      updateView: false,
    },

    // Bind Dropzone visibility visibility to the "file_url" property, which indicates that the Dropzone to AWS has completed
    // and the second part of the upload to Workbright has started
    form: {
      observe: 'file_url',
      visible: function(val){return !(val && val.length > 0)},
      updateView: false,
    },
  },

  initialize: function () {
    this.model = new SupplementalFile({})
  },

  onRender: function () {
    this.stickit()

    this.$el.appendTo($('body'))

    this.ui.modal.modal({
      backdrop: 'static',
      keyboard: false,
    })

    const form = this.$el.find('form')
    form.dzoperator({
      fileTypeErrorMsg: "Sorry, you must upload an accepted file type: Microsoft Office (Word, Powerpoint, Excel), Image (JPEG, PNG, GIF), iWork (Pages, Numbers, Keynote), PDF, CSV, TXT",
      dzOptions: {
        acceptedFiles: ".csv, .doc, .docx, .gif, .jpg, .jpeg, .key, .numbers, .pages, .pdf, .png, .ppt, .pptx, .rtf, .txt, .xls, .xlsx",
        presignURL: '/uploads/presign',
        previewTemplate: $("#dropzone-template").html(),
        success: () => {
          // Wait for Stickit to propagate values to model
          setTimeout(this.createFile.bind(this))
        },
      },
    })

    $.runInitializers(this.$el)
  },

  createFile: function() {
    new ApiOperation({
      path: Routes.employee_supplemental_files_path({employee_id: this.getOption('employee_id')}),
      httpMethod: 'POST',
      data: {
        supplemental_file: {
          file_url: this.model.get('file_url'),
          file_cache: this.model.get('file_cache'),
          file_content_type: this.model.get('file_content_type'),
        },
      },
      wbGenericFailureMsg: "Sorry, we couldn't add this file.",
    }).done( (data) => {
      this.$el.find('.js-dz-reset-upload').trigger('click') // reset dropzone
      this.getOption('addedCallback')(data)
    },
    ).fail(() => {
      // reset dropzone
      this.model.set('file_url', null) // trigger dz to reappear
      this.$el.find('.js-dz-reset-upload').trigger('click')
      Ladda.stopAll()
    })
  },
})
