<template>
  <div
    class="wb-multiselect wb-nested-select"
    :class="classNames"
  >
    <Multiselect
      v-model="multiSelectValue"
      :closeOnSelect="false"
      :options="options"
      :placeholder="placeholder"
      :searchable="enableSearchField"
      :trackBy="valueKey"
      label="label"
      @input="handleInput"
      ref="multiselect"
    >
      <template #beforeList v-if="groupLabel">
        <div class="group__desc">
          <div
            class="multiselect__option"
            @click="navigateOut"
          >
            {{ groupLabel }}
          </div>
        </div>
      </template>
      <template #option="props">
        <div class="option__desc group-option" v-if="props.option.items">
          <span class="option__title">{{ props.option.label }}</span>
          <span class="option__items-nav">
            <span class="option__items-count">{{ props.option.items.length }}</span>
          </span>
        </div>
        <div class="option__desc" v-else>
          <span class="option__title">{{ props.option.label }}</span>
        </div>
      </template>
    </Multiselect>
  </div>
</template>
<script>
  import Multiselect from 'vue-multiselect'
  const VARIANTS = ['button']

  export default {
    components: {
      Multiselect,
    },
    props: {
      enableSearchField: {
        type: Boolean,
        default: false,
      },
      placeholder: {
        type: String,
      },
      resetOnSelect: {
        type: Boolean,
        default: false,
      },
      tree: {
        type: Array,
        // eslint-disable-next-line vue/require-valid-default-prop
        default: [],
      },
      value: {
        type: [Number, String],
      },
      valueKey: {
        type: String,
        default: 'value',
      },
      variant: {
        type: String,
        validator: value => {
          return VARIANTS.includes(value)
        },
      },
    },
    data () {
      return {
        localValue: null,
        multiSelectValue: null,
        selectionPath: [],
      }
    },
    computed: {
      classNames () {
        const classNames = []
        
        if (this.variant === 'button') {
          classNames.push('wb-multiselect-btn')
        }
        return classNames
      },
      groupLabel () {
        return this.selectionPath[this.selectionPath.length - 1]
      },
      options () {
        return this.getOptionsFromPath(this.tree, this.selectionPath)
      },
      treeItems () {
        return this.getTreeItems(this.tree)
      },
    },
    watch: {
      value () {
        this.setLocalValue(this.value)
      },
    },
    created () {
      this.setLocalValue(this.value)
    },
    methods: {
      getOptionsFromPath (tree, path) {
        path.forEach(selection => {
          tree = tree.find(({ label }) => label === selection).items
        })

        return tree
      },
      getTreeItems (items, value = {}) {
        items.forEach(item => {
          if (item.items) {
            this.getTreeItems(item.items, value)
          } else {
            value[item[this.valueKey]] = item
          }
        })
        return value
      },
      handleInput (selection) {
        if (selection) {
          if (selection.items) {
            this.selectGroup(selection.label)
            this.multiSelectValue = this.localValue
          } else if (selection[this.valueKey]) {
            this.$emit('input', selection)
            this.$refs.multiselect.deactivate()
            if (this.resetOnSelect) {
              this.multiSelectValue = null
            }
          }
        }
      },
      navigateOut () {
        this.selectionPath.pop()
      },
      selectGroup (group) {
        this.selectionPath.push(group)
      },
      setLocalValue (value) {
        this.localValue = this.treeItems[value]
        this.multiSelectValue = this.treeItems[value]
      },
    },
  }
</script>
