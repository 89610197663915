import HALAPIConcern from 'models/concerns/hal_api_concern'

const Model = Backbone.Model.extend({
  parse: function(data) {
    this.parseHAL(data)

    return data
  },
})
_.extend(Model.prototype, HALAPIConcern)
export default Model
