<template>
  <div>
    <div class="search-select-wrapper">
      <WbSearch
        ref="select"
        width="100%"
        :route="route"
        v-model="localValue"
        :responseProcessor="processResponse"
        placeholder="Search Staff Members"
        @input="handleInput"
      />
    </div>
  </div>
</template>

<script>
  import WbSearch from 'components/common/WbSearch'
  import { getRoute } from 'lib/routes'

  export default {
    name: 'employee-search',

    components: {
      WbSearch,
    },

    props: {
      selectCallback: {
        type: Function,
        required: false,
      },

      value: {
        required: false,
        type: Object,
      },

      // Optional override of the search path
      route: {
        type: String,
        required: false,
        default: getRoute('search_employees_path'),
      },
    },

    data() {
      return {
        localValue: null,
        employeesById: {},
      }
    },

    watch: {
      value () {
        this.setLocalValue()
      },
    },

    created () {
      this.setLocalValue()
    },

    methods: {
      handleInput (value) {
        const employee = this.employeesById[value]
        this.$emit('input', employee)
        if (this.selectCallback) {
          this.$nextTick(() => {
            this.selectCallback({data: employee})
          })
        }
      },
      processResponse (response) {
        const employees = response && response.employees || []

        return employees.map(employee => {
          this.employeesById[employee.id] = employee
          return {
            label: `${employee.first_name} ${employee.last_name}`,
            value: employee.id,
            desc: employee.email,
          }
        })
      },

      setLocalValue () {
        this.localValue = this.value?.id
      },
    },
  }
</script>
