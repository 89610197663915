// Wizard History without back-button support
//
// currentStepIdxCallback - Function returning the index of the current wizard step
// backCallback - Callback function to return the wizard to the previous step
// body - Body element to hide when leaving page
//
export default function(_currentStepIdxCallback, backCallback){
  // Push any history
  // NOP
  this.pushNextStep = function(){}

  // Trigger a History Back
  // Just wraps browser history
  this.back = function(){
    backCallback()
  }

  // Remove event listeners
  // NOP
  this.cleanup = function(){}

  return this
}
