import UAParser from 'ua-parser-js'

// Singleton class BrowserSupport provides convience methods for various browser-related checks.
export default class BrowserSupport {
  static instance

  constructor() {
    if (BrowserSupport.instance) {
      return BrowserSupport.instance
    }

    this._parser = new UAParser()
    this._parserResult = this._parseVersions(this._parser.getResult())

    BrowserSupport.instance = this
  }

  // Determines if the current client browser is supported. Currently, the following browsers are NOT supported:
  //
  //  IE - below 10
  //  Safari - below 6
  //
  // Returns - Boolean
  get isSupported() {
    const result = this._parserResult

    // if UAParser failed to get details, assume OK.
    if (!result || !result.browser.version) {
      return true
    }

    if ((result.browser.name.indexOf('IE') >= 0)) {
      return false
    } else if ((result.browser.name.indexOf('Safari') >= 0) && (result.browser.major < 9)) {
      return false
    }

    return true
  }

  // Whether or not the browser supports DataTables CSV export. Currently only Safari is not supported.
  //
  // Returns - Boolean
  get isCSVExportSupported() {
    try {
      const isSafari = this._parserResult.browser.name.indexOf('Safari') >= 0
      return !isSafari
    } catch (_) {
      return true
    }
  }

  // Certain browsers have issues with uploading straight from the camera
  //
  // Returns - Boolean
  get hasMobileUploadIssues() {
    try {
      const isAndroid51 = (this._parserResult.os.name.indexOf('Android') >= 0 &&
                            ((this._parserResult.os.major == 5 && this._parserResult.os.minor <= 1) ||
                             (this._parserResult.os.major < 5)))
      return isAndroid51
    } catch (_) {
      return false
    }
  }

  // Friendly version of the browser name. This replaces a couple "weirder" browser names:
  //
  //    MSIE -> "Internet Explorer"
  //    Mobile Safari -> "Safari"
  //
  // Returns - String
  get browserName() {
    try {
      const uaBrowserName = this._parserResult.browser.name
      if (uaBrowserName.indexOf('Safari') >= 0) {
        return 'Safari'
      } else if (uaBrowserName.indexOf('IE') >= 0) {
        return 'Internet Explorer'
      }

      return uaBrowserName
    } catch (_) {
      return 'Unknown'
    }
  }

  // Is this a mobile device?
  //
  // Returns - Boolean
  get isMobile() {
    try {
      return ['mobile', 'tablet'].indexOf(this._parserResult.device.type) != -1
    } catch (_) {
      return false
    }
  }

  // Parses version strings into major, minor and patch.
  _parseVersions(uaResult) {
    if (!uaResult) {
      return null
    }

    const versionRegexp = /^(\d+)\.?(\d+)?\.?(\d+)?.*$/  // https://regex101.com/r/jAoqn2/3
    try {
      if (uaResult.browser) {
        const browserVersion = versionRegexp.exec(uaResult.browser.version)
        Object.assign(uaResult.browser, {
          major: parseInt(browserVersion[1], 10) || 0,
          minor: parseInt(browserVersion[2], 10) || 0,
          patch: parseInt(browserVersion[3], 10) || 0,
        })
      }
    } catch (_) { }

    try {
      if (uaResult.os) {
        const osVersion = versionRegexp.exec(uaResult.os.version)
        Object.assign(uaResult.os, {
          major: parseInt(osVersion[1], 10) || 0,
          minor: parseInt(osVersion[2], 10) || 0,
          patch: parseInt(osVersion[3], 10) || 0,
        })
      }
    } catch (_) { }

    return uaResult
  }
}
