/* global moment */

import Constants from 'lib/constants'
import Routes from 'lib/routes'
import StringUtil from 'lib/util/string'

import BackboneDataTableView from 'views/common/backbone_data_table/backbone_data_table_view'
import AnnouncementTableRowView from 'views/superadmin/announcements/announcement_table_row_view'
import AnnouncementTableToolbarView from 'views/superadmin/announcements/announcement_table_toolbar_view'

export default Marionette.LayoutView.extend({
  template: false,
  ui: {
    dtWrapper: '.dt-wrapper',
  },

  initialize: function() {
    this.backboneDataTableView = null
  },

  onRender: function() {
    this.backboneDataTableView = new BackboneDataTableView({
      collection: this.collection,
      toolbarView: new AnnouncementTableToolbarView({}),
      emptyMessage: 'No Announcements',
      rowViewType: AnnouncementTableRowView,
      dataTableOptions: {
        columns: [
          { title: 'Title', data: 'title', render: (data, _type, row) => {
            const link = $('<a />').attr('href', Routes.superadmin_edit_announcement_path({id: row.id})).text(data)
            return link[0].outerHTML
          },
          },
          { title: 'Filter', data: 'filter', render: (data) => {
            // Convert role names to more readable strings
            // restricted_admin => Restricted Admin
            return data.roles.map((role) => {
              return StringUtil.titleize(role.replace(/_/g, ' '))
            }).join(', ')
          },
          },
          { title: 'Audience', data: 'filter', render: (data, _type, _row) => {
            let audience = data.audience

            if (Array.isArray(audience)) {
              audience = audience[0] == "+ALL_ACCOUNTS" ? ["All Accounts"] : audience.join(', ')
            } else {
              audience = ''
            }

            return audience
          },
          },
          { title: 'Force Display', data: 'force_display', render: (data) => {
            return data ? '<i class="fa fa-check"></i>' : ''
          },
          },
          { title: 'Created At', data: 'created_at', sort: 'created_at', type: 'date', render: (data) => {
            return moment(data).format(Constants.DATE_TIME_FORMAT)
          },
          },
          {
            title: '', orderable: false, data: 'actions', render: () => {
              return '<div class=\'row-action-links\'></div>'
            },
          },
        ],
        order: [3, 'desc'],
      },
    })
    this.backboneDataTableView.setElement(this.ui.dtWrapper)
    this.backboneDataTableView.render()
  },

  onBeforeDestroy: function() {
    this.backboneDataTableView.destroy()
  },
})
