/* global moment */

require('vendor/select2') // Using vendored version to include a manual fix
require('bootstrap-multiselect')
require('jquery.inputmask')

import Constants from 'lib/constants'
import AccessExpirationTimer from 'lib/access_expiration_timer'

/** Initialize all the various third party components and default settings
 **/
jQuery.runInitializers = function($scope) {
  // Enable all tooltips toggles
  $scope.find('[data-toggle="tooltip"]').each(function() {
    const showDelay = $(this).data("delay") || 200
    $(this).tooltip({ delay: { show: showDelay, hide: 150 }, animation: 'fade' })
  })

  // Form "required field" abbr tooltips
  $scope.find('abbr[title="required"]').each(function() {
    const placement = $(this).data('placement') || ($(this).closest('form').hasClass('form-vertical') ? 'top': 'left')
    $(this).tooltip({ delay: { show: 200, hide: 150 }, animation: 'fade', placement: placement, trigger: 'hover' })
  })

  // Form field contextual help
  $scope.find('a.help-popover').each(function() {
    let container
    if ($(this).closest('form').length == 0)
    {container = $('body')}
    else
    {container = $(this).closest('form')}

    $(this).popover({
      html: true,
      title: function() {
        return `<i class="fa fa-question-circle"></i> ${  $(this).data('field-name')  }<a class="close">x</a>`
      },
      container: container,
    }).on('show.bs.popover', function () {
      const $popoverLink = $(this)
      const $popover = $(this).data('bs.popover').$tip
      if (!$popover) {return}

      $popover.addClass('help-popover')
      $popover.on('click', 'a.close', function() {
        $popoverLink.popover('hide')
      })
    })
  })

  // Datepickers
  // Input pika data attributes (pika-max-date, pika-min-date) values should be set in ISO 8601 format
  // via Ruby: e.g. Time.zone.today.iso8601
  // via JS: moment().toISODateString()
  $scope.find('[data-toggle="datepicker"]').each(function(){
    if (!$(this).data('pikaday')) {
      $(this).data('pikaday', new Pikaday({
        field: this,
        format: Constants.DATE_FORMAT,
        yearRange: 100,
        defaultDate: new Date($(this).data('pika-default-date')),
      }))

      if ($(this).data('pika-max-date')) {
        const maxDate = $(this).data('pika-max-date')
        $(this).data('pikaday').setMaxDate(moment(maxDate).toDate())
      }

      if ($(this).data('pika-min-date')) {
        const minDate = $(this).data('pika-min-date')
        $(this).data('pikaday').setMinDate(moment(minDate).toDate())
      }

      // When an invalid date is the value, pikaday converts to 01/01/0000. This causes problem if a form is resubmitted
      // because Chronic.parse('01/01/0000') is nil.
      if ($(this).val() == '01/01/0000') {
        $(this).val('')
      }

      // Manually display if element is focused (loading a page via turbolinks breaks this focus element on page load)
      if ($(this).is(":focus")) {
        $(this).data('pikaday').show()
      }
    }
  })
  // When someone clicks the calendar icon addon, force-open the datepicker
  $scope.find('.input-group-addon i.fa-calendar').each(function() {
    $(this).parent().on('click', function() {
      $(this).next('input').data('pikaday').show()
    })
  })
  // When someone clicks on the remove icon addon, clear the input data
  $scope.find('.input-group-addon i.fa-remove').each(function() {
    $(this).parent().on('click', function() {
      $(this).data('pikaday').setDate(null)
    })
  })

  // Select2 dropdown support
  $scope.find('select.select2').each(function () {
    const $el = $(this)

    // Skip Select2 initilization, it's handled somewhere else
    if ($el.hasClass('init-ignore')) { return }

    const opts = {}
    if ($el.data("search-off"))
    {opts.minimumResultsForSearch = -1}
    if ($el.data("allow-clear"))
    {opts.allowClear = true}

    if ($el.data('template-result')) {
      opts.templateResult = $el.data('template-result')
    }

    opts.theme = "bootstrap"

    // Fix for issue where clicking on clear icon raises exception on Select2 4.0
    // https://github.com/select2/select2/issues/3497#issuecomment-124122158
    opts.placeholder = {
      id: "",
      placeholder: "Leave blank to ...",
    }

    $el.select2(opts)

    // Prevent showing dropdown when select2 remove is clicked. This is new Select2 4.0 behavior.
    // https://github.com/select2/select2/issues/3209#issuecomment-149663474
    $el.on("select2:unselect", function (e) {
      if (!e.params.originalEvent) {
        return
      }

      e.params.originalEvent.stopPropagation()
    })
  })

  // Tooltips on disabled elements
  $scope.find('input:disabled, button:disabled').after(function () {
    if (!$(this).attr("title") || $(this).attr("title").length == 0)
    {return null}

    const d = $("<div>")
    const i = $(this)
    d.css({
      height: i.outerHeight(),
      width: i.outerWidth(),
      position: "absolute",
    })
    d.css(i.offset())
    d.attr("title", i.attr("title"))
    d.tooltip()
    return d
  })

  // TouchSpin
  $scope.find('[data-toggle="touchspin"]').each(function() {
    const $this = $(this)

    const options = {
      mousewheel: false,
      verticalbuttons: true,
      verticalupclass: 'fa fa-plus',
      verticaldownclass: 'fa fa-minus',
      max: Number.POSITIVE_INFINITY,
    }

    let min
    if (min = $this.data('touchspin-min')) {
      if (min === '-Inf')
      {min = Number.NEGATIVE_INFINITY}
      options['min'] = min
    }
    let max
    if (max = $this.data('touchspin-max')) {
      if (max === 'Inf')
      {max =  Number.POSITIVE_INFINITY}
      options['max'] = max
    }
    if ($this.data('touchspin-decimals'))
    {options['decimals'] = $this.data('touchspin-decimals')}

    if ($this.data('touchspin-step'))
    {options['step'] = $this.data('touchspin-step')}

    // Currency support
    if ($this.data('touchspin-prefix') === '$') {
      options['prefix'] = '<i class="fa fa-usd" />'
    } else if ($this.data('touchspin-prefix')) {
      options['prefix'] = $this.data('touchspin-prefix')
    }

    $this.TouchSpin(options)

    // DISCUSS: Should we also capture this in a 'leave' callback?
    if ($this.data("touchspin-clear-zero") && $this.val() == "0") {
      $this.val("")
    }
  })

  // HACK: When the user closes and then re-opens a modal, sometimes autofocus fields don't get the focus again automatically,
  // so we force it manually:
  $scope.find('button[autofocus="autofocus"], input[autofocus="autofocus"]').focus()

  // Set up Ladda buttons automatically on common types of submit buttons
  $scope.find('button[type=submit]').each(function() {
    const $self = $(this)

    $self.ladda()  // jquery binding stores Ladda instance in .data('ladda')

    // Unless explicitly requesting not to start automatically, mimic Ladda.bind functionality and start it when it is clicked
    if (!$self.data('ladda-prevent-autostart')) {
      $self.on('click', function() {
        // HACKFIX: This is asynchronous to avoid an issue where setting the disabled attribute on the button prevents forms
        // from submitting
        $self.data('ladda').startAfter(1)
      })
    }
  })

  // TODO: plugin-ize this
  $scope.find('.btn-group[data-toggle="option-group"]').each(function() {
    const $btnGroup = $(this)
    const $hiddenField = $($(this).data('href'))
    const $selectedBtnOption = $(this).find(`a.btn-option[data-value="${  $hiddenField.val()  }"]`)
    if ($selectedBtnOption.length > 0)
    {$selectedBtnOption.addClass('active').addClass('btn-success').removeClass('btn-default')}
    else {
      const $defaultBtnOption = $(this).find('a.btn-option.btn-option-default')
      $defaultBtnOption.addClass('active').addClass('btn-success').removeClass('btn-default')
      $hiddenField.val($defaultBtnOption.data('value'))
    }

    $(this).find('a.btn-option').on('click', function() {
      if ($(this).hasClass('active')) {return}

      $hiddenField.val($(this).data('value'))
      $btnGroup.find('a.btn-option.active').removeClass('active').removeClass('btn-success').addClass('btn-default')
      $(this).addClass('active').addClass('btn-success').removeClass('btn-default')
    })
  })

  $scope.find('input[data-toggle="inputmask"]').inputmask()

  $scope.find('select[data-toggle="multiselect"]').each(function() {
    const $input = $(this)
    $input.multiselect({
      // enableCaseInsensitiveFiltering: false,
    })
  })

  $scope.find('input[data-toggle="toggle"]').bootstrapToggle()

  AccessExpirationTimer($scope)
}

// Listen for the critical events –
//  page:change - the page has changed thru Turbolinks
//  shown.bs.modal - a modal has been shown (whether or not it was remote or not)
//  shown.a4s.async - our internal event when a remote/async view was loaded
$(document).on('page:change shown.bs.modal shown.a4s.async', function(e) {
  $.runInitializers($(e.target))
})

// When the page changes due to Turbolinks, clear out the dataTable filter plugins
$(document).on('page:fetch', function() {
  if (!$.fn.dataTable || !$.fn.dataTable.ext)
  {return}

  // Reset any custom filters we applied to the datatable
  const filterArray = $.fn.dataTable.ext.search
  if (filterArray) {
    while (filterArray.length > 0) {
      filterArray.pop()
    }
  }
})
