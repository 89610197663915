<template>
  <div>
    <table class="table">
      <thead>
        <tr>
          <th>Your Spreadsheet</th>
          <th />
          <th>WorkBright Field</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="entry in sortedColumnsMap"
          :key="entry.position"
          :class="{'disabled': entry.ignored}"
        >
          <td>
            {{ entry.name }}
            <ColumnSample :sample="entry.sample" />
          </td>
          <td class="text-center">
            <WbIcon
              type="long-arrow-right"
              class="match-arrow"
            />
          </td>
          <td class="w-50 text-right">
            <FieldSelector
              :fields="fields"
              :value="entry.field_key"
              @update:value="(newValue) => (handleFieldKeyChange(entry.position, newValue))"
            />
            <WbButton
              :ref="`ignoreButton${entry.position}`"
              class="pr-0"
              context="link"
              :disabled="entry.ignored"
              size="sm"
              @click="ignoreFieldKey(entry.position)"
            >
              <WbIcon
                v-if="!entry.ignored"
                type="ban"
              />
              {{ entry.ignored? "Ignored. This data won't be updated" : "Ignore this column" }}
            </WbButton>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import FieldSelector from 'components/common/FieldSelector'
  import WbButton from 'components/common/WbButton'
  import WbIcon from 'components/common/WbIcon'
  import ColumnSample from 'components/user_updates/steps/match_columns_step/ColumnSample'

  export default {
    name: 'match-columns-table',
    components: {
      WbButton,
      ColumnSample,
      FieldSelector,
      WbIcon,
    },
    inject: ['fields'],
    props: {
      sortedColumnsMap: {
        type: Array,
        required: true,
      },
    },
    emits: ['fieldKeyChanged', 'fieldIgnoreChanged'],
    methods: {
      handleFieldKeyChange(position, newValue) {
        this.$emit('fieldKeyChanged', position, newValue)
        this.$emit('fieldIgnoreChanged', position, false)
      },
      ignoreFieldKey(position) {
        this.$emit('fieldIgnoreChanged', position, true)
      },
    },
  }
</script>
