<template>
  <div>
    <BulkResetAssignments
      :employeeIds="employeeIds"
      :collectionViewName="collectionViewName"
      @hidden="cleanup"
      @success="handleSuccess"
    />
  </div>
</template>

<script>
  import { mapActions, mapMutations } from 'vuex'
  import * as mutate from 'vuex/mutation_types'
  import CollectionViewStore from 'vuex/modules/collection_view_store'
  import DocumentAssignments from 'vuex/modules/document_assignments'
  import Documents from 'vuex/modules/documents'
  import Employees from 'vuex/modules/employees'
  import Permissions from 'vuex/modules/permissions'
  import BulkResetAssignments from 'components/employees/bulk_reset_assignments/BulkResetAssignments'

  // Vuex CollectionViewStore module name, stores metadata about collection (selected, filters, etc)
  const VUEX_COLLECTION_VIEW_NAME = 'collection_view_reset_assignments'

  export default {
    name: 'bulk-reset-assignments-orphan-container',

    components: {
      BulkResetAssignments,
    },

    props: {
      employeeIds: {
        type: Array,
        required: true,
      },
    },

    data() {
      return {
        collectionViewName: VUEX_COLLECTION_VIEW_NAME,
      }
    },

    created: function(){
      if (!this.$store._modulesNamespaceMap['documents/']) {
        this.$store.registerModule('documents', Documents)
      }
      if (!this.$store._modulesNamespaceMap['document_assignments/']) {
        this.$store.registerModule('document_assignments', DocumentAssignments)
      }
      if (!this.$store._modulesNamespaceMap['employees/']) {
        this.$store.registerModule('employees', Employees)
      }
      if (!this.$store._modulesNamespaceMap['permissions/']) {
        this.$store.registerModule('permissions', Permissions)
      }

      this.$store.registerModule(this.collectionViewName, CollectionViewStore)
    },

    mounted() {
      this.setPageContextKeys({
        bulkResetAssignments: true,
      })
    },

    beforeUnmount() {
      // Clear selected BEFORE we unregister the dynamically created module
      this.$store.dispatch(`${this.collectionViewName}/reset`)
      this.$store.unregisterModule(this.collectionViewName)
    },

    methods: {
      handleSuccess() {
        location.reload()
      },

      cleanup() {
        this.$emit('clear-modal-data')
        this.$destroy()
      },

      ...mapMutations({
        setPageContextKeys: mutate.SET_PAGE_CONTEXT_KEYS,
      }),

      ...mapActions({
        documentAssignmentsBulkFind: 'document_assignments/bulkFind',
        employeesBulkShow: 'employees/bulkShow',
        permissionsBulkAuthorize: 'permissions/bulkAuthorize',
      }),
    },
  }
</script>
