/* global console */
import moment from 'moment-timezone'
import DateTimeUtil from 'lib/util/datetime'
import Auth from 'lib/util/auth'
import Animations from  'lib/util/animations'
import Constants from 'lib/constants'

Backbone.Stickit.addHandler({
  selector: '.stickit-format-date',
  formatValue: function(value, _options) {
    return DateTimeUtil.formatDate(value)
  },
})

Backbone.Stickit.addHandler({
  selector: '.stickit-format-datetime',
  formatValue: function(value, _options) {
    return DateTimeUtil.formatDateTime(value)
  },
})

// This handler converts checkbox inputs from "1"/null into actual Boolean true/false.
// The warning is here to alert devs that if you leave off the :checkbox, stickit will end up finding an ambiguous
// Array of checkboxes (Rails check_box_tag creates a hidden checkbox to support 'unchecking').
Backbone.Stickit.addHandler({
  selector: '.stickit-format-boolean',
  onSet: function(val, _options) {
    if (Array.isArray(val)) {
      console.warn('stickit val is an Array - did you mean to bind to input:checkbox?')
      return val
    }

    return (val === "1")
  },
})

// This handler converts between Moment objects in the model and Pikaday control on the front end.
// Effectively, this lets us render in the US-based MM/DD/YYYY format, while still serializing to ISO 8601 for JSON
// transport of models.
Backbone.Stickit.addHandler({
  selector: '.stickit-moment-pikaday',
  onSet: function(val, _options) {
    return moment(val, Constants.DATE_FORMAT)
  },
  formatValue: function(value, _options) {
    if (moment.isMoment(value)) {
      return value.isValid() ? DateTimeUtil.formatDate(value) : value.getOriginalInput()
    } else {
      return DateTimeUtil.formatDate(value)
    }
  },
  update: function($el, val) {
    $el.val(val)
    const picker = $el.data('pikaday')
    if (picker) {
      picker.setDate(val)
    }
  },
})

// Default handler
Backbone.Stickit.addHandler({
  selector: '*',
  updateMethod: 'html',

  // onGet formats the value of the attribute from the model before rendering it to the DOM. This default
  // implementation checks to see if the attribute value is a "blocked content" marker. If so, it renders
  // some standard HTML.
  //
  // Because we are overriding the base onGet implementation here (since selector is '*'), if you choose to override
  // onGet within a particular binding elsewhere, you will lose this default behavior. In some cases, that may be
  // appropriate. In other situations, if you want to keep this default implementation but ALSO add custom formatting
  // you should provide a `formatValue` method instead.
  onGet: function(val, options) {
    if (Auth.isBlockedContent(val)) {
      return Auth.blockedContentTag()
    }

    if (_.isFunction(options.formatValue)) {
      return options.formatValue.call(this, val, options)
    }

    return val
  },
  afterUpdate: function($el, _val, options) {
    if (options.highlightUpdates === false)
    {return}

    const rowEl = $el.closest('tr')
    Animations.temporaryHighlight(rowEl)
  },
})
