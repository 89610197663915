import * as BaseCollectionModule from 'vuex/mixins/base_vuex_collection_module'

function state() {
  return {
    collection: {},
  }
};

const getters = {
  // Fetch all accounts in the store
  // Returns - Array
  all: (state) => {
    return _.toArray(state.collection)
  },

  ...BaseCollectionModule.getters,
}

// eslint-disable-next-line no-unused-vars
const mutationTypes = {
  ...BaseCollectionModule.mutationTypes,
}

const mutations = {
  ...BaseCollectionModule.mutations,
}

const actions = {
  ...BaseCollectionModule.actions,
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
