export default Marionette.LayoutView.extend({
  template: false,

  ui: {
    adminUserPolicyField: '.admin_user_policy',
    adminUserPolicyInput: '#admin_user_policy', // hidden field
    accessRadioButton: 'input[name="admin_user[access_role]"]:checked',
    newPolicyButton: '.js-create-new-policy',
    viewExistingPolicyButton: '.js-view-existing-policy',
    existingEmployeeSelectInput: '#admin_user_employee_user',
    restrictedAdminRadioButton: 'input[name="admin_user[access_role]"]:radio[value=restricted_admin]',
    policyMessage: '#policy-msg',

    // The following exist only in New Admin view
    adminUserEmail: '#admin_user_email',
    adminUserFirstName: '#admin_user_first_name',
    adminUserLastName: '#admin_user_last_name',
    adminUserMiddleName: '#admin_user_middle_name',
  },

  events: {
    'click @ui.accessRadioButton': 'accessChange',
    'click @ui.newPolicyButton': 'newPolicyClick',
  },

  initialize: function(){

  },

  onRender: function () {
    this.hidePolicySummaryButtons()
    this.togglePolicyField(this.ui.accessRadioButton.val())
    this.accessChange()
  },

  // Populate the existing AdminUser form with data from Policy Generator
  populateForm: function (admin) {
    this.ui.adminUserEmail.val(admin.email)
    this.ui.adminUserFirstName.val(admin.employee_profile.first_name)
    this.ui.adminUserLastName.val(admin.employee_profile.last_name)
    this.ui.adminUserMiddleName.val(admin.employee_profile.middle_name)

    this.ui.restrictedAdminRadioButton.prop('checked', true).trigger('click')
    this.ui.existingEmployeeSelectInput.val(admin.id).trigger("change")
    this.ui.adminUserPolicyInput.val(JSON.stringify(admin.permission_sets))

    this.showExistingPolicyMessaging()

    this.hidePolicySummaryButtons()

    // We need a way to know if the Policy was replaced in the Edit Admin User view in order to 
    // know when to hide the View Existing Policy button. The Policy Summary on the edit screen
    // is only populated from the AdminUserController#edit action server-side and won't take into
    // account the replaced Policy
    this.ui.adminUserPolicyInput.attr('data-replaced', true)
  },

  showExistingPolicyMessaging: function () {
    this.ui.policyMessage.html("&#10003; Custom Policy created.")
    this.ui.newPolicyButton.text('Modify Policy')
    this.ui.viewExistingPolicyButton.toggle(true)

    // Hide the View Existing Policy button if existing Policy is replaced since we can't currently render the replacement
    if (this.ui.adminUserPolicyInput.attr('data-replaced') === 'true') { this.ui.viewExistingPolicyButton.hide()};
  },

  // Returns a User object
  buildAdminData: function () {
    const originFormUrl = $('body').data('root-path')
    const promoteUrl = "/admin/admin_users/promote" // TODO: Zach, move this to routes
    let newAdminData
    // Check if we are on promote view
    if (originFormUrl == promoteUrl) {
      const existingAdmin = this.ui.existingEmployeeSelectInput
      const fullName = existingAdmin.find(':selected').text()
      newAdminData = {
        id: existingAdmin.val(),
        employee_profile: {
          first_name: $.trim(fullName.split(',')[1]),
          last_name: $.trim(fullName.split(',')[0]),
        },
        meta: { origin_url: originFormUrl },
      }
    } else {
      // We are on either new or edit views which use the same form
      newAdminData = {
        id: gon.user ? gon.user.id : null,
        url: originFormUrl,
        email: this.ui.adminUserEmail.val(),
        employee_profile: {
          first_name: this.ui.adminUserFirstName.val(),
          middle_name: this.ui.adminUserMiddleName.val(),
          last_name: this.ui.adminUserLastName.val(),
        },
        meta: { origin_url: originFormUrl },
      }
    }

    return newAdminData
  },

  // Triggers Admin Policy Generator
  newPolicyClick: function () {
    const newAdmin = this.buildAdminData()

    const hasSelectedUser = this.ui.existingEmployeeSelectInput.length && this.ui.existingEmployeeSelectInput.val().length
    const hasFirstName = this.ui.adminUserFirstName.length && this.ui.adminUserFirstName.val().length
    
    // Display validation error if we don't have User details(existing or new) to add Policy to 
    if (!hasSelectedUser && !hasFirstName){
      bootbox.alert({
        title: "We cannot create a Policy without more information",
        message: "Please fill in all admin information above this field before creating a Policy.",
        className: "modal-info",
      })
      return
    }

    // Function for starting the policy generator wizard- used as Bootbox confirm callback, or called directly
    const startPolicyGenerator = function(){
      App.globalVent.trigger(
        'admin_policy_generator:start',
        newAdmin,
        function (user) { this.retrievePolicyPerms(user)}.bind(this))
    }.bind(this)

    startPolicyGenerator()
  },

  // Retrieves Admin User from Policy Generator
  // Triggers global listener to change URL and repopulate admin form
  retrievePolicyPerms: function (user) {
    App.globalVent.trigger('admin_users:policy_retrieved', {
      adminUser: user,
    })
  },

  // This method gets called when the admin access radio buttons are clicked
  // Or upon editing page load
  accessChange: function (e) {
    if (e) {
      const value = this.getAccessValue(e)
      this.togglePolicyField(value)
    }
    // Show policy Created messaging if input value exists
    if (this.ui.adminUserPolicyInput.val().length) { this.showExistingPolicyMessaging() };
  },

  // Returns radio button access value
  getAccessValue: function (element) {
    return $(element.currentTarget).val()
  },

  // Toggles policy form field view, we currently only have two public admin roles
  togglePolicyField: function (accessValue) {
    this.ui.adminUserPolicyField.toggle(accessValue === "restricted_admin")
  },

  hidePolicySummaryButtons: function () {
    this.ui.viewExistingPolicyButton.hide()
  },
})
