<template>
  <ul
    v-if="canLocalize"
    ref="nav"
    class="nav nav-pills"
  >
    <li
      ref="en"
      :class="{ active: language == 'en'}"
    >
      <a
        context="link"
        role="button"
        @click.prevent="setLanguage('en')"
      >
        English
      </a>
    </li>
    <li
      ref="es"
      :class="{ active: language == 'es'}"
    >
      <a
        context="link"
        role="button"
        @click.prevent="setLanguage('es')"
      >
        Español
      </a>
    </li>
    <li
      ref="ht"
      :class="{ active: language == 'ht'}"
    >
      <a
        context="link"
        role="button"
        @click.prevent="setLanguage('ht')"
      >
        Kreyòl Ayisyen
      </a>
    </li>
  </ul>
</template>
<script>

  const DEFAULT_LANGUAGE = 'en'
  const BLOCKED_CLASSES = ['block-translate']

  export default {
    props: {
      localizeKey: {
        type: String,
        required: true,
      },
    },
    data () {
      return {
        language: DEFAULT_LANGUAGE,
        turboLinksListener: null,
      }
    },
    computed: {
      localize () {
        return window.Localize
      },
      canLocalize () {
        return !! this.localize
      },
    },
    created () {
      this.initialize()
    },
    methods: {
      getLanguage () {
        return this.localize.getLanguage() || DEFAULT_LANGUAGE
      },
      handleTurbolinksUpdate () {
        this.language = this.getLanguage()
        this.translate()
      },
      initialize () {
        if (!this.canLocalize) {
          return false
        }
        if (!this.turboLinksListener) {
          this.turboLinksListener = document.addEventListener('page:load', this.handleTurbolinksUpdate)
        }
        this.language = this.getLanguage()
        this.localize.initialize({
          autoApprove: true,
          blockedClasses: BLOCKED_CLASSES,
          disableWidget: true,
          key: this.localizeKey,
          rememberLanguage: true,
        })
        this.translate()
      },
      setLanguage (language) {
        this.language = language
        this.localize.setLanguage(language)
        this.translate()
      },
      translate () {
        this.localize.translatePage()
      },
    },
  }
</script>
