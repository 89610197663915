<template>
  <div class="small">
    <HelpPopover
      :content="popoverContent"
      title="Sample data from your spreadsheet"
      placement="auto right"
    >
      <WbIcon type="table" /> Sample
    </HelpPopover>
  </div>
</template>

<script>
  import HelpPopover from 'components/common/HelpPopover'
  import WbIcon from 'components/common/WbIcon'

  export default {
    name: 'column-sample',
    components: {
      HelpPopover,
      WbIcon,
    },
    props: {
      sample: {
        type: Array,
        required: true,
      },
    },
    computed: {
      popoverContent() {
        if (this.sample?.length) {
          return `<ul class="list-group">
            ${this.sample.reduce((html, item) => (html += `<li class="list-group-item">${item}</li>`), '')}
          </ul>`
        }

        return 'No Content'
      },
    },
  }
</script>
