<template ref="panelView">
  <div>
    <table class="table table-responsive info-table panel-info-table">
      <tbody>
        <ProfileRow label='Type' :value="employee.employee_type_label" />
        <ProfileRow label='Name' :value="employee.full_name" />
        <ProfileRow label='Email' :value="employee.email">
          <a :href="'mailto:' + employee.email">{{ employee.email }}</a>
          <TooltipIcon
            v-if="undeliveredEmail"
            class="text-danger pl-2"
            icon="envelope"
            :message="undeliveredEmail"
          />
        </ProfileRow>
        <ProfileRow label='Address' :value="employee.address_html">
          <span v-html="employee.address_html" />
        </ProfileRow>
        <ProfileRow label='Phone' :value="employee.phone">
          {{ employee.phone }}
          <TooltipIcon
            v-if="hasValidSmsStatus"
            class="pl-2 text-danger"
            icon="phone"
            :message="smsStatus"
            ref="smsStatus"
          />
        </ProfileRow>
        <ProfileRow label='Gender' :value="employee.gender_label" />
        <ProfileRow label='Birthdate' :value="employee.birthdate">
          {{ birthdate }}
        </ProfileRow>
        <ProfileRow label='Age' :value="employee.age" />
        <ProfileRow label='SSN' :value="employee.ssn">
          <span v-if="employee.ssn">{{ ssnMask }}</span>
        </ProfileRow>
      </tbody>
    </table>
  </div>
</template>

<script>
  import DateTimeUtil from 'lib/util/datetime'
  import Locale from 'lib/locale'
  import { mapGetters } from 'vuex'
  import Masks from 'lib/util/masks'
  import ProfileRow from 'components/employees/ProfileRow'
  import EmployeeSmsStatusMixin from 'components/mixins/employee/EmployeeSmsStatusMixin'
  import TooltipIcon from 'components/common/TooltipIcon'

  export default {
    name: 'profile-info-panel-view',

    components: {
      TooltipIcon,
      ProfileRow,
    },

    mixins: [
      EmployeeSmsStatusMixin,
    ],

    computed: {
      employee() {
        return this.getEmployee(this.pageContext.employeeId)
      },

      undeliveredEmail() {
        return this.employee.undelivered_email && Locale.t('errors.email.emailBounced')
      },

      birthdate() {
        return DateTimeUtil.formatDate(this.employee.birthdate)
      },

      ssnMask() {
        const ssn = this.employee.ssn
        return typeof(ssn) == 'string' ? Masks.ssn_last4(this.employee.ssn) : ''
      },

      ...mapGetters({
        currentAccount: 'account/current',
        pageContext: 'pageContext',
        getEmployee: 'employees/get',
      }),
    },
  }
</script>
