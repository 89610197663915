// This code is heavily based on:
// views/document_submissions/i9/edit_attachment_view.js
//
import Routes from 'lib/routes'
import ImageEditorView from 'views/common/image_editor_view'
import RemoteFormValidation from 'lib/remote_form_validation'
import FormsUtil from 'lib/util/forms'
import I9SupplementBItemForm from "models/i9/i9_supplement_b_item_form"

export default class EditView extends Backbone.Marionette.LayoutView.extend({
  template: "#i9-edit-attachment-tmpl",
  className: "i9-documentation i9-attachment",

  ui: {
    form: "form",
    documentTitleSelect: 'select[name="i9_attachment[document_title]"]',
    issuingAuthoritySelect: 'select[name="i9_attachment[issuing_authority]"]',
    documentNumberInput: 'input[name="i9_attachment[document_number]"]',
    expirationDateInput: 'input[name="i9_attachment[expiration_date]"]',
    expirationDateHint: '.form-group.i9_attachment_expiration_date p.help-block',
  },

  bindings: {
    '[name="i9_attachment[document_title]"]': 'document_title',
    '[name="i9_attachment[issuing_authority]"]': 'issuing_authority',
    '[name="i9_attachment[document_number]"]': 'document_number',
    '[name="i9_attachment[expiration_date]"]': 'expiration_date',


    'input[name="i9_attachment[file_front_url]"]': 'file_front_url',
    'input[name="i9_attachment[file_front_cache]"]': 'file_front_cache',
    'input[name="i9_attachment[file_front_content_type]"]': 'file_front_content_type',

    'input[name="i9_attachment[file_back_url]"]': 'file_back_url',
    'input[name="i9_attachment[file_back_cache]"]': 'file_back_cache',
    'input[name="i9_attachment[file_back_content_type]"]': 'file_back_content_type',
  },

  templateHelpers: function(){
    return {
      docSetChoice: this.model.docSetChoice,
    }
  },
}) {

  initialize() {
    this.context = this.getOption("context")
  }

  onRender() {
    // Initialize jQuery components, in this case the expiration date datepicker.
    $.runInitializers(this.$el)

    this.$el.addClass(`doc-${this.model.get('documentation_key') || 'unknown'}`)

    this._renderDocumentTitleInput()
    this._renderIssuingAuthorityInput()
    this._renderDocumentNumberInput()
    this._renderExpirationDateInput()
    this._renderExpirationDateHint()

    this.stickit()
  }

  onShow() {
    this._renderUploadComponents()
    this._initializeRemoteFormValidation()
  }

  validate(validationCompleteCallback) {
    this.validationCompleteCallback = validationCompleteCallback
    this.ui.form.submit()
  }

  // Initialize and bind the ui form element to a RemoteFormValidation
  _initializeRemoteFormValidation() {
    new RemoteFormValidation({
      httpMethod: "PATCH",
      resourceName: "form",
      model: new I9SupplementBItemForm({i9_attachment: this.model}),
      timeout: 30000,
      formEl: this.ui.form,
      validations: {
        "i9_attachment[document_title]": {validators: {wbRemote: {errorKey: "form[documentation_set][0][document_title]"}}},
        "i9_attachment[document_number]": {validators: {wbRemote: {errorKey: "form[documentation_set][0][document_number]"}}},
        "i9_attachment[expiration_date]": {validators: {wbRemote: {errorKey: "form[documentation_set][0][expiration_date]"}}},
        "i9_attachment[file_front_cache]": {excluded: false, validators: {wbRemote: {errorKey: "form[documentation_set][0][file_front]"}}},
        "i9_attachment[file_back_cache]": {excluded: false, validators: {wbRemote: {errorKey: "form[documentation_set][0][file_back]"}}},
        "i9_attachment[issuing_authority]": {validators: {wbRemote: {errorKey: "form[documentation_set][0][issuing_authority]"}}},
      },
      successCallback: this.onRemoteValidateSuccess.bind(this),
      failedCallback: this.onRemoteValidateFailed.bind(this),
      errorCallback: this.onRemoteValidateError.bind(this),
      path: Routes.staff_i9_submission_path,
    })
  }

  // Initialize and render DZOperator uploaders
  _renderUploadComponents() {
    this.ui.form.dzoperator({
      submitButton: this.context.get('submitButton'),
      dzOptions: {
        presignURL: Routes.presign_uploads_path,
        previewTemplate: $("#dropzone-template").html(),
        imageEditorView: ImageEditorView,
      },
    })
  }

  onRemoteValidateSuccess(response) {
    FormsUtil.clearFormInvalidNotification(this.ui.form)
    this.validationCompleteCallback(true, response)
  }

  onRemoteValidateFailed() {
    this.validationCompleteCallback(false)
    FormsUtil.showFormInvalidNotification(this.ui.form)
  }

  onRemoteValidateError() {
    this.validationCompleteCallback(false)
  }

  // Renders or re-renders the Document Title dropdown with the proper values from the I9DocumentationChoice when it is
  // an Array.
  _renderDocumentTitleInput() {
    const docSetChoice = this.model.docSetChoice
    const isReceipt = this.model.get('receipt')

    if (docSetChoice.hasMultiplePrefilledValues('document_title')) {
      // HACKFIX iOS Webkit - On certain versions (tested on iOS 10.3), webkit renders an empty <optgroup> when no
      // initial <option>s are present in the <select>. This conflicts with the dynamically-created options below
      // causing issues with rendering and stickit bindings. Simply removing any <optgroup>s which are present seems
      // to resolve it:
      this.ui.documentTitleSelect.children('optgroup').remove()

      // Clear existing values because, unlike Issuing Authority, these titles may get re-rendered if in Employer Review
      // mode and the admin checks the Receipt checkbox
      this.ui.documentTitleSelect.children('option').remove()

      for (let value of docSetChoice.get('document_title')) {
        value = `${ isReceipt ? 'Receipt - ' : ''}${value}`

        this.ui.documentTitleSelect.append(new Option(value, value, false, false))
      }

      // ensure dropdown reflects model value since we rendered choices above
      this.ui.documentTitleSelect.val(this.model.get('document_title'))
    }
  }

  // Sets up the Issuing Authority dropdown with the proper values from the I9DocumentationChoice.
  _renderIssuingAuthorityInput() {
    const docSetChoice = this.model.docSetChoice

    if (docSetChoice.hasMultiplePrefilledValues('issuing_authority')) {
      // HACKFIX iOS Webkit - On certain versions (tested on iOS 10.3), webkit renders an empty <optgroup> when no
      // initial <option>s are present in the <select>. This conflicts with the dynamically-created options below
      // causing issues with rendering and stickit bindings. Simply removing any <optgroup>s which are present seems
      // to resolve it:
      this.ui.issuingAuthoritySelect.children('optgroup').remove()

      for (const value of docSetChoice.get('issuing_authority')) {
        this.ui.issuingAuthoritySelect.append(new Option(value, value, false, false))
      }

      // ensure dropdown reflects model value since we rendered choices above
      this.ui.issuingAuthoritySelect.val(this.model.get('issuing_authority'))
    }
  }

  // Applies Document Number input mask (if docSetChoice specifies one).
  _renderDocumentNumberInput() {
    const docSetChoice = this.model.docSetChoice

    if (!docSetChoice.documentNumberInputMask) {
      return
    }

    this.ui.documentNumberInput.inputmask({
      mask: docSetChoice.documentNumberInputMask,
      placeholder: '',
    })

    this.ui.documentNumberInput.trigger('change')  // keeps model in sync
  }

  // Renders the various states of the expiration date input
  //
  //  - Disabled if the docSetChoice says it's not necessary
  //  - It will already be prefilled with N/A from code that sets up this view
  _renderExpirationDateInput() {
    const docSetChoice = this.model.docSetChoice

    if (!docSetChoice.isExpirationDateRequired) {
      this.ui.expirationDateInput.attr('disabled', 'disabled').addClass('disabled')
    }
  }

  // Renders the expiration date hint text.
  _renderExpirationDateHint() {
    const docSetChoice = this.model.docSetChoice

    if (!docSetChoice.isExpirationDateRequired) {
      this.ui.expirationDateHint.text("You do not need to enter an expiration date for this document.")
    }
  }
}
