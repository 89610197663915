<template>
  <Panel
    variant="snapshot"
    class="unconfirmed-data-panel"
  >
    <template v-slot:heading>
      <div class="panel-title">
        <h3>Action Required</h3>
        <h4>Unconfirmed Data</h4>
      </div>
    </template>
    <template v-slot:body>
      <div v-if="emptyFields">
        {{ $locale.t(`everify.status.unconfirmed_data.sync_error`) }}
      </div>
      <div v-else>
        <p class="pb-4">
          <AlertMessage
            variant="warning"
            ref="warningUpdate"
          >
            {{ $locale.t(`everify.status.unconfirmed_data.warning_update`) }}
          </AlertMessage>
          {{ $locale.t(`everify.status.unconfirmed_data.instructions`) }}
        </p>
        <div v-for="(field, index) in fields" :key="index" class="row">
          <div class="col-xs-12 col-sm-8">
            <component
              :is="fieldComponent(field.type)"
              :ref="`${field.name}_field`"
              :name="field.name"
              :label="`${field.label}:`"
              :placeholder="field.placeholder"
              :mask="field.mask"
              v-model="field.value"
              :options= "field.options"
              :errors="errors"
              fieldWidth=""
            />
          </div>
        </div>
        <WbButton
          ref="confirmButton"
          label="Confirm and Send to E-Verify"
          :isLoading="isLoading"
          @click="submit"
        />
        <EverifyCaseCloseForm
          :everifyCase="everifyCase"
          @closeTimeout="closeModal"
        />
      </div>
    </template>
  </Panel>
</template>

<script>
  import { mapActions } from 'vuex'
  import AlertMessage from 'components/common/AlertMessage'
  import EverifyCaseCloseForm from 'components/everify/cases/EverifyCaseCloseForm'
  import Panel from 'components/common/Panel'
  import FormFieldInput from "components/common/FormFieldInput"
  import FormFieldDate from "components/common/FormFieldDate"
  import FormFieldSelect from "components/common/FormFieldSelect"
  import FormFieldSsn from "components/common/FormFieldSsn"
  import WbButton from 'components/common/WbButton'
  import Util from 'lib/util'

  const fieldComponentMap = {
    TextField: FormFieldInput,
    DateField: FormFieldDate,
    SelectField: FormFieldSelect,
    SsnField: FormFieldSsn,
  }

  export default {
    name: 'unconfirmed-data-panel',
    components: {
      AlertMessage,
      EverifyCaseCloseForm,
      Panel,
      FormFieldInput,
      FormFieldDate,
      FormFieldSelect,
      FormFieldSsn,
      WbButton,
    },

    props: {
      everifyCase: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
        errors: {},
        isLoading: false,
      }
    },

    computed: {
      fields () {
        return this.everifyCase?.unconfirmed_data_fields || []
      },

      emptyFields() {
        return this.fields.length == 0
      },

      caseId () {
        return this.everifyCase.id
      },

      confirmedData() {
        return this.fields.reduce((result, field) =>{
          return {[field.name]: field.value, ...result}
        }, {})
      },
    },

    beforeUnmount() {
      this.isLoading = false
    },

    methods: {
      submit() {
        this.isLoading = true

        this.dataConfirmation({
          caseId: this.caseId,
          caseConfirmedData: this.confirmedData,
        }).then(() => {
          this.isLoading = false
          this.closeModal()
          Util.showFlashNotice("Request is processing, case will automatically update when the request has finished.")
        }).catch((xhr) => {
          this.isLoading = false
          if (xhr.responseJSON && xhr.responseJSON.errors) {
            this.errors = xhr.responseJSON.errors || {}
          } else {
            Util.genericAjaxError("An error occurred.", xhr)
          }
        })
      },

      closeModal() {
        this.$emit('close')
      },

      fieldComponent(fieldType) {
        return fieldComponentMap[fieldType]
      },

      ...mapActions({
        dataConfirmation: 'everify_cases/dataConfirmation',
      }),
    },
  }
</script>
