<template>
  <div class="panel wizard">
    <div class="row column-divider">
      <div class="col-md-3">
        <p>Your spreadsheet <strong>must</strong> include this field:</p>
        <small class="ml-4">Staff Workbright ID</small>
        <hr />
        <p>Include one or more fields that will be updated</p>
      </div>
      <div class="col-md-9">
        <FileUpload
          ref="fileUpload"
          :error="errorFor('file')"
          :required="true"
          @file-remove="handleUploadedFileRemove"
          @upload-success="handleUploadSuccess"
        />
        <div class="wizard-footer">
          <div ref="links">
            Sample files:
            <a href="https://s3-us-west-1.amazonaws.com/wb-assets/knowledge_base/user_update_samples/Staff_Update_Example_Template.xlsx">Excel</a>,
            <a href="https://s3-us-west-1.amazonaws.com/wb-assets/knowledge_base/user_update_samples/Staff_Update_Example_Template.csv">CSV</a>
          </div>
          <WbButton
            ref="nextButton"
            class="next"
            context="primary"
            @click="handleNext"
          >
            Next
          </WbButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import BaseStep from 'components/user_updates/steps/BaseStep'
  import { createBulkStaffUpdate } from 'services/admin/BulkStaffUpdatesService'
  import FileUpload from 'components/forms/FileUpload'
  import Util from 'lib/util'
  import WbButton from 'components/common/WbButton'

  export default {
    name: 'file-upload-step',
    components: {
      FileUpload,
      WbButton,
    },
    extends: BaseStep,
    props: {
      bulkStaffUpdate: {
        type: Object,
        required: false,
        default: null,
      },
    },
    emits: ['complete'],
    data() {
      return {
        errors: {},
        uploadedFile: {
          contentType: null,
          key: null,
          url: null,
        },
      }
    },
    methods: {
      errorFor (fieldName) {
        if (!this.errors[fieldName]?.length) { return null }
        return this.errors[fieldName].join(' ')
      },
      handleNext() {
        createBulkStaffUpdate({
          fileUrl: this.uploadedFile.url,
          fileCache: this.uploadedFile.key,
          fileContentType: this.uploadedFile.contentType,
        }).then((bulkStaffUpdate) => {
          this.$emit('complete', bulkStaffUpdate, true)
        }).catch(xhr => {
          if (xhr.responseJSON?.errors) {
            this.errors = xhr.responseJSON.errors || {}
          } else {
            Util.genericAjaxError('An error occurred trying to create this Bulk Staff Update', xhr)
          }
        })
      },
      handleUploadSuccess(uploadedFile) {
        this.uploadedFile = uploadedFile
      },
      handleUploadedFileRemove() {
        this.uploadedFile.key = null
        this.uploadedFile.url = null
        this.uploadedFile.contentType = null
      },
    },
  }
</script>
