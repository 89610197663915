import DocumentSelectView from 'views/document_submissions/i9/document_select_view'
import AttachmentCollectionView from 'views/document_submissions/i9/attachment_collection_view'
import EditAttachmentView from 'views/document_submissions/i9/edit_attachment_view'
import Constants from 'lib/constants'
import I9DocumentationSet from 'models/document_submissions/i9_documentation_set'
import WBRequestXHRDecorator from 'decorators/wb_request_xhr_decorator'

// Modal to update the employee's documentation: i.e. make edits to fields, replace documents, or select new documents
//
// el - Keep undefined. This view is self-contained as a modal. It will append itself to the <BODY> and destroy itself
//      when the modal is hidden (i.e. it is a one-time-use view). As such, callers SHOULD NOT retain a reference to
//      this instance.
// model - (I9) the I9 submission intended to edit
// options:
//   saveCallback - (Function) Callback is save is clicked. Passes one argument, the model
//   documentationChoices - (I9DocumentationChoices collection) the collection of all possible I-9 documents to choose from
//
export default Marionette.LayoutView.extend({
  className: 'i9-employer-update-documentation-modal',
  template: '#i9-employer-update-documentation-tmpl',
  regions: {
    documentationContainer: '.documentation-container',
  },
  ui: {
    modal: '.modal',
    primary: '.btn-primary',           // Primary button, used for 'Continue' and 'Save'
    changeDocumentation: '.change-documentation',  // Link to select new documents

    // Warning message displayed if documentation is edited for already-countersigned submission
    editComplianceWarning: '.edit-compliance-warning',
  },

  events: {
    'click @ui.primary': 'primary',    // Click handler for Primary button
    'click @ui.changeDocumentation': 'showSelect', // Click handler for change documentation
    keypress: 'keypress',
  },

  initialize: function() {
    this.viewState = 'showDocuments'
    this.documentationChoices = this.getOption('documentationChoices')
  },

  // Primary on Enter key
  keypress: function(event) {
    if (event.keyCode == Constants.KeyCode.KEY_RETURN) {
      this.primary()
      event.stopPropagation()
      event.preventDefault()
    }
  },

  onRender: function(){

    // Marionette's render() will create a new DIV element, but it will not exist on the DOM. So, we attach ourselves
    // to the <BODY> here, and later on we will clean ourselves up whenever the modal closes.
    this.$el.appendTo($('body'))

    this.showDocuments()  // Defaults to display documentation

    $.runInitializers(this.$el) // Ladda

    // Immediately show ourselves and bind hidden callback
    this.ui.modal.modal({
      backdrop: 'static',
      keyboard: false,
    })
    this.ui.modal.one('hidden.bs.modal', this.bsModalHidden.bind(this))

    this.ui.editComplianceWarning.hide()
    // if this is a previously countersigned I9
    if (this.model.get('certify_status') == "certify_complete") {
      this.observeChangesAndShowWarning()
    }
  },

  // Click handler for the Primary button - two actions are possible here depending on the state of the UI. One
  // action is to save the model, the other is to continue forward in the UI workflow.
  primary: function(){
    // fire manually to make sure this was activated
    // not activated by enter key in some cases, since it's not the form's button.
    this.ui.primary.data('ladda').start()

    if (this.viewState == 'showDocuments'){
      // all done.
      this.save()

    } else {
      // Check the documents to make sure a valid set was selected before moving on to edit
      this.ui.primary.data('ladda').stop()
      this.documentationContainer.currentView.validate(function(valid){
        this.scrollToTop()
        if (valid){
          this.showDocuments()
        }
      }.bind(this))
    }
  },

  // Call the save callback
  save: function(){
    let validationReceivedCount = 0
    const invalidForms = []

    this.documentationContainer.currentView.children.forEach(function(child, index){
      child.validate(function(valid){
        // Keep track of invalid forms
        if (!valid) {
          invalidForms.push({index: index, form: child})
        }

        // Determine if all forms have finished submitting
        validationReceivedCount++
        if (validationReceivedCount == this.model.documentationSet.length) {
          if (invalidForms.length == 0){
            // Everything is valid. Save the new documentationSet
            //
            this.model.documentationSet.save(this.model.get('id'))
              .done((response) => {
                this.model.set('documentation_set', new I9DocumentationSet(response.documentation_set, { parse: true }))
                this.options.saveCallback(this.model)
                this.ui.modal.modal('hide')
              })
              .always(() => {
                this.ui.primary.data('ladda').stop()
              })
              .fail((xhr) => {
                const xhrDecorated = WBRequestXHRDecorator(xhr)
                if (xhrDecorated.status == 409) {
                  App.Util.ajaxErrorDialog(xhrDecorated.getFlashMessage('error'), xhr)
                }
              })

          } else {
            // Some forms are not valid
            //
            this.ui.primary.data('ladda').stop()
            this.ui.primary.find('.ladda-label').html('Save Changes')

            // Hide the alert from all forms except the first one with an issue
            invalidForms.sort(function(a,b){ return a.index > b.index }).forEach(function(f, idx){
              setTimeout(function(){
                const alert = f.form.$el.find('.alert')
                alert.toggle(idx == 0)
              })
            })
          }
        }
      }.bind(this))
    }.bind(this))
  },

  // Show documentation for review
  showDocuments: function(){
    this.ui.primary.find('.ladda-label').html('Save Changes')
    this.viewState = 'showDocuments'

    this.toggleChangeDocumentation(true)

    // Up until this point, the I9DocumentationSet would have been initialized with I9Attachments with no reference
    // back to their original choices. Because we are now potentially editing these attachments, we need to have a
    // valid reference to the original I9DocumentationChoice - that way, the UI view can be rendered with correct
    // labels, dropdowns, hints, etc.
    this.model.documentationSet.lookupAndLinkToChoices(this.documentationChoices)

    this.documentationContainer.show(new AttachmentCollectionView({
      collection: this.model.documentationSet,
      className: 'i9-documentation',
      childView: EditAttachmentView,
      childViewOptions: function(_model, index){
        return {
          className: 'i9-attachment',
          context: new Backbone.Model({ submitButton: this.ui.primary, reviewMode: true }),
          attachmentViewIndex: index,
        }
      }.bind(this),
    }))
  },

  // Show select new documentation
  showSelect: function(){
    const documentationChoices = this.documentationChoices  // for clarity since there is quite a bit of nesting going on below

    const changeView = function() {
      this.viewState = 'selectDocuments'
      this.toggleChangeDocumentation(false)

      this.documentationContainer.show(new DocumentSelectView({
        model: this.model.documentationSet,
        documentationChoices: documentationChoices,
        className: 'i9-documentation',
        citizenshipDesignation: this.model.get('citizenship_designation'),
      }))

      this.ui.primary.find('.ladda-label').html('Next')

      // If submission is countersigned and a new document is selected enable the 'Next' button and show change warning
      if (this.model.get('certify_status') == "certify_complete") {
        this.ui.modal.find('.list-group-item.document-option:not(.active)').click(() => {
          this.allowEdits()
        })
      };

      this.scrollToTop()
    }.bind(this)

    if (this.model.documentationSet.isLegacy()){
      // Display a warning message if existing permission sets will be overwritten
      bootbox.confirm({
        title: 'Change Documentation',
        message: 'Selecting new documents will clear the existing documents. They will not be deleted until you save changes.',
        className: "modal-danger",
        callback: function(result) {
          if (!result) {return}
          this.model.documentationSet.reset()
          changeView()
        }.bind(this),
        buttons: {
          cancel: { label: "Cancel", className: "btn-default" },
          confirm: { label: "Continue", className: "btn-danger" },
        },
      })
    } else {
      changeView()
    }
  },

  // Identify if any fields have changed, and if they have
  // Show the warning that this will remove prior countersignature &
  // you may be out of compliance
  // the 'Save Changes' button will be disabled unless changes have been made
  observeChangesAndShowWarning: function (){
    this.ui.primary.prop('disabled', true)

    this.$el.find('input').keypress(() => {
      this.allowEdits()
    })

    this.$el.find('input').change(() => {
      this.allowEdits()
    })
  },

  allowEdits: function (){
    this.ui.editComplianceWarning.show()
    this.ui.primary.prop('disabled', false)
  },

  // Toggle visiblity of the 'Select Different Documents' button
  //
  // show - boolean
  toggleChangeDocumentation: function(show){
    if (show){
      this.ui.changeDocumentation.css('display', 'inline-block')
    } else {
      this.ui.changeDocumentation.css('display', 'none')
    }
  },

  scrollToTop: function() {
    $('.modal').scrollTop(0)
  },

  bsModalHidden: function() {
    this.destroy()
  },
})
