<template>
  <div class="row">
    <div class="col-md-7">
      <FormFieldSelect
        label="WorkBright ID"
        :modelValue="localBulkStaffUpdate.employee_id_column_index"
        @update:modelValue="handleWorkbrightIdChange"
        :options="employeeIdColumnOptions"
        orientation="horizontal"
        fieldWidth=""
        class="my-3 pl-1"
      />
      <MatchColumnsTable
        :sortedColumnsMap="sortedColumnsMap"
        @fieldKeyChanged="handleFieldKeyChange"
        @fieldIgnoreChanged="handleFieldIgnoreChange"
      />
    </div>
    <div class="col-md-5">
      <TableValidator
        :sortedColumnsMap="sortedColumnsMap"
        :employeeIdColumnIndex="localBulkStaffUpdate.employee_id_column_index"
        @nextStep="handleNextStep"
      />
    </div>
  </div>
</template>

<script>
  import FormFieldSelect from 'components/common/FormFieldSelect'
  import BaseStep from 'components/user_updates/steps/BaseStep'
  import MatchColumnsTable from 'components/user_updates/steps/match_columns_step/MatchColumnsTable'
  import TableValidator from 'components/user_updates/steps/match_columns_step/TableValidator'
  import {updateBulkStaffUpdate} from 'services/admin/BulkStaffUpdatesService'
  import Util from 'lib/util'

  export default {
    name: 'match-columns-step',
    components: {
      FormFieldSelect,
      MatchColumnsTable,
      TableValidator,
    },
    extends: BaseStep,
    props: {
      bulkStaffUpdate: {
        type: Object,
        required: true,
      },
    },
    emits: ['complete'],
    data() {
      return {
        localBulkStaffUpdate: {...this.bulkStaffUpdate},
      }
    },
    computed: {
      sortedColumnsMap() {
        return Object.keys(this.localBulkStaffUpdate.columns_map)
          .map(key => ({
            position: key,
            name: this.bulkStaffUpdate.columns_map[key].name,
            field_key: this.bulkStaffUpdate.columns_map[key].field_key,
            ignored: this.bulkStaffUpdate.columns_map[key].ignored,
            sample: this.bulkStaffUpdate.columns_map[key].sample,
          }))
          .sort((a, b) => parseInt(a.position) - parseInt(b.position))
      },
      employeeIdColumnOptions() {
        const entries =  Object.entries(this.localBulkStaffUpdate.columns_map)
        return entries.map(([value, {name}])=>({label: name, value: parseInt(value)}))
      },
    },
    methods: {
      handleWorkbrightIdChange(newValue) {
        this.localBulkStaffUpdate.employee_id_column_index = newValue
      },
      handleFieldKeyChange(position, newFieldKey) {
        if (this.localBulkStaffUpdate?.columns_map) {
          this.localBulkStaffUpdate.columns_map[position].field_key = newFieldKey
        }
      },
      handleFieldIgnoreChange(position, newValue) {
        if (this.localBulkStaffUpdate?.columns_map) {
          if (newValue) {
            this.localBulkStaffUpdate.columns_map[position].field_key = null
          }
          this.localBulkStaffUpdate.columns_map[position].ignored = newValue
        }
      },
      handleNextStep() {
        updateBulkStaffUpdate({...this.localBulkStaffUpdate})
          .then((bulkStaffUpdate) => {
            this.$emit('complete', bulkStaffUpdate, true)
          }).catch(xhr => {
            if (xhr.responseJSON?.errors) {
              this.errors = xhr.responseJSON.errors || {}
            } else {
              Util.genericAjaxError('An error occurred trying to create this Bulk Staff Update', xhr)
            }
          })
      },
    },
  }
</script>
