export default Marionette.Controller.extend({
  initialize: function() {},

  deleteWebhook: function(id) {
    const webhook = App.Webhooks.listTableCollectionView.collection.get(id)
    if (!webhook)
    {return}

    webhook.destroy({
      wait: true,
      wbGenericFailureMsg: "Sorry, we couldn't remove this webhook.",
    }).done(function(_data, _status, xhr) {
      App.Util.showAjaxFlashNotice(xhr)
    })
  },
})
