// DataTables initialization and defaults
//
import 'datatables.net-bs'
import 'datatables.net-buttons-bs'
import 'datatables.net-buttons/js/buttons.print'
import 'datatables.net-buttons/js/buttons.html5'
import 'datatables.net-buttons/js/buttons.flash'
import 'datatables.net-fixedcolumns'

if ($.fn.dataTable && $.fn.dataTable.defaults) {
  $.fn.dataTable.render._escapeHTML = function() {
    return function (data, type, _row, _settings) {
      return (type === 'display') ? _.escape(data) : data
    }
  }

  $.extend(true, $.fn.dataTable.defaults, {
    dom: '<"row dt-table-header"<"col-xs-6 dt-header-left"lr<"dt-toolbar">><"col-xs-6 dt-header-right"f<"dt-custom-filter">>>tip',

    /*
     * These next few _domXYZ values are not actually real DataTables default settings. Instead, they are pre-defined layouts
     * which can be used in place of the regular `dom` layout (above), e.g.:
     *    dom: $.fn.dataTable.defaults._domFullSearch,
     */
    _domWithMiddleHeader: '<"row dt-table-header"<"col-xs-4 dt-header-left"lr<"dt-toolbar">><"col-xs-4 dt-header-middle"><"col-xs-4"f<"dt-custom-filter">>>tip',
    _domFullSearch: "<'row dt-table-header'<'col-xs-12'f<'dt-custom-filter'>>>'" +
                    "<'row'<'col-xs-12't>>" +
                    "ip",

    paginate: false,
    lengthChange: false,
    filter: true,
    sort: true,
    info: true,
    autoWidth: false,
    pagingType: 'full_numbers',
    columnDefs: [
      { orderable: false, targets: ["row-selector", "unsortable", "action-col"] },
      { visible: false, targets: ["data-col"] },
      { width: '15px', type: 'html', targets: 'row-selector' },
    ],
    stripClasses: [],
    preDrawCallback: function(settings) {
      const $tableWrapper = $(settings.nTableWrapper)

      $tableWrapper.find('.dataTables_filter input').addClass('clearable')

      if (settings.oInit._wrapperClass) {
        $tableWrapper.addClass(settings.oInit._wrapperClass)
      }
    },
    initComplete: function() {
      $(".dataTables_length .select2").select2({
        minimumResultsForSearch: -1,
      })
    },
    language: {
      search: "",
      searchPlaceholder: "Search",
      info: "_TOTAL_ total items",
      infoEmpty: "",
      zeroRecords: '<div class="text-center"><h4>No matching items found</h4><p>Try changing your search criteria or filters.</p></div>',
      emptyTable: '<div class="text-center"><h4>No items to show</h4></div>',
      infoFiltered: " (filtered from _MAX_ items)",
      lengthMenu: 'Show&nbsp; <select class="select2" data-search-off="true">'+
                      '<option value="10">10</option>'+
                      '<option value="25">25</option>'+
                      '<option value="50">50</option>'+
                      '<option value="-1">All</option>'+
                      '</select>&nbsp; items per page',
      // "paginate": {
      //    "next": 'Next <i class="fa fa-chevron-right"></i>'
      //  }
    },
  })
}

if ($.fn.dataTable && $.fn.dataTable.ext) {
  // Tell DT not to trap errors; instead our normal global error handlers will fire
  $.fn.dataTable.ext.errMode = 'none'
}
