// eslint-disable-next-line no-unused-vars
export default function(Sector, App, Backbone, Marionette, $, _) {
  Sector.addInitializer(function() {
    App.KioskCommonInit.start()
  })

  Sector.addFinalizer(function() {
    App.KioskCommonInit.stop()
  })
}
