<template>
  <Panel variant="snapshot">
    <template v-slot:heading>
      <div class="panel-title">
        <h3>Action Required</h3>
        <h4>{{ $locale.t('everify.status.pending_referral.title') }}</h4>
      </div>
    </template>
    <template v-slot:body>
      <p v-html="instructions" />
      <p v-html="disclaimer" />
      <ul>
        <li v-for="option in options" :key="option" v-html="option"/>
      </ul>
      <FormFieldCheckbox
        :errors="formErrors"
        gridColumns="col-xs-12"
        :label="$locale.t('everify.status.pending_referral.employeeNotifiedCheckboxLabel')"
        name="employee_notified"
        :required="true"
        v-model="formData.employeeNotified"
      />
      <div class="mt-4">
        <WbButton
          ref="referButton"
          label="Refer Case"
          :isLoading="isReferCaseButtonLoading"
          @click="submitReferCase"
        />
        <WbButton
          ref="noActionButton"
          label="No Action"
          :isLoading="isNoActionButtonLoading"
          context="danger"
          @click="submitNoAction"
        />
        <span
          v-if="allowDownload"
          class="pull-right d-flex"
        >
          Download Further Action Notice:
          <a
            ref="fanDownloadLink"
            class="btn btn-link py-0 pl-0"
            :class="{'disabled': shouldDisableFanLink}"
            :href="everifyCase.fan_url"
            download
            target="_blank"
          >
            English
          </a>
          <a
            ref="fanSpanishDownloadLink"
            class="btn btn-link py-0 pl-0"
            :class="{'disabled': shouldDisableFanSpanishLink}"
            :href="everifyCase.fan_url_spanish"
            download
            target="_blank"
          >
            Spanish
          </a>
        </span>
        <EverifyCaseCloseForm
          :everifyCase="everifyCase"
          @closeTimeout="closeModal"
        />
      </div>
    </template>
  </Panel>
</template>

<script>
  import { mapActions } from 'vuex'
  import EverifyCaseCloseForm from 'components/everify/cases/EverifyCaseCloseForm'
  import WbButton from 'components/common/WbButton'
  import FormFieldCheckbox from "components/common/FormFieldCheckbox"
  import Panel from 'components/common/Panel'
  import Util from 'lib/util'

  export default {
    components: {
      WbButton,
      FormFieldCheckbox,
      Panel,
      EverifyCaseCloseForm,
    },

    props: {
      everifyCase: {
        type: Object,
        require: true,
      },
      caseEmployee: {
        type: Object,
        require: true,
      },
      allowDownload: {
        type: Boolean,
        default: true,
      },
    },

    data() {
      return {
        actor: "admin",
        isNoActionButtonLoading: false,
        isReferCaseButtonLoading: false,
        formErrors: {},
        formData: {},
      }
    },

    computed: {
      caseId () {
        return this.everifyCase.id
      },

      instructions() {
        return this.$locale.t('everify.status.pending_referral.instructions', this.caseEmployee.first_name)
      },

      disclaimer () {
        return this.$locale.t('everify.status.pending_referral.disclaimer')
      },

      options() {
        return this.$locale.t('everify.status.pending_referral.options', this.caseEmployee.first_name)
      },

      shouldDisableFanLink() {
        return !this.everifyCase.fan_url
      },

      shouldDisableFanSpanishLink() {
        return !this.everifyCase.fan_url_spanish
      },
    },

    beforeUnmount() {
      this.isNoActionButtonLoading = false
      this.isReferCaseButtonLoading = false
    },

    methods: {
      submitNoAction() {
        this.isNoActionButtonLoading = true

        this.submitTNCNoAction({
          caseId: this.caseId,
          employeeNotified: this.formData.employeeNotified,
          actor: this.actor,
        }).then(() => {
          this.isNoActionButtonLoading = false
          this.closeModal()
          Util.showFlashNotice("Request is processing, case will automatically update when the request has finished.")
        }).catch((xhr) => {
          this.isNoActionButtonLoading = false
          if (xhr.responseJSON && xhr.responseJSON.errors) {
            this.formErrors = xhr.responseJSON.errors || {}
          } else {
            Util.genericAjaxError("An error occurred.", xhr)
          }
        })
      },

      submitReferCase() {
        this.isReferCaseButtonLoading = true

        this.submitTNCReferCase({
          caseId: this.caseId,
          employeeNotified: this.formData.employeeNotified,
          actor: this.actor,
        }).then(() => {
          this.isReferCaseButtonLoading = false
          this.closeModal()
          Util.showFlashNotice("Request is processing, case will automatically update when the request has finished.")
        }).catch((xhr) => {
          this.isReferCaseButtonLoading = false
          if (xhr.responseJSON && xhr.responseJSON.errors) {
            this.formErrors = xhr.responseJSON.errors || {}
          } else {
            Util.genericAjaxError("An error occurred.", xhr)
          }
        })
      },

      closeModal() {
        this.$emit('close')
      },

      ...mapActions({
        submitTNCNoAction: 'everify_cases/submitTNCNoAction',
        submitTNCReferCase: 'everify_cases/submitTNCReferCase',
      }),
    },
  }
</script>
