import { ajax } from 'jquery'
import { getRoute } from 'lib/routes'

export function createBulkStaffUpdate({fileUrl, fileCache, fileContentType}) {
  return new Promise((resolve, reject) => {
    ajax({
      method: 'POST',
      type: 'JSON',
      url: getRoute('user_updates_path'),
      data: {
        bulk_staff_update: {
          file_url: fileUrl,
          file_cache: fileCache,
          file_content_type: fileContentType,
        },
      },
    }).success((_responseData, _status, xhr) => {
      resolve(xhr)
    }).fail(xhr => {
      reject(xhr)
    })
  })
}

export function updateBulkStaffUpdate({id, employee_id_column_index, columns_map}) {
  return new Promise((resolve, reject) => {
    ajax({
      method: 'PATCH',
      type: 'JSON',
      url: getRoute('user_update_path', { id }),
      data: {
        bulk_staff_update: {
          employee_id_column_index,
          columns_map,
        },
      },
    }).success((_responseData, _status, xhr) => {
      resolve(xhr)
    }).fail(xhr => {
      reject(xhr)
    })
  })
}

export function fetchBulkStaffUpdates (page = 1) {
  return new Promise((resolve, reject) => {
    ajax({
      method: 'GET',
      type: 'JSON',
      url: `${getRoute('list_user_updates_path')}?page=${page}`,
    }).success((xhr) => {
      resolve(xhr)
    }).fail(xhr => {
      reject(xhr)
    })
  })
  return
}
