import { mapState, mapMutations } from 'vuex'
import { DELETE_PAGE_CONTEXT_KEY } from 'vuex/mutation_types'

// Mixin to provide modal functionality to any Vue component
//
// Events Emitted
//
//   modalShow - immediately after the Bootstrap modal `show` method is called
//   modalShown -  after modal is actually shown
//   modalHidden - after Bootstrap modal signals hide with `hidden.bs.modal`
//
// Opening Modal: The modal opens in response the the Vuex 'watchPageContextVariable' being set.
// Closing Modal: Call hide() directly on the modal via a ref. While clearing the `watchPageContextVariable` Vuex value would
//                work, it will close quickly and not use the Boostrap animations.
export default {
  data() {
    return {
      isShown: false,
    }
  },
  props: {
    // Title to include in modal header. Optional, can use slot content instead
    title: {
      type: String,
      required: false,
    },

    // The name of the pageContext key to watch. When the value of this key is defined, the modal will show.
    // This key will also automatically be deleted when the modal is hidden.
    watchPageContextVariable: {
      type: String,
      required: true,
    },

    // Prevent the modal from closing (e.g. while submitting a form)
    // There are a few ways modal can be closed, in particular:
    //   - the hide() method
    //   - clicking the backdrop
    //   - clicking the X
    //   - the Bootstrap JS modal('hide') event (can't block this)
    //
    // Before closing, this mixin checks the `locked` prop to determine if it's allowed.
    // Avoid calling the modal('hide') directly, since we can check if locked before hand.
    // The close via 'X' has been updated to call `hide()`, rather than using `data-dismiss`.
    locked: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapState({
      shouldShow({ pageContext }) {
        return pageContext[this.watchPageContextVariable] !== undefined
      },
    }),
  },
  methods: {
    ...mapMutations({
      deletePageContextKey: DELETE_PAGE_CONTEXT_KEY,
    }),

    // Hide the modal, explicitly triggered through built-in "X" cancel control
    cancel() {
      this.hide()
      this.$emit('cancelled')
    },

    hide() {
      if (!this.locked) {
        $(this.$refs.panel).modal('hide')
      }
    },
  },
  watch: {
    locked: function(val) {
      if (!$(this.$refs.panel).data('bs.modal')) { return };

      // Locking prevents closing the modal (e.g. while submitting a form)
      // Prevent clicking the backdrop to close by updating to 'static'
      $(this.$refs.panel).data('bs.modal').options.backdrop = val ? 'static' : true
    },

    shouldShow: function(val) {
      if (val && !this.isShown) {

        // Defer showing the Bootstrap modal until Vue is done updating the DOM
        this.$nextTick(() => {
          const $modalEl = $(this.$refs.panel)

          // This event fires immediately when the show instance method is called. 
          $modalEl.one('show.bs.modal', e => { 
            this.$emit('modalShow', e)
          })

          $modalEl.modal('show')
          
          // This event is fired when the modal has been made visible to the user
          $modalEl.one('shown.bs.modal', e => {
            this.$emit('modalShown', e)

            this.isShown = true
          })

          // Update state if modal is closed via native Bootstrap JS handlers (e.g. data-dismiss or `.modal('hide')`)
          $modalEl.one('hidden.bs.modal', () => {
            this.isShown = false
            this.deletePageContextKey(this.watchPageContextVariable)
            this.$emit('modalHidden')
          })
        })
      } else if (!val && this.isShown) {
        $(this.$refs.panel).modal('hide')
      }
    },
  },
}
