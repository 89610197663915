import { isHeic, heicTo } from 'heic-to'

export async function isHeicFile(file) {
  return await isHeic(file)
}

export async function heicToJpg (file) {
  const newFileName = `${file.name.split('.')[0]}.jpg`
  const convertedBlob = await heicTo({
    blob: file,
    toType: 'image/jpeg',
    quality: 1,
  })

  return new File([convertedBlob], newFileName, { type: convertedBlob.type })
}
