import Dropzone from 'dropzone'
import BrowserSupport from 'lib/browser_support'
import ErrorService from 'lib/error_service'
import AWSRequestXHRDecorator from 'decorators/aws_request_xhr_decorator'
import Util from 'lib/util'
import {
  closeMediaStreams,
  hideDZButtons,
  initTakePhoto,
  resetSelectOptions,
  showDZButtons,
} from "./dropzone/take_photo_on_desktop"

// DROPZONE OPERATOR
export default function ($, window) {
  "use strict"

  // Create the defaults once
  const defaults = {
    zoneType: 'single',                           // single or multi
    cacheDir: '/uploads/tmp/component_files/',
    cancelBtnSel: '.js-dz-cancel-upload',
    resetBtnSel: '.js-dz-reset-upload',
    fileTypeErrorMsg: 'Sorry, you can only upload PDF files or images (PNG, JPG, GIF, etc) at this time.<br/><br/>' +
      'If you are attempting to upload a Word document, please choose File -> Save As and save it as a PDF instead.',
    dzOptions: {
      timeout: 4 * 60 * 1000,                     // increase timeout on uploads from 30 seconds to 4 min
      url: App.Routes.External.aws_dropzone_url,  // Direct-to-AWS upload URL
      addRemoveLinks: false,
      maxFilesize: 20,                            // MB
      dictInvalidFileType: 'file_type',           // override these with 'keys' instead; we'll handle messaging ourselves
      dictFileTooBig: 'file_too_big',             // " "
      thumbnailWidth: 150,
      thumbnailHeight: 150,
      previewsContainer: null,
      renameFile: (file) => {
        let filename = file.name

        // Rare error, somehow users can submit files without a name,
        // just an extension, like '.png'
        // Give the file a random name
        if (filename && filename.match(/^\.[A-z]+$/)) { // Just an extension? Like .png?
          filename = `file_${Date.now()}${filename}`
        }
        return filename
      },
      // Specify default extensions; note: using `image/*` will allow obscure image
      // types through (such as TIFF); ensure this matches extension_whitelist in whatever
      // uploader you're using on the backend and the Reform validations for non-DZ uploads
      acceptedFiles: '.jpg,.jpeg,.gif,.png,.pdf',
    },

    // Thumbnail icons for filetypes that cannot be previewed
    noPreviewFiletypes: [
      {
        thumbnailUrl: App.Routes.Images.pdf,
        matchString: /pdf/,
        extensions: ['pdf'],
      },
      {
        thumbnailUrl: App.Routes.Images.csv,
        matchString: /csv/,
        extensions: ['csv'],
      },
      {
        thumbnailUrl: App.Routes.Images.excel,
        matchString: /excel|spreadsheet/,
        extensions: ['xls', 'xlsx'],
      },
      {
        thumbnailUrl: App.Routes.Images.keynote,
        matchString: /keynote/,
        extensions: ['key'],
      },
      {
        thumbnailUrl: App.Routes.Images.numbers,
        matchString: /numbers/,
        extensions: ['numbers'],
      },
      {
        thumbnailUrl: App.Routes.Images.pages,
        matchString: /pages/,
        extensions: ['pages'],
      },
      {
        thumbnailUrl: App.Routes.Images.powerpoint,
        matchString: /powerpoint/,
        extensions: ['ppt', 'pptx'],
      },
      {
        thumbnailUrl: App.Routes.Images.rtf,
        matchString: /rtf/,
        extensions: ['rtf'],
      },
      {
        thumbnailUrl: App.Routes.Images.txt,
        matchString: /txt/,
        extensions: ['txt'],
      },
      {
        thumbnailUrl: App.Routes.Images.word,
        matchString: /word/,
        extensions: ['doc', 'docx'],
      },
    ],
  }

  $.extend(DZOperator.prototype, {
    init: function () {
      const self = this
      const dropzoneContainerElements = this.$form.find('.dropzone-container')
      const dropzoneFileElements = this.$form.find('.dropzone-file')
      const takePhotoEnable = dropzoneContainerElements.length > 0
      const dropzoneElement = takePhotoEnable ? dropzoneContainerElements : dropzoneFileElements

      this._dropZones = _(new Array())
      this.hasImgEditor = !!this.options.dzOptions.imageEditorView && !((new BrowserSupport).hasMobileUploadIssues)

      // Don't create thumbnails for ImageEditor
      if (this.hasImgEditor) {
        this.options.dzOptions.createImageThumbnails = false
      }

      if (this.options.zoneType == 'single') {
        this.options.dzOptions.maxFiles = 1
      }

      dropzoneElement.each(function () {
        let takePhotoUIElements
        // Report when browser is not supported by DZ but user got this far.
        //
        if (!Dropzone.isBrowserSupported() || self.options.dzOptions['forceFallback'] === true) {
          // Notify us when DZO is initialized in a browser we do not support
          new ErrorService("Browser is not supported", 'DropzoneNotSupported').report()

          // Short-circuit leaving DZ initialized, which will fall back to old-school file INPUTs
          return
        }

        // before we init Dropzone on this bad boy, grab the 'fallback' input field because that will tell us
        // the ID of this field
        const fieldId = $(this).find("input[type='file']").attr('id')
        if (takePhotoEnable) {
          self.options.dzOptions["clickable"] = [$(this).find('.dropzone-file')[0]]
          self.options.dzOptions["previewsContainer"] = this.querySelector('.dropzone-previews')
        }
        const dz = new Dropzone(this, self.options.dzOptions)

        // After initializing the dropzone instance, set its `accept` option to call our dz_accept method, which
        // does the presigning of the upload. Our dz_accept method will only get called after the default built-in `accept` method
        // succeeds, which is what does the MIME type and file size checks
        dz.options.accept = function (file, done) {
          self.dz_accept(dz, file, done)
        }

        // save refs to the hidden fields which store our AWS key, content type and URL
        $.extend(dz, {
          $awsKeyField: self.$form.find(`input[type='hidden']#${fieldId}_cache`),
          $contentTypeField: self.$form.find(`input[type='hidden']#${fieldId}_content_type`),
          $awsURLField: self.$form.find(`input[type='hidden']#${fieldId}_url`),
          $element: $(this),
          $mediaStream: null,
          $imageCapture: null,
        })

        if (takePhotoEnable) { takePhotoUIElements = initTakePhoto(this, dz) }
        const maxRetries = 3

        self._dropZones.push(dz)

        // save a reference to the actual dropzone instance
        $(this).data('dropzone', dz)

        // DZ Hooks
        dz.on('addedfile', function (file) {
          if (takePhotoEnable) {
            resetSelectOptions(takePhotoUIElements)
            hideDZButtons(takePhotoUIElements)
          }
          self.dz_addedFile(dz, file)
        })
        dz.on('success', function (file, response) {
          // TO TEST "Waiting for uploads to finish", uncomment the next few lines (which pauses call to dz_success):
          //
          // setTimeout(function() {
          self.dz_success(dz, file, response)
          // }, 5000);
        })
        dz.on('sending', function (file, xhr, formData) {
          self.dz_sending(dz, file, xhr, formData)
        })
        dz.on('error', function (file, errorMessage, xhr) {
          const shouldCheckRetry = !["file_empty", "file_too_big"].includes(errorMessage)
          if (shouldCheckRetry) {
            file.retryTimes = file.retryTimes ? file.retryTimes + 1 : 1

            if (file.retryTimes < maxRetries) {
              file.status = Dropzone.QUEUED
              return
            } else if (takePhotoEnable) {
              showDZButtons(takePhotoUIElements)
            }
          }

          self.dz_error(dz, file, errorMessage, xhr)
        })
        dz.on('complete', function () {
          if (takePhotoEnable) {
            closeMediaStreams(dz.$mediaStream)
          }
          self.dz_complete(dz)
        })
        dz.on("maxfilesexceeded", function (file) {
          self.dz_maxfilesexceeded(dz, file)
        })

        // Buttons
        if (self.options.cancelBtnSel && self.options.cancelBtnSel.length > 0) {
          $(this).on('click', self.options.cancelBtnSel, function () {
            if (takePhotoEnable) {
              showDZButtons(takePhotoUIElements)
            }
            self.cancelUpload(dz)
          })
        }
        if (self.options.resetBtnSel && self.options.resetBtnSel.length > 0) {
          $(this).on('click', self.options.resetBtnSel, function () {
            if (takePhotoEnable) {
              showDZButtons(takePhotoUIElements)
            }
            self.resetUpload(dz)
          })
        }

        self._initExistingFile(dz)

        // Show a warning tooltip for Android devices <= 5.1 due to suspected OS issues
        if ((new BrowserSupport).hasMobileUploadIssues) {
          const browserWarningTooltip = $('<a>').attr('href', 'javascript:void(0)').addClass('dz-android-warning small').attr('data-toggle', 'tooltip').attr('data-placement', 'bottom').attr('data-html', 'true').attr('title', 'This version of Android has known problems uploading straight from the camera.<br/><br/>Try opening the Camera app separately and saving the photo to your gallery. Then come back here and choose that photo from the gallery.').addClass('breathe').text('Having trouble taking a picture?')
          browserWarningTooltip.tooltip()

          $(this).parent().append($('<div>').addClass('text-right breathe').append(browserWarningTooltip))
          $('.dz-android-warning').slice(1).hide() // only show the first instance on the page
        }
      })

      // Handle form submissions ourselves, so we can be sure all uploads are complete.
      this.$form.on('submit', $.proxy(this._checkPendingAndSubmit, this))

      return this
    },

    // Returns true if any files are still uploading; otherwise returns false
    anyUploading: function () {
      return !this._dropZones.every(function (dz) {
        return (dz.getUploadingFiles().length == 0 && dz.getQueuedFiles().length == 0)
      })
    },

    cancelUpload: function (dz) {
      // HACK: dz.removeAllFiles throws an error (bug) that doesn't actually affect us; so just catch it and ignore it
      try {
        dz.removeAllFiles(true)
      } catch (e) {
      } finally {
        dz.$element.addClass('dz-clickable')
        if (dz.$element.find('.progress-bar').length > 0) // sometimes it's nil?
        {
          dz.$element.find('.progress-bar').style('width', '0%')
        }
      }
    },

    resetUpload: function (dz) {
      // Reset Image Editor view
      if (this.hasImgEditor) {
        dz.$element.find('.img-ed').hide()
      }

      // Clear hidden fields
      dz.$awsKeyField.val('')
      dz.$awsURLField.val('')
      dz.$contentTypeField.val('')

      // Trigger a change event
      $(dz.$awsKeyField).trigger('change')
      $(dz.$awsURLField).trigger('change')
      $(dz.$contentTypeField).trigger('change')

      // Reset UI
      dz.$element.find('.dz-preview').remove()

      if (!!dz.previewsContainer) {
        dz.previewsContainer.classList.remove('dz-complete', 'dz-max-files-reached', 'dz-started')
      } else {
        dz.$element.removeClass('dz-complete').removeClass('dz-max-files-reached').removeClass('dz-started')
      }

      // Reset DZ internals
      dz.files = []
      dz.options.maxFiles = 1
      dz.enable()
    },
  })

  function DZOperator(formElement, options) {
    this.formElement = formElement
    this.$form = $(formElement)

    // We store the submit button so that we can show a 'Waiting for uploads to finish' message if user tries to
    // submit the form whilst an upload is in progress. If `false` or no submit button found, feature will be disabled.
    this.$submitBtn = _.result(options, 'submitButton', this.$form.find('button[type="submit"]'))
    if (!this.$submitBtn || !this.$submitBtn.length || this.$submitBtn.length == 0) {
      delete this.$submitBtn
    }

    if (options.dzOptions !== undefined && options.dzOptions.presignURL === undefined) {
      window.console.log('DZOperator ERROR: presignURL not defined!', options)
    }

    // Merge the passed in dzOptions with the default dzOptions; we have to do this manually since dzOptions
    // is a sub-hash
    const dzOptions = $.extend({}, defaults.dzOptions)
    if (options.dzOptions) {
      options.dzOptions = $.extend(dzOptions, options.dzOptions)
    }

    this.options = $.extend({}, defaults, options)
    this._defaults = defaults

    /* PRIVATE */
    {
      // Whether or not to accept the file that was dropped. This runs our pre-signing logic to allow direct-to-S3
      // uploads.
      // See http://www.dropzonejs.com/#config-accept for info on the DZ.accept() option.
      // See https://drive.google.com/drive/u/0/folders/0BybDdPQ4lBEQfk1aT3ZBOFJmcW9xXzBPS0g4SlJQY3JLOF9hcnhrbG45MHFxbDkyTjluTkU
      // for info on how direct-to-S3 uploads work in our app.
      this.dz_accept = function (dz, file, dzDoneCallbackFn) {
        // Don't even try to upload a zero-byte file
        if (file.size == 0) {
          dzDoneCallbackFn('file_empty')
          return
        }

        dz.$element.find('.dz-file-message').text('Preparing to upload...')
        dz.$element.removeClass('dz-clickable')

        // Get the AWS signature credentials that make it possible to upload directly to S3
        //
        $.ajax({
          type: 'POST',
          url: dz.options.presignURL,
          data: {
            filename: file.name,
            content_type: file.type,
          },
          progressBar: false,
          wbGenericFailureMsg: "Sorry, we experienced an error preparing your file for upload.",
        })
          .done((_responseText, _textStatus, xhr) => {
            file.awsSignature = xhr.responseJSON
            dzDoneCallbackFn()
          })
          .fail(() => {
            dzDoneCallbackFn('presign_failed')
          })
      }

      this.dz_addedFile = function (dz, file) {
        this.displayThumbnail(dz, file)
      }

      this.dz_success = function (dz, file, response) {
        // save the cached filename & type we got back from AWS (via XML); response can be null if we manually call this
        // via the _initExistingFile method during a form reload
        if (response && response.length > 0) {
          const $awsData = $(response)
          const location = $($awsData).find('Location').text()
          const key = $($awsData).find('Key').text()


          dz.$awsKeyField.val(key)
          dz.$contentTypeField.val(file.type)
          dz.$awsURLField.val(location)

          // Trigger a change event
          $(dz.$awsKeyField).trigger('change')
          $(dz.$awsURLField).trigger('change')
          $(dz.$contentTypeField).trigger('change')

          this.displayThumbnail(dz, file, location)
        }

        // UI stuff
        dz.$element.find('.progress-msg').html('<i class="fa fa-check"></i> File uploaded successfully')
        dz.$element.find('.dz-file-message').text('Made a mistake? No problem, just click Remove to start over:')

        if (!!dz.previewsContainer) {
          dz.previewsContainer.classList.add('dz-complete')
        } else {
          dz.$element.addClass('dz-complete')
        }

        dz.disable()

        // if we were waiting for uploads to finish in order to submit the form, go ahead and re-submit the form. If there are
        // still more files being uploaded, the handler will catch it and continue to wait.
        if (this._waitingToSubmit) {
          this.$form.submit()
        }

        // Show the Image Editor if provided
        // Do not show to Android version <= 5.1 due to suspected OS issues
        if (this.hasImgEditor && this.fileIsImage(file)) {
          // Create the element for the ImageEditor to bind to
          const previewElement = dz.previewsContainer || dz.$element
          const imgEdElement = document.createElement('div')
          imgEdElement.classList.add('img-ed')
          previewElement.prepend(imgEdElement)

          // override default display:table
          if (!!dz.previewsContainer) {
            dz.previewsContainer.classList.add('display', 'block')
          } else {
            dz.$element.css('display', 'block')
          }

          const imgEd = new this.options.dzOptions.imageEditorView({
            el: dz.$element.find('.img-ed')[0],
            dz: dz,
          })

          imgEd.render()

          // Hide progress bar after image editor loaded
          dz.$element.find('.dz-progress').hide()
        }
      }

      // Returns boolean value that represents if file MIME type is image or not
      this.fileIsImage = function (file) {
        if (!file) {
          return
        }

        const imgMIMETypes = ['image/jpeg', 'image/png', 'image/gif', 'image/tiff']
        const fileType = file.type

        // REVIEW: This is to catch if file does not have type, is this ever the case?
        if (fileType) {
          return _.contains(imgMIMETypes, fileType)
        } else {
          return false
        }
      },

      // Emits thumbnail action to display thumbnail and handles UI accordingly
      this.displayThumbnail = function (dz, file, cachedDataUrl) {
        // Do not emit thumbnail if image editor used and file is image
        if (this.hasImgEditor && this.fileIsImage(file)) {
          dz.$element.find('.dz-thumbnail').hide()
          return
        }

        // Do not emit thumbnail if file has no preview or no dataUrl passed in
        const thumbnailUrl = this.thumbnailForNoPreviewFile(file) || cachedDataUrl
        if (thumbnailUrl) {
          dz.emit("thumbnail", file, thumbnailUrl)
        }
      },

      // Thumbnail url for file types that do not have previews
      //
      // Returns path to thumbnail image
      this.thumbnailForNoPreviewFile = function (file) {
        let thumbnailUrl = null
        const fileExtension = file.name.split('.').pop()

        _.each(this.options.noPreviewFiletypes, function (filetype) {
          if (file.type.match(filetype.matchString) || filetype.extensions.indexOf(fileExtension) >= 0) {
            thumbnailUrl = filetype.thumbnailUrl
          }

        })
        return thumbnailUrl
      }

      this.dz_sending = function (dz, file, _xhr, formData) {
        // Before we submit the file to S3, we need to copy the signature credentials to the FormData sent
        // (these credentials are set as .awsSignature via the dz_accept callback)
        $.each(file.awsSignature, function (k, v) {
          formData.append(k, v)
        })

        dz.$element.find('.dz-file-message').text('Uploading, please wait...')
        this._showScreenLockWarning()
      }

      this.dz_error = function (dz, file, errorMessage, xhr) {
        dz.removeFile(file)
        dz.$element.addClass('dz-clickable')

        if (xhr) {
          // Couldn't upload the file to AWS. We report it to the ErrorService manually because Dropzone is not
          // dependent on jQuery; therefore, there is no guarantee that jQuery global Ajax callbacks will fire.
          //
          // We also don't report client network errors (i.e. readyState == 0)
          new ErrorService("Failed to upload file to DZ bucket", "AWSUploadError").report({
            dz_file: file,
            dz_error: errorMessage,
            xhr_readyState: xhr.readyState,
            xhr_status: xhr.status,
            xhr_statusText: xhr.statusText,
            aws_error: AWSRequestXHRDecorator(xhr).responseJSON['Error'],
          })

          Util.ajaxErrorDialog('Sorry, there was an issue uploading this file. Please refresh the page and try again.', xhr)
        } else {
          // Validation error messages
          switch (errorMessage) {
          case 'file_type':
            Util.errorDialog(this.options.fileTypeErrorMsg, "File Type Not Allowed")
            break
          case 'file_too_big':
            Util.errorDialog(`Sorry, your file is too big to upload. Files must be
                                ${this.options.dzOptions.maxFilesize} MB or smaller.`,
            "File Too Big")
            break
          case 'file_empty':
            Util.errorDialog(`It looks like this file is empty (0 bytes). Please double-check
                                the file by opening it up in a different app (such as a PDF reader or image viewer).
                                <br/><br/>
                                If you're sure the file is OK but you still get this error message, you may need to restart
                                your browser and try again.`,
            "Empty File")
            break
          }
        }
      }

      this.dz_complete = function () {
        // Just in case...
        NProgress.done()
        NProgress.remove()
      }

      this.dz_maxfilesexceeded = function (dz, file) {
        // don't show it or anything.
        dz.removeFile(file)
      }

      this._initExistingFile = function (dz) {
        // do we already have a file stored on the server?
        if (!dz.$awsKeyField.val() || dz.$awsKeyField.val().length == 0) {
          return
        }

        const mockFile = {
          name: dz.$awsKeyField.val(),
          size: 12345,
          type: dz.$contentTypeField.val(),
        }

        // Actually push file into array so UI updates are consistent
        dz.files.push(mockFile)

        dz.emit('addedfile', mockFile)

        // Send a null response to success event as there is no actual request happening for a mockFile
        dz.emit('success', mockFile, null)
        dz.emit('complete', mockFile)
      }

      // Show the warning message not to let the screen lock
      this._showScreenLockWarning = function () {
        if ((new BrowserSupport).isMobile) {
          $('.dz-screen-lock-warning').removeClass('hidden')
        }
      }

      this._checkPendingAndSubmit = function (e) {

        // If we're not uploading anything, go ahead and submit!
        if (!this.anyUploading()) {
          if (this.$submitBtn) {
            // HACKFIX: if we had already been waiting for the uploads to finish, ladda would've been started, which means
            // that the button would have the disabled property set. In some browsers (Chrome and IE8, maybe others) the
            // form won't submit if the submit button is `disabled`. We get around this by temporarily turning off that attribute.
            // We'll re-set it a split second later, after the form submission is in progress.
            if (this._waitingToSubmit) {
              this.$submitBtn.removeAttr('disabled')
              this.$submitBtn.removeAttr('readonly')
              this.$submitBtn.find('.ladda-label').text('Submitting...')
            }

            const ladda = this.$submitBtn.data('ladda')
            if (ladda && !ladda.isLoading()) {
              ladda.startAfter(1)
            }
          }

          this._waitingToSubmit = false
          return
        }

        // If we're uploading, just wait. The form will be re-submitted whenever an upload is complete.
        this._waitingToSubmit = true
        e.stopImmediatePropagation() // block any other form submit handlers until we're done uploading.
        e.preventDefault()

        if (this.$submitBtn) {
          this.$submitBtn.find('.ladda-label').text('Waiting for uploads to finish...')

          const ladda = this.$submitBtn.data('ladda')
          if (ladda && !ladda.isLoading()) {
            ladda.startAfter(1)
          }
        }
      }
    }

    return this.init()
  }

  // A really lightweight plugin wrapper around the constructor,
  // preventing against multiple instantiations
  $.fn.dzoperator = function (options) {
    return this.each(function () {
      if (this.tagName != 'FORM' && window.console) {
        window.console.log("ERROR: dzoperator should only be initialized on FORM elements.")
        return
      }

      if ($.data(this, 'dzoperator') === undefined) {
        $.data(this, 'dzoperator', new DZOperator(this, options))
      }
    })
  }
};
