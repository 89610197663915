// Using JSON conforming to HAL API spec, initialize some defaults on any model that extends this concern
export default {
  url: function() {
    if (this._links && this._links.self) {
      return this._links.self.href
    } else {
      return Backbone.Model.prototype.url.call(this)
    }
  },

  // Strip out _links from incoming data from the server. `_links` is retained in the model Object, just not in the set of 
  // attributes. Expected to be called from a model's `parse()` function.
  parseHAL: function(data) {
    if (_.isEmpty(data._links))
    {return}

    this._links = data['_links']
    delete data['_links']
  },
}
