<!-- DO NOT REMOVE THIS CODE -->
<!-- This BaseStep is use to define the required fields by the BaseWizard -->

<script>
  export default {
    methods: {
      valid() { return true },
      invalidError() {},
      beforeAdvance() {},
    },
  }
</script>
