import Util from 'lib/util'
import WBRequestXHRDecorator from 'decorators/wb_request_xhr_decorator'

export default Marionette.Controller.extend({
  destroyDocument: function(id) {
    return $.ajax({
      method: 'DELETE',
      url: `/forms/${id}`,
      wbGenericFailureMsg: "Sorry, we couldn't remove this form.",
    }).done(function(_data, _status, xhr) {
      Util.navigateAndShowAjaxFlashNotice("/forms", xhr)
    }).fail( function(xhr) {
      const xhrDecorated = WBRequestXHRDecorator(xhr)
      if (xhrDecorated.status == 409) {
        App.Util.ajaxErrorDialog( xhrDecorated.getFlashMessage('error'), xhr)
      }
    })
  },
})
