import UserImportEmployee from 'models/user_import/user_import_employee'

export default Backbone.Collection.extend({
  model: UserImportEmployee,
  initialize: function(_model, options){
    this.id = options.id
  },
  url: function(){
    return `/user_imports/${this.id}/employees`
  },
})
