export default Marionette.Object.extend({

  // Initialize object
  //
  // options: fields: Collection of Fields
  //          sheetColumns: Collection of SheetColumns
  //          submitSheetView: ItemView providing submit button and status
  //          autoConfirm: Boolean, start the validation process immediately after initial fetches are finished (optional)
  initialize: function(options) {
    this.fields = options.fields                           // Collection of all possible fields to pick from
    this.sheetColumns = options.sheetColumns               // Collection of all columns in the uploaded sheet
    this.representableFields = options.representableFields  // Collection of all importable RepresentableFields
    this.fieldsTree = null

    this.submitSheetView = options.submitSheetView // Submit button, hide/show

    this.fieldsFetched              = false  // Has the Fields collection been fetched?
    this.sheetColumnsFetched        = false  // Has the SheetColumns collection been fetched?
    this.representableFieldsFetched = false  // Has the RepresentableFields collection been fetched?

    this.submitSheetView.notReadyToSubmit()        // Is everything is mapped, ready to go?

    this.autoConfirm = options.autoConfirm         // Automatically start validation after loading

    this.subscribeToPubSubEvents()

    // Fetch both collections
    this.fetchCollections()
  },

  // Fetch all collections. Store fetched state and notify when finished
  //
  // Returns nothing
  fetchCollections: function(){
    this.fields.fetch().done(function(){
      this.fieldsFetched = true
      this.fetchFinished()}.bind(this))

    this.sheetColumns.fetch().done(function(){
      this.sheetColumnsFetched = true
      this.fetchFinished()}.bind(this))

    this.representableFields.fetchFieldsTree(['importable']).done(function(_responseText, _textStatus, xhr){
      this.fieldsTree = xhr.responseJSON
      this.fieldsTree = this.sanDiegoFilterUnsupportedFields(this.fieldsTree)

      this.representableFieldsFetched = true
      this.fetchFinished()
    }.bind(this))
  },

  // Indicate that a collection has finished downloading. Triggers view loading if everything is done.
  //
  // Returns true if everything has been fetched
  fetchFinished: function(){
    // Trigger an event to indicate that all API data is loaded
    const everythingFetched = (
      this.fieldsFetched
        && this.sheetColumnsFetched
        && this.representableFieldsFetched)
    if (!everythingFetched){return false}

    if (this.autoConfirm){
      this.sheetColumns.confirmColumns()
    } else {
      App.vent.trigger('admin_user_import:column_match_data_loaded')
      this.resetMatching()
    }
    return true
  },

  // Save SheetColumns
  saveMatches: function(){
    this.sheetColumns.saveMatches(function(errors){
      this.handleValidationErrors(errors)
    }.bind(this))
  },

  // Respond to validation messages. Indicate that columns are ready to submit
  // if errors are not present
  handleValidationErrors: function(errors) {
    const hasErrors = (errors && errors.columns_map)
    if (hasErrors){
      this.submitSheetView.notReadyToSubmit()
    } else {
      this.submitSheetView.readyToSubmit()
    }
  },

  // Rewrite the match state for Fields after updates have occurred
  //
  // Returns nothing
  resetMatching: function(){
    _.each(this.fields.models, function(field){
      const fieldIsMatched = this.sheetColumnByFieldKey(field.get('field_key'))
      field.set('matched', fieldIsMatched)
    }.bind(this))
  },

  // Find the Field that a SheetColumn is matched with
  //
  // sheetColumn: SheetColumn
  //
  // Returns the Field if present, null if not
  sheetColumnMatchedTo: function(sheetColumn) {
    if (sheetColumn.isMatched()){
      return this.fieldByKey(sheetColumn.get('field_key'))
    }
    return null
  },

  // Find the Field by field key
  //
  // fieldKey: Field key string ('a4s_employee_email')
  //
  // Returns the Field, null if not found
  fieldByKey: function(fieldKey){
    const found = this.fields.find(function(item){
      return item.get('field_key') == fieldKey
    })
    return found ? found : null
  },

  // Find the SheetColumn by matched field key
  //
  // fieldKey: Field key string ('a4s_employee_email')
  //
  // Returns SheetColumn, null if not found
  sheetColumnByFieldKey: function(fieldKey){
    const found = this.sheetColumns.findWhere({field_key: fieldKey})
    return found ? found : null
  },

  // Mark a SheetColumn as ignored, save changes
  //
  // sheetColumn: SheetColumn to ignore
  //
  // Returns nothing
  ignoreColumnAndSave: function(sheetColumn){
    this.ignoreColumn(sheetColumn)
    this.saveMatches()
  },

  // Mark a SheetColumn as ignored
  //
  // sheetColumn: SheetColumn to ignore
  //
  // Returns nothing
  ignoreColumn: function(sheetColumn) {
    sheetColumn.set('field_key', null)
    sheetColumn.set('ignored', true)

    this.resetMatching()
  },

  // Match a SheetColumn to a Field, save changes
  //
  // sheetColumn: SheetColumn to match
  // field: Field to match
  //
  // Returns nothing
  matchColumnAndSave: function(sheetColumn, fieldKey) {
    this.matchColumn(sheetColumn, fieldKey)
    this.saveMatches()
  },

  // Match a SheetColumn to a Field
  //
  // sheetColumn: SheetColumn to match
  // field: Field to match
  //
  // Returns nothing
  matchColumn: function(sheetColumn, fieldKey) {
    // Null out the key if it's not legitimate, like the column is cleared out
    if (!this.fieldByKey(fieldKey)){fieldKey = null}

    this.sheetColumns.unsetFieldKey(fieldKey)
    sheetColumn.set('field_key', fieldKey)
    sheetColumn.set('ignored', false)

    this.resetMatching()
  },

  // Subscribe to PubSub events related to this import
  //
  // Returns nothing
  subscribeToPubSubEvents: function(){
    const importId = this.sheetColumns.id

    const channel = App.PubSub.subscribeToUserChannel({ topic: `admin_user_import.${importId}`, unique: false })
    channel.bind('employee_validation_complete', () => {
      App.vent.trigger('admin_user_import:employee_validation_complete')
    })

    channel.bind('employee_validation_failed', () => {
      App.vent.trigger('admin_user_import:employee_validation_failed')
    })
  },

  // Indicate that column confirmation has started
  //
  // Returns nothing
  columnConfirmStarted: function(){
    this.submitSheetView.notReadyToSubmit()
  },

  // SanDiego Restriction: Hide seasonality-related fields from unseasonal accounts
  sanDiegoFilterUnsupportedFields: function(fieldsTree) {
    fieldsTree.forEach( (branch) => {
      branch.items = _(branch.items).reject( (field) => {
        return field.field_key == 'a4s_employee_tenure'
      })
    })

    return fieldsTree
  },
})
