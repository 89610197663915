// General admin permissions unrelated to fields and document submissions
export default function(){
  this.BASELINE_DEFINITIONS = [
    { target_id: null,  target_type: 'Employee',  action: 'create',     grant: false },
    { target_id: null,  target_type: 'Document',  action: 'manage',     grant: false },
    { target_id: null,  target_type: 'Report',    action: 'manage',     grant: false },
  ],

  this.GROUP_DEFINITIONS = [
    { target_id: null,  target_type: 'EmployeeGroupMembership', action: 'manage',         grant: false },
    { target_id: null,  target_type: 'EmployeeSeason',          action: 'manage',         grant: false },
    { target_id: null,  target_type: 'EmployeeSeason',          action: 'change_status',  grant: false },
    { target_id: null,  target_type: 'Employee',                action: 'destroy',        grant: false },
    { target_id: null,  target_type: 'SupplementalFile',        action: 'view',           grant: false },
    { target_id: null,  target_type: 'SupplementalFile',        action: 'manage',         grant: false },
  ]
}
