<template>
  <div>
    <ul class="nav-wizard pl-0">
      <li v-for="(step, index) in steps" v-bind:key="index" :class="{active: index === currentStepIdx}">
        {{ step.label }}
      </li>
    </ul>
    <h2>{{ currentStep.title }}</h2>
    <p>{{ currentStep.subTitle }}</p>
    <component
      :is="currentStepComponentName"
      ref="currentStep"
      :bulkStaffUpdate="bulkStaffUpdate"
      @complete="handleStepCompleted"
    />
  </div>
</template>

<script>
  import BaseWizard from 'components/common/BaseWizard'
  import FileUploadStep from 'components/user_updates/steps/FileUploadStep'
  import MatchColumnsStep from 'components/user_updates/steps/MatchColumnsStep'

  export default {
    name: 'bulk-staff-update-wizard',
    components: {
      BaseWizard,
      FileUploadStep,
      MatchColumnsStep,
    },
    mixins: [BaseWizard],
    data() {
      return {
        steps: [
          {
            component: 'FileUploadStep',
            label: '1. Upload File',
            title: 'Upload Your Spreadsheet',
            subTitle: 'Make bulk updates to your staff in WorkBright using an Excel or CSV file.',
          },
          {
            component: 'MatchColumnsStep',
            label: '2. Match Columns',
            title: 'Match Columns',
            subTitle: 'Select the WorkBright ID column and map the other columns to their respective WorkBright fields.',
          },
        ],
        currentStepIdx: 0,
        bulkStaffUpdate: null,
      }
    },
    computed: {
      currentStep() {
        return this.steps[this.currentStepIdx]
      },
    },
    methods: {
      handleStepCompleted(newRecord, goToNextStep) {
        this.bulkStaffUpdate = newRecord
        if (goToNextStep) { this.nextStep() }
      },
    },
  }
</script>
