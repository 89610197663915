import EmployeesController from 'controllers/employees_controller'
import EmployeesRouter from 'routers/employees_router'

// eslint-disable-next-line no-unused-vars
export default function(Sector, App, Backbone, Marionette, $, _) {
  // Handling for New Employee event
  Sector.listenTo(App.globalVent, 'employees:new', function() {
    const controller = new EmployeesController()
    controller.newEmployee()
    App.vent.trigger('employees:new:post-init') // Any page-specific behavior for New Employees (e.g. changing url path)
  })

  Sector.addInitializer(function() {
    Sector.controller = new EmployeesController()
    Sector.router = new EmployeesRouter({ controller: Sector.controller })

    // On the Staff page, append to the location to create '/staff/new'.
    Sector.listenTo(App.vent, 'employees:new:post-init', function() {
      App.navigate("new")
    })

    Sector.listenTo(App.vent, 'employee:delete', Sector.controller.deleteEmployee)

    Sector.listenTo(App.vent, 'employee:employment:edit', function(model) {
      if (model.get('active_season')) {
        App.navigate("employment/edit")
      }
      Sector.controller.editEmployment(model)
    })

    // Handling for Rehire Wizard event: launch Rehire Wizard modal for a single employee
    Sector.listenTo(App.vent, 'employee:rehire', function(model) {
      Sector.controller.rehireWizard(model)
    })

    Sector.listenTo(App.vent, 'employee:supplemental_files:delete', Sector.controller.deleteSupplementalFile)
    Sector.listenTo(App.vent, 'employee:supplemental_files:download', Sector.controller.downloadSupplementalFile)
  })
};
