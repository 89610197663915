<template>
  <div>
    <BulkDownloadsContainer
      :collectionViewName="collectionViewName"
      :employeeIds="employeeIds"
      @hidden="cleanup"
    />
  </div>
</template>

<script>
  import { mapGetters, mapActions, mapMutations } from 'vuex'
  import * as mutate from 'vuex/mutation_types'
  import BulkDownloadsContainer from 'components/employees/bulk_downloads/BulkDownloadsContainer'
  import CollectionViewStore from 'vuex/modules/collection_view_store'

  export default {
    name: 'bulk-downloads-orphan-container',

    components: {
      BulkDownloadsContainer,
    },

    props: {
      employeeIds: {
        type: Array,
        required: true,
      },
    },

    data() {
      return {
        collectionViewName: 'collection_view_downloads',
      }
    },

    computed: {
      ...mapGetters({
        pageContext: 'pageContext',
      }),
    },

    created() {
      if (!this.$store._modulesNamespaceMap[`${this.collectionViewName}/`]) {
        this.$store.registerModule(this.collectionViewName, CollectionViewStore)
      }
    },

    beforeUnmount() {
      // Clear selected BEFORE we unregister the dynamically created module
      this.$store.dispatch(`${this.collectionViewName}/reset`)
      this.$store.unregisterModule(this.collectionViewName)
    },

    mounted() {
      this.showModal()
    },

    methods: {
      cleanup() {
        this.$emit('clear-modal-data')
        this.$destroy()
      },

      showModal () {
        const keys = {}
        keys[this.collectionViewName] = true
        this.setPageContextKeys(keys)
      },

      ...mapActions({
        employeesBulkShow: 'employees/bulkShow',

      }),

      ...mapMutations({
        setPageContextKeys: mutate.SET_PAGE_CONTEXT_KEYS,
      }),
    },
  }
</script>
