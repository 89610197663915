// eslint-disable-next-line no-unused-vars
export default function(Sector, App, Backbone, Marionette, $, _) {
  Sector.addInitializer(function() {
    Sector.kioskNavbarView = new App.Views.Kiosk.KioskNavbarView({ el: 'nav.navbar-main .navbar-right' })
    Sector.kioskNavbarView.render()

    Sector.listenTo(App.globalVent, 'kiosk:admin:command', function(command, options) {
      Sector.issueAdminCommand(command, options)
    })

    Sector.listenTo(App.globalVent, 'kiosk:admin:skip', function(options) {
      Sector.issueAdminCommand(function() {
        $.ajax(gon.skipPath, {
          type: "POST",
        }).done(function() {
          Turbolinks.visit(gon.newSubmissionPath)
        }).always(function() {
          // force NProgress to finish quickly (default behavior is slower than one would expect)
          NProgress.done()
        })
      }, options)
    })
  })

  Sector.addFinalizer(function() {
    if (Sector.kioskNavbarView) {
      Sector.kioskNavbarView.destroy()
    }

    Sector.stopListening(App.globalVent)
  })

  Sector.issueAdminCommand = function(command, options) {
    options = $.extend({ exitKioskMode: 0 }, options)

    new App.Views.Kiosk.AsyncKioskPasskeyView({
      modalOptions: { width: '500', modalOverflow: false, backdrop: true, keyboard: true },
      command: command,
      exit: options.exitKioskMode,
      _url: App.Util.getPageBodyData("pk-path"),
      ajaxOptions: {
        wbGenericFailureMsg: "Sorry, we couldn't process your request.",
      },
    })
  }
}
