import AsyncDocumentSubmissionView from 'views/document_submissions/async_document_submission_view'
import WBRequestXHRDecorator from 'decorators/wb_request_xhr_decorator'
import Util from 'lib/util'
import Routes from 'lib/routes'
import DocumentSubmission from 'models/document_submission'
import * as mutate from 'vuex/mutation_types'
import { mutationTypes as baseCollectMutationTypes } from 'vuex/mixins/base_vuex_collection_module'

export default Marionette.Controller.extend({
  initialize: function() {
    this.pageTitle = document.title
  },

  showInbox: function() {
    App.navigate("")
    App.DocumentSubmissions.mainPageLayout.ui.initialLoadingView.hide()
    App.DocumentSubmissions.mainPageLayout.submissionViewerRegion.empty()
    App.DocumentSubmissions.mainPageLayout.ui.inboxDataTableWrapper.show()
    App.DocumentSubmissions.mainPageLayout.ui.paginationFooter.show()
    document.title = this.pageTitle
  },

  // Find the models for the next/previous rows in the table, given a model
  // This allows features like "Accept & Next" to observe the table's sort and filter
  //
  // model - Document Submission model from the InboxCollectionView's collection
  //
  // returns - {
  //             before: {model or null},
  //             after: {model or null}
  // }
  findAdjacentRowModels: function(model) {
    const adjacentModels = {before: null, after: null}
    const collection = App.DocumentSubmissions.mainPageLayout.inboxCollectionView.collection
    const dataTableAPI = App.DocumentSubmissions.mainPageLayout.inboxCollectionView.getDataTableObject()
    const DATA_TABLE_ID_COL = 9 // Submission model ID is column idx 10 in the table, not visible

    // Find the index of the selected model in the table
    let rowIdx = -1
    _.each(dataTableAPI.rows({filter: 'applied'}).data(), function(data, idx){
      if (data[DATA_TABLE_ID_COL] == model.get("id")) {rowIdx = idx}
    })

    // Find the model from the previous row
    let prevRowIdx
    if ((prevRowIdx = rowIdx - 1) >= 0){
      const prevRowData = dataTableAPI.rows({filter: 'applied'}).data()[prevRowIdx]
      const prevModelId = parseInt(prevRowData[DATA_TABLE_ID_COL])
      adjacentModels.before = collection.findWhere({id: prevModelId})
    }

    // Find the model from the next row
    let nextRowIdx
    if ((nextRowIdx = rowIdx + 1) < dataTableAPI.rows({filter: 'applied'}).data().length){
      const nextRowData = dataTableAPI.rows({filter: 'applied'}).data()[nextRowIdx]
      const nextModelId = parseInt(nextRowData[DATA_TABLE_ID_COL])
      adjacentModels.after = collection.findWhere({id: nextModelId})
    }

    return adjacentModels
  },

  showAsync: function(model, runInboxActionOnFail) {
    const self = this
    const adjacentModels = this.findAdjacentRowModels(model)

    const submissionUrl = `/onboarding/inbox/${model.id}`

    // The request to view an individual inbox DocumentSubmission happens in two parts:
    // 1. Request the full data for the model. Update the existing model in the collection with this content.
    // 2. Request an HTML rendered template to display the data, with part of the data rendered into it.
    //
    // Don't ask me how this happened- I just work here.
    //
    // At the time of writing, this is fixing a bug where all fields on every inbox submission was being fetched,
    // causing feature-breaking page load times. The change loads just DocumentSubmission IDs, then fetches the
    // additional data when called. We have to do this in two parts (data, html view) because it would be weirder
    // to cram GON variables into the html view and execute it on retrieval, though 'weird' is on a sliding scale
    // at this point.
    //
    // FIXME: This feature is everywhere, so a bigger refactoring (like a more clear JSON-driven data/view relationship)
    // is necessary.

    $.ajax(submissionUrl, {
      type: "GET",
      wbGenericFailureMsg: "Sorry, we couldn't load the requested submission.",
    }).done(function(data) {
      // Update the existing model with the full content. If this submission was part of the original Inbox set,
      // any updates from the user will be reflected in the Inbox itself.
      const newModel = new DocumentSubmission(data.document_submission, { parse: true })
      model.set(newModel.attributes, {silent: true})

      // Load Vuex data for use with Historial Submission
      window.vuexStore.commit(`document_assignments/${baseCollectMutationTypes.SET_RECORD}`, data.document_assignment)
      window.vuexStore.commit(`documents/${baseCollectMutationTypes.SET_RECORD}`, data.document)
      window.vuexStore.commit(`document_submissions/${baseCollectMutationTypes.SET_RECORD}`, model.attributes)
      window.vuexStore.dispatch('permissions/loadInitialData', data.permissions)

      window.vuexStore.commit(mutate.SET_PAGE_CONTEXT, {
        employee: data.employee,
        visible_submission_id: data.visible_submission_id,
      })

      new AsyncDocumentSubmissionView({
        _url: `/onboarding/inbox/${model.id}`,
        _success: function() {
          App.DocumentSubmissions.mainPageLayout.ui.inboxDataTableWrapper.hide()
          App.DocumentSubmissions.mainPageLayout.ui.paginationFooter.hide()
          App.DocumentSubmissions.mainPageLayout.ui.initialLoadingView.hide()
          App.DocumentSubmissions.mainPageLayout.submissionViewerRegion.show(this)

          Util.Animations.scrollTo(App.DocumentSubmissions.mainPageLayout.submissionViewerRegion.$el)
          document.title = `${model.get('document_name')} – ${model.get('employee_name')} – ${App.Name}`
        },
        _error: function() {
          if (runInboxActionOnFail)
          {self.showInbox()}
          else
          {App.navigate("")}
        },
        ajaxOptions: {
          wbGenericFailureMsg: "Sorry, we couldn't load the requested submission.",
        },
        model: model,
        modelBefore: adjacentModels.before,
        modelAfter: adjacentModels.after,
        assignment: data.document_assignment,
        historical_submissions_count: data.historical_submissions_count,
      })

    })
  },

  // Loads the page just from an ID (if navigated directly)
  showAsyncWithId: function(id) {
    // First, try to get it from the collection view so that it is wired up to that if the user makes changes.
    let model = App.DocumentSubmissions.mainPageLayout.inboxCollectionView.collection.get(id)

    // Otherwise, use whatever Rails passed us.
    if (!model && (gon.document_submission && gon.document_submission.id)) {
      model = new DocumentSubmission(gon.document_submission, { parse: true })
    }

    if (model) {
      App.DocumentSubmissions.mainPageLayout.ui.inboxDataTableWrapper.hide()
      App.DocumentSubmissions.mainPageLayout.ui.paginationFooter.hide()
      App.DocumentSubmissions.mainPageLayout.ui.initialLoadingView.show()
      this.showAsync(model, true)
    } else {
      this.showInbox()
    }
  },

  showEditInternalFieldsModalView: function(model) {
    new App.Views.DocumentSubmissions.EditInternalFieldsAsyncModalView({
      _url: Routes.edit_fields_admin_submission_path({ id: model.id }),
      model: model,
      ajaxOptions: {
        wbGenericFailureMsg: "Sorry, we couldn't load the Edit form.",
      },
      _error: function(xhr) {
        const xhrDecorated = WBRequestXHRDecorator(xhr)
        if (xhrDecorated.status == 409) {
          Util.ajaxErrorDialog(xhrDecorated.getFlashMessage('error'), xhr)
        }
      },
    })
  },
})
