import WizardStep from 'lib/wizard_step'
import StepDocumentationView from 'views/document_submissions/i9/supplement_b/step_documentation_view'

// Options:
//  documentationChoices - (I9DocumentationChoices) Required. the static set of all possible documentation choices
//
export default WizardStep.extend({
  view: StepDocumentationView,
  subStepIdx: 0,

  // Total number of steps
  //
  // returns integer
  stepCount: function() {
    // Add 1 for the document selection step
    return this._view.model.documentationSet.length + 1
  },

  // Action of the step
  //
  // Returns object: {display: 'something_to_display', ...}
  stepAction: function(){
    if (this.subStepIdx == 0) {
      return {display: 'select_documents'}
    } else {
      return {display: 'upload_document', index: (this.subStepIdx - 1)}
    }
  },

  // Is this step finished?
  //
  // boolean - true if the step is complete, false if not
  isFinished: function() {
    // The documentation set hasn't been selected initially, so the step count starts at 1.
    // In this case, we're not finished, so return false.
    return this.stepCount() > 1 && this.subStepIdx >= (this.stepCount() - 1)
  },

  // Is this step at the start?
  //
  // boolean - true if the step is at the start, false if not
  isStarting: function(){
    return this.subStepIdx <= 0
  },

  // Advance to the next substep
  advance: function() {
    if (!this.isFinished()) {
      this.subStepIdx++
    }
    this.updateView()
  },

  // Retreat to the previous substep
  retreat: function() {
    if (!this.isStarting()) {
      this.subStepIdx--
    }
    this.updateView()
  },

  // Update the view with the current step action
  updateView: function(){
    const action = this.stepAction()
    this._context.set('action', action)
    this._view.showSubStep()
  },

  // Percentage of this step completed
  // Current substep divided by total number of substeps
  //
  // returns - integer 0 to 100
  completePercentage: function(){
    return parseInt((this.subStepIdx / this.stepCount()) * 100)
  },
})
