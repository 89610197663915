export default Marionette.Controller.extend({
  initialize: function() {},

  deleteReport: function(report) {
    report.destroy({
      wait: true,
      wbGenericFailureMsg: "Sorry, we couldn't delete this report.",
    }).done(function(_data, _status, xhr) {
      App.Util.showAjaxFlashNotice(xhr)
    })
  },
})
