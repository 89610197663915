import * as BaseCollectionModule from 'vuex/mixins/base_vuex_collection_module'
import Routes from 'lib/routes'
import Locale from 'lib/locale'
import PubSubUtil from 'lib/pub_sub_wrapped_action'

function state() {
  return {
    collection: {},
  }
}

const getters = {
  // Sort assignments in alphabetical order by Document name
  // Returns - Array
  sortedAlpha: (state) => (documents) => {
    return _(state.collection).sortBy( (a) => { return documents[a.document_id].name })
  },

  // Sort assignments in presentation display order by DocumentOrdering
  // Returns - Array
  sortedPresentation: (state) => (documentOrderings, documents) => {
    const assignments = Object.values(state.collection)

    // Documents with an explicit ordering: sort by their ordering
    const orderedAssignments = _(assignments.filter((a) => documentOrderings[a.document_id]))
      .sortBy((a) => documentOrderings[a.document_id].position)

    // Documents without an explicit ordering: sort by their name
    const unorderedAssignments = _(assignments.filter((a) => !documentOrderings[a.document_id]))
      .sortBy((a) => documents[a.document_id].name)

    return orderedAssignments.concat(unorderedAssignments)
  },

  ...BaseCollectionModule.getters,
}

export const mutationTypes = {
  ...BaseCollectionModule.mutationTypes,
}

export const mutations = {
  ...BaseCollectionModule.mutations,
}

const actions = {
  // Fetches historical submissions for a given DocumentAssignment
  //
  // assignment [Object] - the actual assignment object with keys id, employee_id, and document_id
  fetchHistoricalSubmissions: ({ dispatch, commit }, assignment) => {
    return new Promise((resolve, reject) => {
      dispatch('document_submissions/fetchHistoricalSubmissions', {
        employeeId: assignment.employee_id,
        documentAssignmentId: assignment.id,
      }, { root: true })
        .catch(xhr => reject(xhr))
        .then(historicalSubmissionIds => {
          commit(mutationTypes.MERGE_INTO_RECORD, {
            id: assignment.id,
            historical_submission_ids: historicalSubmissionIds,
          })
          resolve()
        })
    })
  },

  // Resets DocumentAssignments
  // Posts to API, and receives response back on PubSub channel, as this is a long-running bulk operation
  //
  // data
  //   assignments [Array] - assignments to reset
  //   emailMessage [String] - optional, email message
  reset: ({ dispatch }, { assignments, emailMessage }) => {
    return PubSubUtil.pubSubWrappedAction('document_assignments_reset',
      (channelName, reject) => {
        // Don't kick off request until PubSub has subscribed to the channel, or we may not receive the response.
        $.ajax({
          method: 'PATCH',
          url: Routes.Api.bulk_reset_document_assignments_path,
          data: {
            document_assignment_ids: assignments.map(a => a.id),
            email_message: emailMessage,
            pubsub_channel: channelName,
          },
          wbGenericFailureMsg: 'Sorry, we could not reset the submissions.',
          progressBar: false,
        }).fail(xhr => reject(xhr))
      },
      (_response, resolve, reject) => {
        dispatch('document_assignments/bulkFind', {
          employeeIds: assignments.map((a) => a.employee_id),
          documentIds: assignments.map((a) => a.document_id),
        }, { root: true })
          .then(() => resolve())
          .catch(xhr => reject(xhr))
      },
    )
  },

  setAsCurrent: ({ commit }, {
    document_assignment,
    new_current_submission,
    auto_accept_historical_rejection = false,
  }) => {
    return new Promise((resolve, reject) => {
      $.ajax({
        method: 'PATCH',
        url: Routes.Api.current_submission_employee_document_assignment_path({
          id: document_assignment.id,
          employee_id: document_assignment.employee_id,
        }),
        data: {
          auto_accept_historical_rejection,
          new_current_submission_id: new_current_submission.id,
        },
        wbGenericFailureMsg: Locale.t('assignments.setAsCurrent.fail'),
        progressBar: false,
      }).then(assignment => {
        commit(mutationTypes.SET_RECORD, assignment)
        resolve(assignment)
      }).fail(xhr => reject(xhr))
    })
  },

  bulkFind: ({ dispatch }, { employeeIds, documentIds }) => {
    return new Promise((resolve, reject) => {
      $.ajax({
        method: 'POST',
        url: Routes.Api.bulk_find_document_assignments_path,
        data: {
          document_ids: documentIds,
          employee_ids: employeeIds,
        },
      }).then(assignments => {
        dispatch('document_assignments/loadInitialData', assignments, { root: true })
        resolve(assignments)
      }).fail(xhr => reject(xhr))
    })
  },

  ...BaseCollectionModule.actions,
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
