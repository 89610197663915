<template>
  <div class="well">
    <h4>Multiple Jobs Worksheet</h4>
    <p>This will calculate the value to enter in Step 4(c), Extra withholding.</p>

    <FormFieldCurrency
      orientation="horizontal"
      :required="false"
      label="Job 1 annual income"
      :errors="errors"
      name="mjw_job_1"
      min="0"
      v-model="formData.job1"
    />

    <FormFieldCurrency
      orientation="horizontal"
      :required="false"
      label="Job 2 annual income"
      :errors="errors"
      name="mjw_job_2"
      min="0"
      v-model="formData.job2"
    />

    <FormFieldCurrency
      v-if="jobs === 3"
      orientation="horizontal"
      :required="false"
      label="Job 3 annual income"
      :errors="errors"
      name="mjw_job_3"
      min="0"
      v-model="formData.job3"
    />

    <FormFieldRadio
      label="For the highest paying job, how often do you get paid?"
      :required="false"
      :options="payFrequencyOptions"
      v-model="formData.payFrequency"
      :errors="errors"
      name="pay_frequency"
      orientation="horizontal"
    />

    <FormFieldNumber v-if="isCustomPay"
      orientation="horizontal"
      :required="false"
      label="How many times a year are you paid?"
      :errors="errors"
      name="custom_pay_frequency"
      min="0"
      v-model="formData.customPayFrequency"
      ref="customPayFrequency"
    />
  </div>
</template>

<script>
  import FormFieldCurrency from 'components/common/FormFieldCurrency'
  import FormFieldNumber from 'components/common/FormFieldNumber'
  import FormFieldRadio from 'components/common/FormFieldRadio'

  export const PAY_FREQUENCIES = {
    weekly: 52,
    biweekly: 26,
    monthly: 12,
  }
  export default {
    name: 'multiple-jobs-worksheet',

    components: {
      FormFieldCurrency,
      FormFieldNumber,
      FormFieldRadio,
    },

    props: {
      jobs: {
        required: true,
        type: Number,
      },

      status: {
        required: true,
        type: String,
      },

      errors: {
        required: false,
        type: Object,
      },
    },

    data() {
      return {
        formData: {
          job1: '',
          job2: '',
          job3: '',
          mjwLine1: '',
          mjwLine2a: '',
          mjwLine2b: '',
          payFrequency: null,
          customPayFrequency: '',
        },
        payFrequencyOptions: [
          { label: "Weekly", value: 'weekly' },
          { label: "Biweekly", value: 'biweekly' },
          { label: "Monthly", value: 'monthly' },
          { label: "Other", value: 'custom' },
        ],
      }
    },

    computed: {
      isCustomPay () {
        return this.formData.payFrequency === 'custom'
      },
    },

    watch: {
      formData: {
        deep: true,
        handler () {
          this.sendUpdate()
        },
      },
    },

    methods: {
      // update the Extra Withholding field on the W4
      // and the MJW form values
      sendUpdate() {
        const extraWithholding = this.calculateExtraWithholding()
        const mjw1 = this.formData.mjwLine1
        const mjw2a = this.formData.mjwLine2a
        const mjw2b = this.formData.mjwLine2b
        const pay = this.getPayFrequency(
          this.formData.payFrequency,
          this.formData.customPayFrequency,
        )
        const job1 = this.formData.job1
        const job2 = this.formData.job2
        const job3 = this.formData.job3
        this.$emit('updateFromMjw', extraWithholding, mjw1, mjw2a, mjw2b, pay, job1, job2, job3)
      },

      getPayFrequency(payFrequency, customPayFrequency) {
        if (payFrequency === 'custom') {
          return parseInt(customPayFrequency)
        }
        return PAY_FREQUENCIES[payFrequency] || 0
      },

      // calculate the value of the 'Extra Withholding' field on the W4
      calculateExtraWithholding() {
        const numberOfJobs = parseInt(this.jobs)

        const jobs = [
          parseFloat(this.formData.job1) || 0,
          parseFloat(this.formData.job2) || 0,
          parseFloat(this.formData.job3) || 0,
        ].sort((a, b) => a - b) // from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort

        return numberOfJobs < 3
          ? this.calculateExtraWithholdingTwoJobs(jobs)
          : this.calculateExtraWithholdingThreeJobs(jobs)
      },

      calculateExtraWithholdingTwoJobs(jobs){
        const nextHighestPayingJob = jobs[1]
        const highestPayingJob = jobs[2]

        let lookupValue
        switch (this.status) {
          case 'single_or_married_filing_separately':
            lookupValue = this.getValueFromStatusOneTable(highestPayingJob, nextHighestPayingJob)
            this.formData.mjwLine1 = lookupValue
            return this.processLookupValue(lookupValue)

          case 'married_filing_jointly':
            lookupValue = this.getValueFromStatusTwoTable(highestPayingJob, nextHighestPayingJob)
            this.formData.mjwLine1 = lookupValue
            return this.processLookupValue(lookupValue)

          case 'head_of_household':
            lookupValue = this.getValueFromStatusThreeTable(highestPayingJob, nextHighestPayingJob)
            this.formData.mjwLine1 = lookupValue
            return this.processLookupValue(lookupValue)

          default:
            return 0
        }
      },

      calculateExtraWithholdingThreeJobs(jobs){
        const lowestPayingJob = jobs[0]
        const nextHighestPayingJob = jobs[1]
        const highestPayingJob = jobs[2]

        let lookupValue
        let lookupValueTwo
        switch (this.status) {
          case 'single_or_married_filing_separately':
            lookupValue = this.getValueFromStatusOneTable(highestPayingJob, nextHighestPayingJob)
            this.formData.mjwLine2a = lookupValue
            lookupValueTwo = this.getValueFromStatusOneTable((highestPayingJob + nextHighestPayingJob), lowestPayingJob)
            this.formData.mjwLine2b = lookupValueTwo
            return this.processLookupValue((lookupValue + lookupValueTwo))

          case 'married_filing_jointly':
            lookupValue = this.getValueFromStatusTwoTable(highestPayingJob, nextHighestPayingJob)
            this.formData.mjwLine2a = lookupValue
            lookupValueTwo = this.getValueFromStatusTwoTable((highestPayingJob + nextHighestPayingJob), lowestPayingJob)
            this.formData.mjwLine2b = lookupValueTwo
            return this.processLookupValue((lookupValue + lookupValueTwo))

          case 'head_of_household':
            lookupValue = this.getValueFromStatusThreeTable(highestPayingJob, nextHighestPayingJob)
            this.formData.mjwLine2a = lookupValue
            lookupValueTwo = this.getValueFromStatusThreeTable((highestPayingJob + nextHighestPayingJob), lowestPayingJob)
            this.formData.mjwLine2b = lookupValueTwo
            return this.processLookupValue((lookupValue + lookupValueTwo))

          default:
            return 0

        }
      },

      // uses values from lookup tables to determine withholding
      // this will get called before we have all our values in place
      // and will return 0 when we don't have enough data to make a calculation
      processLookupValue(lookupValue) {
        const payFrequency = this.getPayFrequency(
          this.formData.payFrequency,
          this.formData.customPayFrequency,
        )
        const periodValue = lookupValue / payFrequency

        let processedLookupValue = 0
        if (periodValue < Infinity) {
          processedLookupValue = periodValue
        }

        return processedLookupValue.toFixed(2)
      },

      // we have three different lookup charts - one for each filing status
      // the `rows` are the left hand side of the chart and are used to look up the value for the highest paying job
      // the `columns` are top of the chart, and are used to look up the value for the lower paying job
      // `table` is an array of arrays representing the data in the chart
      // the positions of the `row` and the `column` are used to access the data in `table`
      getValueFromStatusOneTable(highest, lowest) {
        // single or married filing separately
        const rows = {
          1: { low: 0,      high: 9999     },
          2: { low: 10000,  high: 19999    },
          3: { low: 20000,  high: 29999    },
          4: { low: 30000,  high: 39999    },
          5: { low: 40000,  high: 59999    },
          6: { low: 60000,  high: 79999    },
          7: { low: 80000,  high: 99999    },
          8: { low: 100000, high: 124999   },
          9: { low: 125000, high: 149999   },
          10: { low: 150000, high: 174999   },
          11: { low: 175000, high: 199999   },
          12: { low: 200000, high: 249999   },
          13: { low: 250000, high: 399999   },
          14: { low: 400000, high: 449999   },
          15: { low: 450000, high: Infinity },
        }

        const columns = this.lookupTableColumns()

        const table = [
          // comment row below is the salary lower bounds, for easier column referencing
          //0   10000 20000  30000  40000  50000  60000  70000  80000  90000  100000 110000
          [200,  850,  1020, 1020,  1020,  1370,  1870,  1870,  1870,  1870,  1870,  2040],  // 1
          [850,  1700, 1870, 1870,  2220,  3220,  3720,  3720,  3720,  3720,  3890,  4090],  // 2
          [1020, 1870, 2040, 2390,  3390,  4390,  4890,  4890,  4890,  5060,  5260,  5460],  // 3
          [1020, 1870, 2390, 3390,  4390,  5390,  5890,  5890,  6060,  6260,  6460,  6660],  // 4
          [1220, 3070, 4240, 5240,  6240,  7240,  7880,  8080,  8280,  8480,  8680,  8880],  // 5
          [1870, 3720, 4890, 5890,  7030,  8230,  8930,  9130,  9330,  9530,  9730,  9930],  // 6
          [1870, 3720, 5030, 6230,  7430,  8630,  9330,  9530,  9730,  9930,  10130, 10580], // 7
          [2040, 4090, 5460, 6660,  7860,  9060,  9760,  9960,  10160, 10950, 11950, 12950], // 8
          [2040, 4090, 5460, 6660,  7860,  9060,  9950,  10950, 11950, 12950, 13950, 14950], // 9
          [2040, 4090, 5460, 6660,  8450,  10450, 11950, 12950, 13950, 15080, 16380, 17680], // 10
          [2040, 4290, 6450, 8450,  10450, 12450, 13950, 15230, 16530, 17830, 19130, 20430], // 11
          [2720, 5570, 7900, 10200, 12500, 14800, 16600, 17900, 19200, 20500, 21800, 23100], // 12
          [2970, 6120, 8590, 10890, 13190, 15490, 17290, 18590, 19890, 21190, 22490, 23790], // 13
          [2970, 6120, 8590, 10890, 13190, 15490, 17290, 18590, 19890, 21190, 22490, 23790], // 14 (this is the same as the previous row)
          [3140, 6490, 9160, 11660, 14160, 16660, 18660, 20160, 21660, 23160, 24660, 26160], // 15
        ]

        const lookupRow = this.getLookupRow(rows, highest)
        const lookupColumn = this.getLookupColumn(columns, lowest)

        return table[lookupRow][lookupColumn]
      },

      getValueFromStatusTwoTable(highest, lowest) {
        // married filing jointly or qualifying surviving spouse
        const rows = {
          1: { low: 0,      high: 9999     },
          2: { low: 10000,  high: 19999    },
          3: { low: 20000,  high: 29999    },
          4: { low: 30000,  high: 39999    },
          5: { low: 40000,  high: 49999    },
          6: { low: 50000,  high: 59999    },
          7: { low: 60000,  high: 69999    },
          8: { low: 70000,  high: 79999    },
          9: { low: 80000,  high: 99999    },
          10: { low: 100000, high: 149999   },
          11: { low: 150000, high: 239999   },
          12: { low: 240000, high: 259999   },
          13: { low: 260000, high: 279999   },
          14: { low: 280000, high: 299999   },
          15: { low: 300000, high: 319999   },
          16: { low: 320000, high: 364999   },
          17: { low: 365000, high: 524999   },
          18: { low: 525000, high: Infinity },
        }

        const columns = this.lookupTableColumns()

        const table = [
          // comment row below is the salary lower bounds, for easier column referencing
          //0   10000 20000  30000  40000  50000  60000  70000  80000  90000  100000 110000
          [0,    0,    700,   850,   910,   1020,  1020,  1020,  1020,  1020,  1020,  1020],  // 1
          [0,    700,  1700,  1910,  2110,  2220,  2220,  2220,  2220,  2220,  2220,  3220],  // 2
          [700,  1700, 2760,  3110,  3310,  3420,  3420,  3420,  3420,  3420,  4420,  5420],  // 3
          [850,  1910, 3110,  3460,  3660,  3770,  3770,  3770,  3770,  4770,  5770,  6770],  // 4
          [910,  2110, 3310,  3660,  3860,  3970,  3970,  3970,  4970,  5970,  6970,  7970],  // 5
          [1020, 2220, 3420,  3770,  3970,  4080,  4080,  5080,  6080,  7080,  8080,  9080],  // 6
          [1020, 2220, 3420,  3770,  3970,  4080,  5080,  6080,  7080,  8080,  9080,  10080], // 7
          [1020, 2220, 3420,  3770,  3970,  5080,  6080,  7080,  8080,  9080,  10080, 11080], // 8
          [1020, 2220, 3420,  4620,  5820,  6930,  7930,  8930,  9930,  10930, 11930, 12930], // 9
          [1870, 4070, 6270,  7620,  8820,  9930,  10930, 11930, 12930, 14010, 15210, 16410], // 10
          [1870, 4240, 6640,  8190,  9590,  10890, 12090, 13290, 14490, 15690, 16890, 18090], // 11
          [2040, 4440, 6840,  8390,  9790,  11100, 12300, 13500, 14700, 15900, 17100, 18300], // 12
          [2040, 4440, 6840,  8390,  9790,  11100, 12300, 13500, 14700, 15900, 17100, 18300], // 13 (this is the same as the previous row)
          [2040, 4440, 6840,  8390,  9790,  11100, 12300, 13500, 14700, 15900, 17100, 18300], // 14 (this is the same as the previous row)
          [2040, 4440, 6840,  8390,  9790,  11100, 12300, 13500, 14700, 15900, 17170, 19170], // 15 (this is almost same as the previous row)
          [2040, 4440, 6840,  8390,  9790,  11100, 12470, 14470, 16470, 18470, 20470, 22470], // 16
          [2790, 6290, 9790,  12440, 14940, 17350, 19650, 21950, 24250, 26550, 28850, 31150], // 17
          [3140, 6840, 10540, 13390, 16090, 18700, 21200, 23700, 26200, 28700, 31200, 33700], // 18
        ]

        const lookupRow = this.getLookupRow(rows, highest)
        const lookupColumn = this.getLookupColumn(columns, lowest)

        return table[lookupRow][lookupColumn]
      },

      getValueFromStatusThreeTable(highest, lowest) {
        // head of household
        const rows = {
          1: { low: 0,      high: 9999     },
          2: { low: 10000,  high: 19999    },
          3: { low: 20000,  high: 29999    },
          4: { low: 30000,  high: 39999    },
          5: { low: 40000,  high: 59999    },
          6: { low: 60000,  high: 79999    },
          7: { low: 80000,  high: 99999    },
          8: { low: 100000, high: 124999   },
          9: { low: 125000, high: 149999   },
          10: { low: 150000, high: 174999   },
          11: { low: 175000, high: 199999   },
          12: { low: 200000, high: 249999   },
          13: { low: 250000, high: 449999   },
          14: { low: 450000, high: Infinity },
        }

        const columns = this.lookupTableColumns()

        const table = [
          // comment row below is the salary lower bounds, for easier column referencing
          //0   10000 20000  30000  40000  50000  60000  70000  80000  90000  100000 110000
          [0,    450,  850,  1000,  1020,  1020,  1020,  1020,  1870,  1870,  1870,  1890],  // 1
          [450,  1450, 2000, 2200,  2220,  2220,  2220,  3180,  4070,  4070,  4090,  4290],  // 2
          [850,  2000, 2600, 2800,  2820,  2820,  3780,  4780,  5670,  5690,  5890,  6090],  // 3
          [1000, 2200, 2800, 3000,  3020,  3980,  4980,  5980,  6890,  7090,  7290,  7490],  // 4
          [1020, 2220, 2820, 3830,  4850,  5850,  6850,  8050,  9130,  9330,  9530,  9730],  // 5
          [1020, 3030, 4630, 5830,  6850,  8050,  9250,  10450, 11530, 11730, 11930, 12130], // 6
          [1870, 4070, 5670, 7060,  8280,  9480,  10680, 11880, 12970, 13170, 13370, 13570], // 7
          [1950, 4350, 6150, 7550,  8770,  9970,  11170, 12370, 13450, 13650, 14650, 15650], // 8
          [2040, 4440, 6240, 7640,  8860,  10060, 11260, 12860, 14740, 15740, 16740, 17740], // 9
          [2040, 4440, 6240, 7640,  8860,  10860, 12860, 14860, 16740, 17740, 18940, 20240], // 10
          [2040, 4440, 6640, 8840,  10860, 12860, 14860, 16910, 19090, 20390, 21690, 22990], // 11
          [2720, 5920, 8520, 10960, 13280, 15580, 17880, 20180, 22360, 23660, 24960, 26260], // 12
          [2970, 6470, 9370, 11870, 14190, 16490, 18790, 21090, 23280, 24580, 25880, 27180], // 13
          [3140, 6840, 9940, 12640, 15160, 17660, 20160, 22660, 25050, 26550, 28050, 29550], // 14
        ]

        const lookupRow = this.getLookupRow(rows, highest)
        const lookupColumn = this.getLookupColumn(columns, lowest)

        return table[lookupRow][lookupColumn]
      },

      // used to find the position on the side of the chart
      getLookupRow(rows, highest) {
        const rowKeys = Object.keys(rows)
        for (const key of rowKeys) {
          if (highest <= rows[key]['high'] && highest >= rows[key]['low'] ) {
            return key - 1
            break
          }
        }
      },

      // used to find the position at the top of the chart
      getLookupColumn(columns, lowest) {
        const columnKeys = Object.keys(columns)
        for (const key of columnKeys) {
          if (lowest <= columns[key]['high'] && lowest >= columns[key]['low']) {
            return key - 1
            break
          }
        }
      },

      // the 'columns' for all three of the charts are identical
      lookupTableColumns() {
        return {
          1: { low: 0,      high: 9999   },
          2: { low: 10000,  high: 19999  },
          3: { low: 20000,  high: 29999  },
          4: { low: 30000,  high: 39999  },
          5: { low: 40000,  high: 49999  },
          6: { low: 50000,  high: 59999  },
          7: { low: 60000,  high: 69999  },
          8: { low: 70000,  high: 79999  },
          9: { low: 80000,  high: 89999  },
          10: { low: 90000,  high: 99999  },
          11: { low: 100000, high: 109999 },
          12: { low: 110000, high: 120000 },
        }
      },
    },
  }
</script>
