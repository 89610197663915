import FieldValueEntry from 'models/field_value_entry'
import FieldValueEntriesCollection from 'collections/field_value_entries_collection'

export default {
  // Deep initialize FieldValueEntries in `attribute`. To skip deep-initialization on an existing model, remove key
  // `attribute` from the incoming `data`.
  parseUDFValuesStore: function(data, attribute) {
    // Don't touch the attribute if it's not part of the data.
    if (data[attribute] === undefined)
    {return}

    const fieldValueEntries = $.map(data[attribute] || [], function(fve) {
      return new FieldValueEntry(fve, { parse: true })
    })
    data[attribute] = new FieldValueEntriesCollection(fieldValueEntries)
  },

  // Updates existing FieldValueEntries with latest data from server. Doesn't alter the rest of the model, only the
  // `attribute` supplied.
  setUDFValues: function(data, attribute) {
    $.map(data[attribute] || [], function(fve) {
      this.get(attribute).get(fve.id).set({
        value: fve.value,
        value_decorated: fve.value_decorated,
      })
    }.bind(this))
  },
}
