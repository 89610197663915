// Rule disabled becasue still flagging .upgrades .min .transit
/* eslint-disable import/extensions */

// Require Manually-Vendored Libraries
// This should be moved to NPM where possible
window.Sortable = require('vendor/sortable.min')
window.NProgress = require('vendor/nprogress')        // THIS FILE WAS MODIFIED!
require('vendor/jquery.transit')                      // THIS FILE WAS MODIFIED!
require('vendor/jquery.color-2.1.2.min')
require('vendor/jquery.bootstrap.wizard.min')
require('vendor/canvasToBlob.min')

// Extensions
require('vendor/vendor_extensions/bootstrap_validators/bootstrapValidator.signaturePad')
require('vendor/vendor_extensions/bootbox.upgrades')
require('vendor/vendor_extensions/bootstrap.upgrades')
require('vendor/vendor_extensions/jquery.upgrades')
