import Util from 'lib/util'
import Images from 'lib/util/images'
import Dialogs from 'lib/util/dialogs'
import Routes from 'lib/routes'
import WBRequestXHRDecorator from 'decorators/wb_request_xhr_decorator'

export default Marionette.ItemView.extend({
  template: '#image-editor-template',
  ui: {
    canvas: '.img-canvas',
    editorContainer: '.image-editor-container',
    editButton: '.js-edit-button',
    cropButton: '.js-crop-button',
    saveButton: '.js-save-button',
    rotateRightButton: '.js-rotate-right-button',
    rotateLeftButton: '.js-rotate-left-button',
    resetButton: '.js-reset-button',
    editorButtonGroup: '.image-editor-btn-group',
    finalActionButtonGroup: '.final-action-btn-group',
    showOriginalButton: '.js-show-original-button',
    revertButton: '.js-revert-button',
    adminOptions: '.admin-options',
    editedImageWrapper: '.editor-img',
  },

  events: {
    'click @ui.editButton': 'editClick',
    'click @ui.cropButton': 'cropClick',
    'click @ui.rotateRightButton': 'clickRotateImageRight',
    'click @ui.rotateLeftButton': 'clickRotateImageLeft',
    'click @ui.resetButton': 'clickReset',
    'click @ui.saveButton': 'saveClick',
    'click @ui.revertButton': 'revertClick',
  },

  initialize: function () {
    this.adminMode = this.options.adminMode
    this.editingMode = true

    if (this.adminMode) {
      this.imgURL = this.options.imgSrc
      this.imgFileName = this._getFileName()
      this.initialImgFilename = this.imgFileName
      this.submission = this.options.submission
      this.submissionEditable = this.submission.get('images_editable') || false
    } else {
      this.dzOperator = this.options.dz
      this.imgURL = this.dzOperator.$awsURLField.val()
    }
  },

  onDestroy: function () {
    // Cleanup
    URL.revokeObjectURL(this.objectURL)
    // Remove resize listener
    $(window).off(`resize.${this.resizeListenerName}`)
  },

  onRender: function () {
    this._hideLoadingMsg()
    this.ui.editedImageWrapper.prepend('<div class="loading-indicator-bar"></div>')

    // In adminMode, we don't auto-initialize image editor, so we skip loading the image into the editor
    if (this.adminMode) {
      if (!this.submissionEditable) { return };

      this._getLastModifiedBy()

      // Set the button href to the original file URL
      this.ui.showOriginalButton.attr('href', Routes.submission_attachment_original_file_path({ id: this.submission.id, attachment_id: this.imgFileName })).attr('target', '_new')

      // Move original image into editor container and save a ref
      const imageElement = this.$el.parent().find('.attachment-link img')
      this.ui.editedImageWrapper.append(imageElement)
      this.initialImgEl = imageElement
      this.ui.adminOptions.show()
      this.$el.parent().find('.attachment-link').hide()
    } else {
      this.loadImg()
    }

    // Initializes toolbar tooltips
    $.runInitializers(this.$el)

    // Save ref to unique internal cid of this ItemView so we can bind to resize for each if multiple on same page
    this.resizeListenerName = `${this.cid}_resize`

    // Custom responsiveness
    $(window).on(`resize.${this.resizeListenerName}`, this.resizeEditor.bind(this))
  },

  templateHelpers: function() {
    return {
      editable: this.submissionEditable,
    }
  },

  // Turns on/off editing mode based on boolean arg
  editing: function (bool) {
    if (bool == true) {
      this.initCropperEl()

      if (this.adminMode) {
        this.ui.editorContainer.addClass('img-editing admin-mode')
        this.ui.adminOptions.hide()
      }

    } else {
      if (!this.cropperEl) { return };
      this.ui.editorContainer.removeClass('img-editing')
      this.cropperEl.destroy()
      this.cropperEl = null
    }
  },

  revertClick: function (e) {
    e.stopPropagation()
    e.preventDefault()

    const options = {
      confirmTitle: 'Are you sure?',
      confirmMessage: 'This will restore the image to the original version submitted by the employee. Are you sure you want to do this?',
      confirmBtnLabel: 'Revert to Original',
    }
    Dialogs.confirmation(options, this.revertToOriginal.bind(this))
  },

  revertToOriginal: function (options) {
    this.$el.find('.admin-options').hide()
    this._displayLoadingMsg('Reverting Image...')

    options = _.extend({}, options)

    $.ajax({
      url: Routes.submission_attachment_revert_to_original_path({ id: this.submission.id, attachment_id: this.imgFileName }),
      type: "PATCH",
      data: options,
      dataType: 'json',
      wbGenericFailureMsg: "Sorry, we couldn't revert this image to its original.",
    }).done(data => {
      Util.showFlashNotice('Attachment updated.')

      // Set edited image url and rerender
      this.imgURL = data.new_file_url
      this.imgFileName = this._getFileName()
      this.editingMode = false
      this._updateRawImage()
      this.render()
      this._fileUpdated()
    }).fail(xhr => {
      // 409 conflict is a CurrentSubmissionEnforcementForm failure
      if (xhr.status == 409) {
        const xhrDecorated = WBRequestXHRDecorator(xhr)
        Util.ajaxErrorDialog(xhrDecorated.getFlashMessage('error'), xhr)

        this._hideLoadingMsg()
        this.ui.adminOptions.hide()
      } else {
        this._hideLoadingMsg()
        this.ui.adminOptions.show()
      }
    })
  },

  // Converts the cropped canvas to a javascript Blob (file-like object of immutable, raw data)
  // Adds to Dropzone
  saveClick: function () {
    this.ui.editorButtonGroup.hide()

    if (!this.cropperEl.cropped) {
      // We manually call crop so we always have a "cropped" canvas
      this.cropperEl.crop()
      this.syncContainerDataDimensions()
      this.setCropBoxDimensions()
    }

    this.ui.editedImageWrapper.prepend('<div class="loading-indicator-bar"></div>')
    this._displayLoadingMsg('Saving Image...')

    if (this.adminMode) {
      this.ui.adminOptions.hide()
    } else {
      this.dzOperator.removeAllFiles()
      // Hide the entire ImageEditor while we send to Dropzone
      this.ui.editorContainer.hide()
    }

    // Convert canvas to javascript Blob before passing to Dropzone as Dropzone does not take base64 binary images
    const editedCanvas = this.cropperEl.getCroppedCanvas()
    editedCanvas.toBlob(function (imgBlob) {
      if (this.adminMode) {
        this._updateImage(imgBlob)
        // this.editing(false); # REVIEW

      } else {
        imgBlob.name = `${this.dzOperator.$element.attr('data-file-title')}.png`
        this.addToDropzone(this.dzOperator, imgBlob)
        this.editing(false)
        this.destroy() // Destroy the view so we don't have dupes
      }
    }.bind(this))
  },

  // Displays the crop box overlay on canvas editor
  cropClick: function (e) {
    e.stopPropagation()
    e.preventDefault()

    this.cropperEl.crop()
    this.setCropBoxDimensions()

    this.ui.editorContainer.addClass('img-edited')
  },

  // Initializes Cropper on canvas
  editClick: function (e) {
    e.stopPropagation()
    e.preventDefault()

    if (this.cropperEl) { this.cropperEl = null }; // reset

    if (this.adminMode) {
      this.editingMode = true
      this.loadImg()
    } else {
      this.editing(true)
    }
  },

  // Sets the crop box to the entire size of the container
  setCropBoxDimensions: function () {
    this.syncContainerDataDimensions()

    this.cropperEl.setCropBoxData({
      top: 0,
      left: 0,
      height: this.$el.find('.cropper-container').height(),
      width: this.$el.find('.cropper-container').width(),
    })
  },

  // Resizes image canvas to dimensions of container
  resizeToContainer: function () {
    let newWidth
    let newHeight
    const newDimensions = {
      left: 0,
      top: 0,
    }

    if (this.ui.editorContainer.width() > this.cropperEl.image.naturalWidth) {
      // If original img is smaller than container, let's set the container size to the image size
      newWidth = this.cropperEl.image.width
      newHeight = this.cropperEl.image.height

      // Set editor container max-width to original img size width for responsiveness
      this.ui.editorContainer.css({ "max-width": newWidth })
    } else {
      newWidth = this.ui.editorContainer.width()
      newHeight = this.ui.editorContainer.height()
    }

    // Merge new height/width
    _.extend(newDimensions, { width: newWidth, height: newHeight })

    // Set the dimensions of the canvas data
    this.cropperEl.setData(newDimensions)
    this.cropperEl.setCanvasData(newDimensions)

    // Set the dimensions of cropper container
    this.$el.find('.cropper-container').css({
      width: `${this.cropperEl.getCanvasData().width}px`,
      height: `${this.cropperEl.getCanvasData().height}px`,
    })

    this.syncContainerDataDimensions()
  },

  // Rotates image on canvas left
  clickRotateImageLeft: function () {
    this.cropperEl.clear()
    this.cropperEl.rotate(-90)
    this.resizeEditor()
    this.ui.editorContainer.addClass('img-edited')
  },

  // Rotates image on canvas right
  clickRotateImageRight: function () {
    this.cropperEl.clear()
    this.cropperEl.rotate(90)
    this.resizeEditor()
    this.ui.editorContainer.addClass('img-edited')
  },

  // Reset the image and crop box to their initial states
  clickReset: function () {
    this.cropperEl.reset()
    this.cropperEl.clear()

    this.resizeToContainer()
    this.ui.editorContainer.removeClass('img-edited')

    if (this.adminMode) {
      this.ui.editedImageWrapper.prepend('<div class="loading-indicator-bar"></div>')
      this.ui.editorContainer.removeClass('img-editing')
      this.ui.adminOptions.show()
    }
  },

  // Manually adds the file to Dropzone field for processing
  addToDropzone: function (dz, img) {
    dz.addFile(img)
  },

  // Create <img> element from AWS uploaded file for Cropper
  loadImg: function () {
    this._displayLoadingMsg()

    if (this.adminMode) {
      this._displayLoadingMsg()

      if (this.editingMode == true) {
        this.ui.adminOptions.hide()
      }
    }

    const self = this
    const xhr = new XMLHttpRequest()

    // Use an AJAX request workaround to bypass CORS AWS image conflicts with CropperJS canvas
    xhr.onload = function () {
      // URL.createObjectURL creates a DOMString containing an URL representing the object given in parameter.
      // The object URL represents the specified Blob object.
      const url = URL.createObjectURL(this.response)
      const img = new Image()

      img.onload = function () {
        // Resize the image to be < MAX image dimensions
        const resizedImg = Images.resize(img, { maxWidth: 800, maxHeight: 800 })

        // Save a reference to this Obj URL so we can destroy it after we are completely finished with it
        this.objectURL = url

        // load img tag into DOM
        self.ui.editedImageWrapper.html(resizedImg)

        if (self.adminMode) {
          self._hideLoadingMsg()
        }
        self.editing(self.editingMode)

        // reset
        self.editingMode = true
      }
      img.src = url
    }

    xhr.open('GET', this.imgURL, true)
    xhr.responseType = 'blob'
    xhr.send()
  },

  // Intializes cropper canvas element
  initCropperEl: function () {
    // Don't initialize if cropper element already exists
    if (!this.cropperEl == undefined || !this.cropperEl == null)
    {return}

    const img = this.$el.find('.editor-img > img')[0]
    const self = this

    new Cropper(img, {
      autoCrop: false,          // We only show crop window on manual click
      viewMode: 1,              // The crop box should be within canvas
      zoomOnWheel: false,
      zoomOnTouch: false,
      scalable: false,
      movable: false,
      responsive: false,        // resizeEditor() does this for us
      autoCropArea: 1,          // 1 = 100% of image
      restore: false,           // This must be false since we are not using CropperJS built-in responsiveness and we do not want to restore editor on resize
      checkOrientation: false,  // Let the user orient their photo, Cropper's guess is not always correct
      dragMode: 'none',
      setDragMode: 'none',
      toggleDragModeOnDblclick: 'false', // Disable the toggle drag mode between "crop" and "move" when click twice on the cropper.
      guides: false,
      built: function () {
        if (!self.adminMode) {
          // Wait to display editing buttons until Cropper built
          self.ui.editorContainer.addClass('img-editing')
        }

        // Save a ref to cropper el
        self.cropperEl = this.cropper
        self.resizeEditor()
      },
    })
  },

  // Resizes entire editor to be truly responsive with no emptyspace
  resizeEditor: function () {
    if (!this.cropperEl) { return };

    this.cropperEl.clear()
    this.cropperEl.resize()
    this.resizeToContainer()
    this.setCropBoxDimensions()
  },

  // Sets image container $('.editor-img') to exact dimensions of cropper container $('.cropper-container')
  // Because we manually update the dimensions of the cropper container on resize, we need to keep these in sync
  syncContainerDataDimensions: function () {
    this.cropperEl.getContainerData().height = $(this.cropperEl.cropper).height()
    this.cropperEl.getContainerData().width = $(this.cropperEl.cropper).width()
  },

  _getLastModifiedBy: function () {
    $.ajax({
      url: Routes.submission_attachment_last_updated_path({ id: this.submission.id, attachment_id: this.imgFileName }),
      type: "GET",
      dataType: 'json',
    }).done(function (data) {
      if (!data.edited) {
        if (typeof(this.ui.revertButton) == 'object'){ this.ui.revertButton.hide() };
        if (typeof(this.ui.showOriginalButton) == 'object'){ this.ui.showOriginalButton.hide() };
      }
    }.bind(this))
      .fail( function (){
      // If we cannot determine when an attachment was last modified, hide the admin bar
        this.ui.adminOptions.hide()
      }.bind(this))
  },

  // Updates attachment with edited blob
  _updateImage: function (blob) {
    const formData = new FormData()
    formData.append('edited_image', blob)

    $.ajax({
      url: Routes.submission_attachment_update_path({ id: this.submission.id, attachment_id: this.imgFileName }),
      type: "PATCH",
      data: formData,
      processData: false,
      contentType: false,
      timeout: 0,
      wbGenericFailureMsg: "Sorry, we couldn't update this image.",
    }).done(data => {
      Util.showFlashNotice('Attachment updated.')

      // Set edited image url and rerender
      this.imgURL = data.new_file_url
      this.imgFileName = this._getFileName()
      this.editingMode = false
      this._updateRawImage()
      this.render()
      this._fileUpdated()
    }).fail(xhr => {
      // 409 conflict is a CurrentSubmissionEnforcementForm failure
      if (xhr.status == 409) {
        const xhrDecorated = WBRequestXHRDecorator(xhr)
        Util.ajaxErrorDialog(xhrDecorated.getFlashMessage('error'), xhr)
      }

      // Reset state to original image and hide admin bar
      this._hideLoadingMsg()
      this.clickReset()
      this.ui.adminOptions.hide()
    })
  },

  // Re-creates the intial image that is loaded on the page for adminMode
  _updateRawImage: function () {
    this.$el.parent().find('.attachment-link').append('<img/>')
    this.$el.parent().find('.attachment-link img').attr('src', this.imgURL)
  },

  _displayLoadingMsg: function (content) {
    const messaging = content || 'Loading Editor...'
    this.$el.find('.loading-indicator-bar').html(`<i class="fa  fa-circle-o-notch fa-spin"></i>&nbsp;&nbsp;<span>${messaging}</span`).show()
  },

  _hideLoadingMsg: function () {
    this.$el.find('.loading-indicator-bar').hide()
  },

  // Callback for updating attachment
  _fileUpdated: function () {
    if (!this.getOption('updateCallback')) {return}

    const updatedAttrs = {
      updatedUrl: Routes.Api.submission_attachment_path({id: this.submission.id, file: this.imgFileName, download: 1}),
      updatedFileName: this.imgFileName,
      originalFileIdentifier: this.initialImgFilename,
      updatedContentType: 'image/png', // Note: All edited imgs are saved as .png
    }
    this.options.updateCallback(updatedAttrs)

    // Reset intial file name to updated file name because we are rerendering the view
    this.initialImgFilename = this.imgFileName
  },

  // Returns filename from full image URL
  //  ex. (AWS full image URL) "https://wb-development.s3-us-west-1.amazonaws.com/red/submission_components/430/file_test.png?X-Amz-Algorithm=blah&X-AmzCredential=blah"
  //      (AttachmentsController#show path) "/submissions/429/attachments/file_back.png"
  //
  // How this works:
  // 1. Split the string at '?' to remove AWS URL params and use the first part. If `?` doesn't exist, we get the same URL back:
  //     "https://wb-development.s3-us-west-1.amazonaws.com/red/submission_components/430/file_test.png"
  // 2. Splitting that at '/' gives you:
  //     ["https:", "", "wb-development.s3-us-west-1.amazonaws.com", "red", "submission_components", "430", "file_test.png"]
  //     ["", "submissions", "429", "attachments", "file_back.png"]
  // 3. Take the end of URL with pop()
  //     "file_test.png"
  //     "file_back.png"
  // REVIEW: consider improving parsing with regex.
  _getFileName: function () {
    return this.imgURL.split('?')[0].split('/').pop()
  },
})
