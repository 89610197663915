import ErrorService from 'lib/error_service'
import StringUtil from 'lib/util/string'

// Localized Strings
// NOTE: Today just returns English-language, but is intended to mirror the setup in the Rails locale files. In particular,
// strings used in flash messages. If we're really localizing, we'll probably switch to a more robust localization library.
// This is mostly to get us in the habit of maintaining or JS string similar to how we do it in Rails.


// Localized Strings
// Can be either:
// - a string
// - a function for building a string
const localeStrings = {
  common: {
    buttons: {
      saveAndContinue: "Save & Continue",
    },
  },
  accounts: {
    create: {
      fail: "Sorry, we could not create the account.",
    },
    settings: {
      header: {
        title: "General Settings",
        description: "Set defaults and other options related to your WorkBright setup.",
      },
      startDateRequired: {
        helpText: "This requires each staff member to have a start date to indicate when they are expected to start working, which you can modify if need be. A start date is required by some integrations (like E-Verify) and helps staff receive timely notifications about their forms.",
        hint: "We strongly suggest enabling this option because E-Verify requires it.",
      },
      notificationsMethod: {
        helpText: "This sets the default method for delivering notifications to staff. The notification delivery method can also be changed for individual staff (by either an administrator or the staff member) if they prefer a different option.<br/><br/>If Text is selected, we will send emails as a backup delivery option to staff who have missing/invalid phone numbers.",
      },
      onboardingNotificationMethod: {
        helpText: "This sets the default method for delivering onboarding notifications to staff. The onboarding notification delivery method can also be changed for individual staff (by either an administrator or the staff member) if they prefer a different option.<br/><br/>If Text is selected, we will send emails as a backup delivery option to staff who have missing/invalid phone numbers.",
      },
      embedded: {
        urlHint: "URL to redirect mobile users after they've completed all forms. It may be a link to a website, or your own app with a custom URL scheme.",
        textHint: "Text on the redirect button displayed to users after all forms are completed.",
        previewLinkHint: "'Forms Complete' preview link is available when 'Embedded redirect URL' is configured.",
      },
    },
  },
  staff: {
    i9: {
      documentVerification: {
        pending: {
          variant: "info",
          title: "Please, wait",
          message: "The document verification will begin shortly",
        },
        completed: {
          variant: "success",
          title: "Document successfully verified",
          message: "Please click next to proceed to the next step",
        },
        cancelled: {
          variant: "danger",
          title: "Whoops",
          message: `The document verification was cancelled, click "Previous" to select other documents, or reload this page to start over`,
        },
        error: {
          variant: "danger",
          title: "Error",
          message: `There was an error trying to start the document verification, click "Previous" to select other documents, or reload this page to start over`,
        },
      },
      personalInfo: {
        help_text: {
          other_names_input: "Provide all other last names used, if any (such as maiden name). Enter N/A if you have not used other last names. For example, if you legally changed your last name from Smith to Jones, you should enter Smith in this field. If you legally changed your last name from Smith to Jones, and then from Jones to Moore, you should enter Smith, Jones in this field.",
        },
      },
      preparerTranslator: {
        subTitle: "Was this form completed with the assistance of a preparer or translator?",
        description: "The preparer/translator should complete this section with their information and signature.",
      },
    },
    profile: {
      applied_for_ssn: {
        checkbox: {
          label: "I don't have an SSN yet, but I have applied for it",
          help_text: "Select this box only if you have already applied for your Social Security Number through the Social Security Administration. Once you have received your SSN, you will be able to update your profile with the missing information.",
        },
        help_block: "Check this ONLY if you have actually applied for your SSN",
      },
    },
  },
  i9SubmissionStatus: {
    certify_complete: ({certifierName, certifyDate}) => (`Employer Section completed by ${certifierName} (${certifyDate})`),
    certify_complete_remote: ({certifierName, certifyDate}) => (`Employer Section completed by ${certifierName} (${certifyDate})`),
    certify_failed: 'Error encountered signing Employer Section. Please try again.',
    certify_failed_remote: 'Error during Remote Verification. Please try again.',
    certify_not_matched: (documentTitles) =>  `Authorized Representative indicated mismatch for:<br> ${documentTitles}`,
    certify_not_matched_remote: (documentTitles) => `Remote Authorized Representative indicated mismatch for:<br> ${documentTitles}`,
    certify_remote_verifying: 'Remote Verification started by staff member',
    certify_remote_verifying_remote: 'Remote Verification started by staff member',
    certify_processing: 'Processing Employer Section... (Refresh this page)',
    certify_processing_remote: 'Processing Remote Verification... (Refresh this page)',
    missing: 'Not yet received',
    paper: 'Paper Form I-9',
    pending: 'Pending Admin review',
    rejected: 'Rejected Section 1',
    uncertified: 'Ready to complete Employer Section',
    uncertified_remote: 'Waiting for staff member to begin remote verification',
  },
  document_submissions: {
    create: {
      success: function(documentName) {
        return `Your ${documentName} has been submitted.`
      },
      fail: "Sorry, we could not submit this document.",
    },
    supplementA: {
      perjuryStatement: "I attest, under penalty of perjury, that I have assisted in the completion of Section 1 of this form and that to the best of my knowledge the information is true and correct.",
    },
    supplementb: {
      modal: {
        titleText: function(staffName, sectionName) {
          return `Form I-9 ${sectionName} for ${staffName}`
        },
        perjuryStatement: "I attest, under penalty of perjury, that to the best of my knowledge, this employee is authorized to work in the United States, and if the employee presented documentation, the documentation I examined appears to be genuine and to relate to the individual who presented it.",
      },
    },
    rejection_reasons: {
      form_not_information_match: "The document(s) do not match the information provided",
      form_not_genuine_information: "The document(s) do not appear genuine",
      form_not_relating_to_employee: (staffName) => `The document(s) do not appear to be relating to ${staffName}`,
      form_not_information_correct: (staffName) => `${staffName} has indicated the information provided in Section 1 is incorrect`,
    },
  },
  assignments: {
    reset: {
      success: function(a) {
        return `Requested ${StringUtil.pluralize(a, 'new submission')} successfully.`
      },
    },

    setAsCurrent: {
      success: function(documentName) {
        return `The current submission for ${documentName} was updated.`
      },

      fail: function() {
        return 'Sorry, we could not set this submission as current.'
      },
    },

    processing: function(eventName) {
      return `${eventName} is unavailable because form assignments are updating. This may be to a due form update or season change. Refresh this page in a few minutes to try again.`
    },
  },
  custom_fields: {
    update: {
      success: `Custom field values updated successfully.`,
      fail: 'Sorry, an error occurred updating this field.',
    },
  },
  documents: {
    clone: {
      document_fetch_fail: "Sorry, we couldn't get the list of documents from the selected account.",
      employee_group_fetch_fail: "Sorry, we couldn't get the list of staff groups from the selected account.",
      fail: "We were unable to clone these documents, please try again",
      success: "Document(s) cloned successfully",
    },
    update: {
      success: function(documentName) {
        return `The ${documentName} has been updated`
      },
      fail: "Sorry, an error occurred updating this document.",
    },
    help_text: {
      force_inbox: "If checked, all submissions for this form will be marked as \"Pending Admin Review\" and will show up in the inbox. You will be required to review the form and mark it as either \"Accepted\" or \"Rejected.\"<br/><br/>If unchecked, then submissions will automatically skip the inbox and be marked as \"Accepted\" immediately after they are received. This is typically a good idea for very simple forms, such as those that only require a name & signature. In general, you should exercise caution when skipping the inbox, as it removes the element of human review from the process.",
      force_inbox_hint: "(If unchecked, submissions will skip the inbox and automatically be marked as \"Accepted\" in WorkBright.)",
      staff_initiate_resubmission_hint: "We strongly suggest disabling reviewing this form in the Inbox if you'd like to allow staff to start over if the Authorized Representative has indicated a document mismatch.",
    },
    edit: {
      built_in: {
        w4: "The W-4 form is automatically assigned to Employee/W-2 (W-4 & I-9) staff; however, you can disable it below.",
        i9: "The I-9 form is automatically assigned to Employee/W-2 (W-4 & I-9) staff; however, you can disable it below. Contractors/1099 (W-9) and staff without U.S. Federal forms will never be asked to fill out an I-9.",
        default: "This form is automatically assigned to Employees; however, you can disable it below.",
      },
    },
    w4: {
      hints: {
        filing_status: "Select Head of household only if you’re unmarried and pay more than half the costs of keeping up a home for yourself and a qualifying individual.",
      },
    },
  },
  employeeProfile: {
    applied_for_ssn: 'Applied for SSN',
  },
  employees: {
    activate: {
      success: function(e) {
        return `${StringUtil.pluralize(e, 'staff member')} activated.`
      },
    },

    deactivate: {
      success: function(e) {
        return `${StringUtil.pluralize(e, 'staff member')} deactivated.`
      },
    },

    destroy: {
      success: function(fullName) {
        return `${fullName} has been removed from your organization.`
      },
      fail: "Sorry, we couldn't delete this staff member.",
    },

    bulkDownload: {
      success: function(employeeCount) {
        return `The download request for ${StringUtil.pluralize(employeeCount, 'staff member')} has been queued. You will receive an email when it is completed.`
      },
      fail: "Download request failed",
      errors: {
        documentIds: "Please select at least one form",
        description: function(length) {
          return `Description too long: ${length} character maximum`
        },
      },
      alerts: {
        emptyDocuments: "No forms found",
        noDocumentsPermitted: "You are not permitted to view any forms",
      },
    },

    notificationMethods: {
      popoverText: "Staff will receive notifications by email since phone number has not been provided",
    },

    ri9NotificationMethods: {
      update: {
        success: "Remote I-9 notification method updated.",
      },
    },

    onboardingNotificationMethods: {
      update: {
        success: "Onboarding notification method updated.",
      },
    },
  },
  employee_group_memberships: {
    add: {
      success: function(employeeCount, groupName) {
        return `${StringUtil.pluralize(employeeCount, 'staff member')} added to the group ${groupName}.`
      },
    },
    remove: {
      success: function(employeeCount, groupName) {
        return `${StringUtil.pluralize(employeeCount, 'staff member')} removed from the group ${groupName}.`
      },
    },
  },
  employments: {
    update: {
      success: function(employeeCount) {
        return `The employment dates for ${StringUtil.pluralize(employeeCount, 'staff member')} were updated.`
      },
    },

    errors: {
      start_date: {
        invalid: 'The start date must come before the end date.',
        required: 'Start date is required by your account settings.',
      },
    },

    help_text: {
      hire_date: "This is your internal, legal Date of Hire for this staff member. It can reflect whatever makes most sense for your business (e.g. the date they accepted the job offer, their first training day, etc). This date can be used as a merge field for forms.",
      start_date: "This is the staff member's official start date. It will appear on their dashboard and can be used as a merge field on forms.",
      end_date: "This is the staff member's official last day. It will appear on their dashboard and can be used as a merge field on forms.",
      onboard_now: "Active staff members will immediately receive a notification inviting them to log in and complete assigned forms. Then they will receive reminder notifications for the next month or until their assigned forms are complete.",
      onboard_later: "Active staff members will receive a welcome notification on a future date that you set - and they will start to receive subsequent reminder notifications.<br/><br/>This option allows you to make changes to their profiles and form assignments before they receive notification.<br/><br/>We suggest setting this date for tomorrow to allow configuration time for any profile changes you are making.",
      onboard_never: "Choose this option if you do not want staff members to receive a notification inviting them to log in and complete forms. You can change this setting at a future date, at which time Active staff members will receive a notification if they have any incomplete assigned forms.",
    },
    i9_remote_reverification: "If ON, Remote Reverification is enabled for this staff member. After submitting their document(s) for reverification of work eligibility, they will be instructed to ask a 3rd party to review and verify the documentation and sign that they have completed the verification. If OFF, Remote Reverification is disabled for this staff member. And, when they are required to reverify employment eligibility, Supplement B will need to be reviewed and countersigned by an administrator.",
    i9_remote_verification: "If ON, remote verification is enabled for this staff member. After submitting the I-9 form, they will be instructed to ask a 3rd party to review and verify the form's fields, documentation, and signature. If OFF, remote verification is disabled for this staff member. When they submit the I-9 form, it will need to be reviewed and countersigned by an administrator.",
  },
  everify: {
    close_case_instructions: {
      pending_referral: "You may want to close a case in <strong>Pending Referral</strong> if the staff's info was entered incorrectly or if the case needs to be resubmitted. Closing the case will not cause the staff's employment to be authorized or rejected by E-Verify so a new case will need to be submitted.",
      final_nonconfirmation: "Cases in <strong>Final Nonconfirmation</strong> have finished processing but must be closed to complete their workflow. Please select a reason and then close the case.",
      close_case_and_resubmit: "Please select a reason and then close the case.",
      photo_match: "Please select a reason and then close the case.",
      unconfirmed_data: "Please select a reason and then close the case.",
      scan_and_upload: "Please select a reason and then close the case.",
    },
    errors: {
      close_case: {
        timeout: "Close case request sent. Waiting for E-Verify to update case status.",
      },
      photo_matching: {
        timeout: "Photo-matching request sent. Waiting for E-Verify to update case status.",
      },
      scan_and_upload: {
        timeout: "Scan and upload request sent. Waiting for E-Verify to update case status.",
      },
      unconfirmed_data: {
        timeout: "Unconfirmed data request sent. Waiting for E-Verify to update case status.",
      },
      tnc_no_action: {
        timeout: "TNC No action request sent. Waiting for E-Verify to update case status.",
      },
      tnc_refer_case: {
        timeout: "TNC Refer case request sent. Waiting for E-Verify to update case status.",
      },
    },
    status: {
      created: {
        title: 'Awaiting submission to E-Verify',
        nextSteps: 'The case will be submitted to E-Verify soon. No action is required at this time.',
      },
      closed_authorized: {
        title: 'Closed - Authorized',
        nextSteps: 'The E-Verify case has been closed and the staff member has been authorized to work. No further action is available.',
      },
      closed_nonconfirmation: {
        title: 'Closed - Unauthorized',
        nextSteps: 'The E-Verify case resulted in a Tentative Nonconfirmation (TNC) and has been closed. The staff member has not been authorized by E-Verify.',
      },
      closed_other: {
        title: 'Closed - Other',
        nextSteps: 'The E-Verify case has been closed with a non-authorization reason. View the case in E-Verify for more details.',
      },
      manually_closed: {
        title: 'Closed by Admin',
        nextSteps: 'This case has been closed by an administrator. No further action is available.',
      },
      close_case_and_resubmit: {
        title: 'Close Case and Resubmit',
        instructions: 'E-Verify has determined that this case should be closed and resubmitted due to technical problems. You should close this case and resubmit the Form I-9 data to create a new case.',
        nextSteps: 'E-Verify was unable to process this case. Please close the case and create a new one.',
        extendedSlideoutNextSteps: 'E-Verify was unable to process this case. Please close the case and create a new one.',
      },
      draft: {
        title: 'Draft',
        nextSteps: 'A draft case has been created in E-Verify and will begin processing soon. No action is required at this time.',
      },
      employment_authorized: {
        title: 'Employment Authorized',
        nextSteps: "The staff member's employment has been approved by E-Verify. Please close the case.",
      },
      final_nonconfirmation: {
        title: 'Final Non-confirmation (TNC)',
        instructions: 'This case has ended up in "final non-confirmation" status, which means that the employee has not been authorized to work. You should now close the case.',
        nextSteps: "E-Verify cannot confirm this staff member's employment eligibility. Please visit E-Verify to close the case.",
        extendedSlideoutNextSteps: "E-Verify cannot confirm this staff member's employment eligibility.Please visit E- Verify to close the case.",
      },
      internal_error: {
        title: 'Internal Error',
        nextSteps: 'There was an error in WorkBright while processing this case. View and update the case in E-Verify until the error is fixed in WorkBright.',
      },
      manual_review: {
        title: 'Manual Review',
        nextSteps: 'The case is being reviewed by E-Verify. No action is required at this time.',
      },
      photo_match: {
        title: 'Photo Match Required',
        nextSteps: "Compare the staff member's photos to verify that they match.",
        extendedSlideoutNextSteps: "Compare the staff member's photos to verify that they match.",
      },
      queued: {
        title: 'Queued',
        nextSteps: 'The case has been queued by E-Verify and will begin processing soon. No action is required at this time.',
      },
      referred: {
        extendedSlideoutNextSteps: 'This case requires the staff member to provide more information or take action.',
        helpText: 'E-Verify has flagged this case as a "tentative non-confirmation (TNC)" and the employee previously chose to refer/dispute the case. It is either their responsibility to follow up with DHS and/or SSA (based on instructions in the Further Action Notice). You should download the Referral Date Confirmation letter for this case and present it to the staff member. Otherwise, there is nothing to do until E-Verify updates the status of this case.',
        nextSteps: 'This case requires the staff member to provide more information or take action.',
        noData: 'We were unable to retrieve the Further Action Notice or Referral Date Confirmation Letter from E-Verify.',
        title: 'Referred (TNC)',
      },
      scan_and_upload: {
        title: 'Scan and Upload',
        nextSteps: "The staff member's photo did not match the E- Verify's photo for the staff member. Please view the case in E-Verify to upload a new photo of the staff member.",
        extendedSlideoutNextSteps: "E-Verify requires photos of the staff member's documentation. Follow the instructions provided to send photos to E-Verify.",
      },
      submitted: {
        title: 'Submitted',
        nextSteps: 'The case has been submitted to E-Verify and will begin processing soon. No action is required at this time.',
      },
      pending_referral: {
        title: 'Pending Referral (TNC)',
        nextSteps: 'This case requires the staff member to provide more information or take action.',
        extendedSlideoutNextSteps: "This case requires the staff member to provide more information or take action.",
        employeeNotifiedCheckboxLabel: "I presented the FAN to the employee and informed them of their options.",
        instructions: (firstName) => `E-Verify has flagged this case as a "tentative non-confirmation (TNC)" and requested the employee to follow up with additional information. <i>You should inform ${firstName} of these options and ask them to choose one</i>:`,
        disclaimer: `<i>Note: There may be state-specific guidance on the method in which you need to inform the employee of their TNC. By selecting "Refer Case", we will notify the employee and present the FAN via WorkBright according to their selected notification method. Please refer to your state-specific guidelines if additional notification outside of WorkBright is required.</i>`,
        adverseActionAlert: "IMPORTANT: Employers may not terminate, suspend, delay training, withhold or lower pay, or take any other adverse action against an employee because of the mismatch, until the mismatch becomes a Final Nonconfirmation.",
        options: (firstName) => {
          return [
            `<strong>Refer Case:</strong> This means the employee will contact DHS and/or SSA to resolve the case. If the employee chooses this option, is their responsability to provide the specified agency with the requested information to resolve the case. In this case, you should download the "Further Action Notice", present it to ${firstName}, and then click the "Refer Case" button below.`,
            `<strong>No Action:</strong> This means the employee has decided not to take action to resolve the case and you should click the "No Action" button below. This will result in a closed case with "Final Nonconfirmation" status.`,
          ]
        },
        refer: "You have chosen to take action; this means you must contact DHS or SSA to resolve the case.",
        no_action: "You have chosen to take no action; this means the case will be closed with a 'Final Nonconfirmation' status.",
      },
      pending_referral_bad_data: {
        title: 'Pending Referral Bad Data (TNC)',
      },
      unconfirmed_data: {
        title: 'Unconfirmed Data',
        nextSteps: 'E-Verify needs you to confirm the data on this case before it can be processed. Please view the case in E-Verify confirm the data.',
        extendedSlideoutNextSteps: 'E-Verify has requested that you confirm some data for this case before it can be processed. Please review/update these fields and then resubmit them to E-Verify.',
        instructions: 'Please review all these fields and update any with incorrect data. When everything looks correct, click the button to resubmit the data to E-Verify.',
        fields: {
          first_name: {label: "First Name"},
          last_name: {label: "Last Name"},
          date_of_birth: {label: "Date Of Birth"},
          ssn: {label: "Social Security Number (SSN)"},
        },
        sync_error: 'Something went wrong when syncing data with E-Verify, please contact support for more information.',
        warning_update: "Any updates to the following fields will only be sent to E-Verify and will not update any fields in WorkBright, such as the staff's profile or Form I-9.",
      },
      unsubmitted_receipt: {
        title: 'Unsubmitted',
        nextSteps: 'The case could not be submitted to E-Verify because the I-9 has not been successfully completed.',
      },
      submission_error: {
        title: 'Submission Error',
        nextSteps: ' ',
        fieldNameLookup: {
          resubmissionFields: {
            ssn: 'Social Security Number',
            citizenship_status_code: 'Citizenship Status', // Section 1 Form I-9
            first_name: 'First Name',
            middle_initial: 'Middle Name',
            last_name: 'Last Name',
            other_last_names_used: 'Other Last Names Used',
            date_of_birth: 'Birthdate',
            phone_number: 'Phone Number',
            employee_email_address: 'Email Address',
            alien_number: 'Alien Number',
          },
          updateDocumentationFields: {
            date_of_hire: 'Employment Start Date',
            document_a_type_code: 'List A Document Type',
            document_b_type_code: 'List B Document Type',
            document_c_type_code: 'List C Document Type',
            document_bc_number: 'List BC Document Number',
            document_sub_type_code: 'Document Sub-Type',
            us_passport_number: 'US Passport Number',
            foreign_passport_number: 'Foreign Passport Number',
            foreign_passport_with_form_i94: 'Foreign Passport with I94 Form',
            i551_number: 'I551 Number',
            i94_number: 'I94 Number',
            i766_number: 'I766 Number',
            country_code: 'Country',
            expiration_date: 'Document Expiration Date',
            visa_number: 'Visa Number',
            sevis_number: 'Sevis Number',
            us_state_code: 'US State Name',
            no_expiration_date: 'Document Expiration Date',
            reason_for_delay_code: 'Late Verification Reason',
            reason_for_delay_description: 'Late Verification Reason Description (Other Reason)',
            case_creator_name: "Administrator's Name",
            case_creator_email_address: "Administrator's Email Address",
            case_creator_phone_number: "Administrator's Phone Number",
            case_creator_phone_number_extension: "Administrator's Phone Number Extension",
          },
          referToWorkBrightFields: {
            client_company_id: 'Company ID',
            client_software_version: 'Software Version',
            employer_case_id: 'Company Case ID',
          },
          duplicateContinueReasonFields: {
            duplicate_continue_reason: 'Duplicate Continue Reason',
          },
        },
        missing_duplicate_continue_reason: {
          extendedSlideoutNextSteps: 'To continue creating a case with the information from this Form I-9, select a duplicate continue reason.',
        },
      },
    },
    staff: {
      tnc: {
        instructions: `Your employer is informing you that your E-Verify case received a U.S. Department of
          Homeland Security (DHS) and/or Social Security Administration (SSA)
          Tentative Non-Confirmation (mismatch) case result. A DHS and/or SSA mismatch means that
          the information your employer entered in E-Verify from your Form I-9,
          Employment Eligibility Verification, did not match records available to DHS and/or SSA.
          A DHS and/or SSA mismatch case result does not necessarily mean that you are not
          authorized to work in the United States.`,
        whatIsEverify: `E-Verify is an internet-based system that compares information from your Form I-9,
          Employment Eligibility Verification, to records available to the U.S. Department of
          Homeland Security (DHS) and Social Security Administration (SSA) to confirm your
          identity and that you are authorized to work in the United States.`,
      },
    },
    deadline: 'Estimated deadline',
    deadlineFilter: {
      any: 'Any deadline',
      all: 'All',
      past: 'Past due',
      soon: 'Within 3 days',
    },
  },
  errors: {
    ajax: {
      generic: "Something went wrong",
      fetch: function(modelName) {
        return `An error occurred fetching ${modelName}.`
      },
    },
    form: {
      generic: "Oops, looks like there was a problem with your form:",
      ocr: "We were unable to read all required data from your document. Please manually enter the information below (you may need to scroll down).",
    },
    email: {
      emailBounced: "The last email we sent to this staff member has bounced or been rejected. Please double-check the email address.",
    },
  },
  forms: {
    save: 'Save',
    cancel: 'Cancel',
  },
  everify_account: {
    create: {
      fail: "Sorry, we could not create the E-Verify account.",
      success: function(_accountName) {
        return `E-Verify Account has been configured.`
      },
    },
    update: {
      success: function(_accountName) {
        return `E-Verify Account has been updated.`
      },
    },
    authFailure: function(companyName, helpDeskLink) {
      return `The Web Services credentials for ${companyName} are invalid or expired. Please update your Web Services password by following the instructions in <a href=${helpDeskLink} target="_blank">this article</a>.<p></p>Cases will not be processed by this account until the Web Services credentials are updated.`
    },
    startDateMessage: "E-Verify requires every Form I-9 to include the employee's \"first day of employment\", which we store as \"start date\". When you enable E-Verify, your account will require a start date for all staff members and this date will be sent to E-Verify with every Form I-9.",
  },
  permissions: {
    profile_fields_step: {
      stepHeader: function(firstName) {
        return `For employees in these groups, what profile information should ${firstName} be able to access?`
      },
      fieldRestrictionsinPdfs: "NOTE: If you restrict specific field access, but then configure access to include a form that contains that field, the administrator WILL be able to see/download/report on that entire form, INCLUDING the restricted field.",
      managed: {
        stepHeader: "What profile information should this policy holder be able to access?",
      },
    },

    documents_step: {
      stepHeader: "What about form submissions from staff members in these groups?",
      fieldRestrictionsinPdfs: "NOTE: If you restricted specific field access, but now configure access to include a form that contains that field, the administrator WILL be able to see/download/report on that entire form, INCLUDING the restricted field.",
      managed: {
        stepHeader: "What access should this policy holder have for form submissions?",
      },
    },

    employee_management_step: {
      stepHeader: "Other actions which apply to people in these groups:",
      managed: {
        stepHeader: "What other actions should this policy holder be able to perform for staff in their assigned groups?",
      },
    },

    other_people_step: {
      stepHeader: function(firstName) {
        return `For people that don't belong to any of these groups, ${firstName} won't have access to do anything. If you'd like to give some read-only access, select an option below:`
      },
      managed: {
        stepHeader: "For staff that don't belong in the policy holder's assigned groups, select additional read-only access options below:",
      },
    },

    other_actions_step: {
      stepHeader: function(firstName) {
        return `Should ${firstName} be allowed to do any of the following?`
      },
      managed: {
        stepHeader: "Should this policy holder be allowed to do any of the following?",
      },
    },

    summary_step: {
      stepHeader: function(name) {
        return `A summary of permissions for ${name}`
      },
    },
  },
  signature_pad: {
    instructions: {
      drawSignature: "Draw your signature",
      printFullName: "Enter your first and last name",
    },
  },
  sso_config: {
    help_text: {
      idpName: "The name of the Identity Provider.",
      entityId: "An ID issued by the IdP for your account.",
      targetUrl: "The url that WorkBright will redirect users to when they sign in with SSO",
      fingerprint: "A fingerprint generated by the Identity Provider to authenticate requests.",
      fingerprintAlgo: "The algorithm used by the Identity Provider to generate a certified fingerprint.",
      authnContext: "The authentication context indicates how a user authenticated at an Identity Provider.",
    },
    create: {
      fail: "Sorry, we could not save the SSO settings.",
      success: "SSO settings have been saved.",
    },
    update: {
      fail: "Sorry, we could not update the SSO settings.",
      success: "SSO settings have been updated.",
    },
    scim_settings: {
      regenerate_token: {
        success: "SCIM Bearer Token has been regenerated.",
        fail: "Sorry, we could not regenerate a SCIM token.",
      },
      activate: {
        success: "SCIM provisioning has been activated and your bearer token has been generated",
        fail: "Sorry, we could not activate the SCIM settings",
      },
      deactivate: {
        success: "SCIM provisioning has been deactivated and the token deleted",
        fail: "Sorry, we could not deactivate the SCIM settings",
      },
    },
  },
  integrations: {
    adp: {
      config: {
        mappingOptions: "Before submitting staff to ADP®, please select how you would like to collect their payroll data.",
        staffPayroll: {
          title: "Staff Payroll Fields",
          description: "Fields that staff will submit: Bank Account Number, Bank Routing Number, and Bank Account Type.",
          options: {
            customForm: {
              optionText: "<strong>Use my form</strong><br/>I'm already using a direct deposit form in WorkBright and it includes these fields. I'd like to keep using that form and map the fields to WorkBright's payroll form.",
              popoverTitle: "Custom Forms",
              popoverText: "These payroll fields are all required: Bank Account Number, Bank Routing Number, and Bank Account Type. They can exist on different forms but they must be mapped to WorkBright's payroll fields before data can be sent to ADP. If you select this option, you will be asked to map the fields in the next step.",
            },
            workbrightForm: {
              optionText: "<strong>Use WorkBright's payroll form</strong><br/>I do not have a direct deposit form in WorkBright and I would like to use WorkBright's payroll form.",
              popoverTitle: "WorkBright's Payroll Form",
              popoverText: "WorkBright's Payroll Form contains fields for bank account number, bank routing number, and bank account type - all of which can be sent to ADP.",
            },
          },
        },
        internalPayroll: {
          title: "Internal Payroll Fields",
          description: "Fields that only admins can submit: Pay Frequency, Pay Rate, and Pay Rate Type.",
          options: {
            customFields: {
              optionText: "<strong>Use my custom fields</strong><br/>I'm already using my own custom fields for these payroll fields.",
              popoverTitle: "Custom Fields",
              popoverText: "These required payroll fields must all be mapped to WorkBright's payroll fields before data can be sent to ADP: Pay Frequency, Pay Rate, and Pay Rate Type. If you select this option, you will be asked to map the fields in the next step.",
            },
            workbrightStandardFields: {
              optionText: "<strong>Use WorkBright's payroll fields</strong><br/>I do not have these payroll fields set up in my WorkBright account.",
              popoverTitle: "WorkBright's Internal Payroll Fields",
              popoverText: "WorkBright's internal payroll fields include Pay Frequency, Pay Rate, and Pay Rate Type - and they can all be sent to ADP.",
            },
          },
        },
        messages: {
          optionsSaved: "Integration options saved",
          optionsNotSaved: "An error occurred saving the integration options.",
        },
        updateFieldMapping: {
          modal: {
            titleText: "Update Field Mapping Preferences",
            message: "<strong>Updating will clear your current field mapping preferences.</strong><br/>Are you sure you want to update?",
          },
          updateButtonText: "Update Field Mapping Preferences",
        },
      },
      sync: {
        syncError: "An error occurred while syncing the direct deposit data to ADP® Workforce Now. Please <a href=\"mailto:support@workbright.com\">contact WorkBright Support</a> for help.",
      },
    },
  },
  navbar: {
    announcements: "New stuff!",
  },
  standard_fields: {
    mapping: {
      instructions: "Based on your selections from the previous step, these are the fields you need to map to finish configuration of your ADP® Integration. For internal fields, not all fields must be mapped.",
      single_direct_deposit_note: "Note: WorkBright supports mapping only one direct deposit account at this time.",
      error_message: 'All non-internal fields shown here must be mapped to proceed',
    },
    account_no_fields: 'Your account is not currently configured with any Standard Fields.',
    add_mapping: 'Add Mapping',
    choose_field: (standardFieldName) => {
      return `Choose which field you would like to associate with the standard field ${standardFieldName}:`
    },
    delete_mapping: 'Delete Mapping',
    delete_mapping_error: 'Unable to delete mapping',
    description: 'Description',
    field: 'Field',
    internal: 'Internal',
    mapped: 'Mapped',
    mapping_failed: 'Unable to save mapping',
    mapping_updated: 'Mapping updated',
    mapping_deleted: 'Mapping deleted',
    no_fields: (standardFieldName) => {
      return `There are no fields available to map to the standard field ${standardFieldName}`
    },
    options_instructions: (mappedFieldName, standardFieldName) => {
      return `Tell us how each option for ${mappedFieldName} should map to options for ${standardFieldName}`
    },
    select_field: 'Select Field',
    select_value: 'You must select a value for each option.',
    specify_field: 'You must specify a Field to map.',
    standard_field_mapping: 'Standard Field Mapping',
    update_mapping: 'Update Mapping',
  },
  reclaimEmail: {
    title: "Reclaim Email Address",
    description: "Free up an email address that belongs to a deleted employee",
  },
  remoteCountersign: {
    certify_identity_label: (employeeFullName) => `I attest that I am an authorized representative and NOT ${employeeFullName} and that the above information is correct.`,
    perjuryStatement: "I attest, under penalty of perjury, that (1) I have examined the document(s) presented by the above-named employee, (2) the above-listed document(s) appear to be genuine and to relate to the employee named, and (3) to the best of my knowledge the employee is authorized to work in the United States.",
    return_dashboard: "Return to your forms list.",
    review_document_instructions: `Certify, under the penalty of perjury that the physical document(s):`,
    submit_location_instructions: (employee) => `${employee} is requesting that you review their document(s) and sign their Form I-9. You will need to be in the same place and with ${employee}, review their document(s), and then to sign a statement that, under penalty of perjury, you have examined the documents presented by ${employee}, that the documents appear to be genuine and to relate to ${employee}, and to the best of your knowledge ${employee} is authorized to work in the United States.` ,
    verification_finished: "I-9 verification completed. Thank you, you are finished.",
    verification_rejected: "I-9 verification could not be completed using this verification process. If the employee wishes to provide alternative document(s), they may contact the employer. Thank you, you are finished.",
    reverify: {
      employee_share_location: `Click the button to share your location.`,
      employee_choose_auth_rep: "Enter the cell number of the person who will review your document(s). It will text them a link. You will both need good cell coverage to proceed.",
      employee_waiting_on_auth_rep: "Make sure you have your document(s) and get ready to show them to the person who you have chosen to review them.",
      finish_without_reverifying: (employeeFullName) => {
        return `By clicking Finish below, you are stating that the documents provided by ${employeeFullName} do not match.`
      },
      reverify_accepted: "I-9 reverification completed. Thank you, you are finished.",
      reverify_rejected: "I-9 reverification could not be completed using this verification process. If the employee wishes to provide alternative document(s), they may contact the employer. Thank you, you are finished.",
      reverify_accepted_employee: "Your authorized representative has completed your remote I-9 reverification, thank you!",
      closeWindow: "You may close this browser window",
      document_receipt_instructions: "An employee may use a receipt as proof that they can provide a physical document at a later date. If you are shown a receipt, verify that 1) The word \"Receipt\" appears in the name of the document above the Issuing Authority above, 2) The Expiration Date is 90 days from the date the receipt was issued, and 3) The recept being shown to you appears to be genuine and matches the photo. If ALL THREE are true, click Match. If not, click No Match",
      perjuryStatement: "UNDER PENALTY OF PERJURY, YOU CANNOT SIGN YOUR OWN FORM I-9 SUPPLEMENT B.",
      employee: {
        reset: "Click here if you need to choose a different person and start over.",
      },
    },
  },
}

// Find a string by handle
//
// handle - (string) Full string handle (assignments.reset.success)
//
// returns - localized string, null if not found
function findString(handle) {
  let strings = localeStrings // Start with the full object

  const propertyNames = handle.split('.')
  do { // Loop through propertyNames
    const propertyName = propertyNames.shift()

    if (!strings[propertyName]) {
      new ErrorService('Locale', 'Unknown String Handle').report({handle: handle})
      break
    }

    if (propertyNames.length) { // Nesting deeper
      strings = strings[propertyName]
    } else { // Found it!
      return strings[propertyName]
    }
  } while (propertyNames.length)

  return null
}

export default {
  // Get a localized version of a string by handle.
  //
  // handle: (string)
  // args: String-specific arguments
  //
  t: function(handle, ...args) {
    let localeString = findString(handle)

    if (typeof(localeString) == 'function') {
      localeString = localeString(...args)
    }
    return localeString
  },
}
