import CustomFieldsPanelView from 'components/employees/CustomFieldsPanelView'
import EditCustomFieldsModalView from 'views/employees/custom_fields/edit_custom_fields_modal_view'
import { pureOrphanVue } from 'lib/vue/pureOrphanVue'

export default Marionette.LayoutView.extend({
  template: false,
  events: {
    'click .js-employee-custom-fields-edit-button': 'editCustomFields',
  },

  // NOTE: Custom fields' bindings are set up in `initialize()` function below
  bindings: {
  },

  initialize: function() {
    // Set up bindings for each of the custom fields in model.custom_fields. This includes both fields the employee has values for
    // as well as all of the current fields the employee can have.
    const fieldsCollection = this.model.get('custom_fields')
    if (fieldsCollection && fieldsCollection.length > 0) {
      fieldsCollection.each(function(fieldValueEntry) {
        this.addBinding(fieldValueEntry, `#udf_${fieldValueEntry.id}`, {
          observe: 'value_decorated',
        })
      }, this)
    }
    this.stickit()

    pureOrphanVue(CustomFieldsPanelView, "component.vue-custom-fields")
  },

  editCustomFields: function() {
    new EditCustomFieldsModalView({
      ajaxOptions: {
        wbGenericFailureMsg: "Sorry, we couldn't open the Edit window.",
      },
      model: this.model,
      _url: `${App.Util.getPageRootPath()}/custom_fields/edit`,
    })
  },
})
