import PrerenderedTableRowItemView from 'views/common/prerendered_table_row_item_view'

export default PrerenderedTableRowItemView.extend({
  triggers: {
    'click .js-toggle-archive-employee-group': {
      event: "employeeGroup:toggleArchive",
      stopPropagation: false,
      preventDefault: true,
    },

    'click .js-delete-employee-group': {
      event: "employeeGroup:delete",
      stopPropagation: false,
      preventDefault: true,
    },
  },

  modelEvents: {
    "change:archived": "archivedChanged",
  },

  onShow: function() {
    // If the dataset is empty, this row won't have a model
    if (!this.model) {return}

    this.renderArchivedState(this.model.get("archived"))
  },

  archivedChanged: function(_model, value) {
    this.renderArchivedState(value)
    // bubble up to collectionview
    this.trigger("model:change:archived")
  },

  renderArchivedState: function(value) {
    this.$el.toggleClass("archived", value)
    this.$el.find(".action-col .archive-text").text(value ? "Unarchive" : "Archive")
  },
})
