import UserImportSheetColumn from 'models/user_import/user_import_sheet_column'

const CollectionType = Backbone.Collection.extend({
  model: UserImportSheetColumn,

  // Initialize collection
  //
  // model: Array of SheetColumn data objects
  // options: id: UserImport ID
  initialize: function(_model, options){
    this.id = options.id
  },

  url: function(){
    return `/user_imports/${  this.id  }/sheet_columns`
  },

  // List of SheetColumns that have not been matched to a Field or ignored
  //
  // Returns UserImportSheetColumnCollection
  unmatched: function(){
    const filtered = this.filter(function (column) {
      return !column.isMatched() && !column.isIgnored()
    })
    return new CollectionType(filtered, {})
  },

  // Clear our the match state for any SheetColumn with a particular field key
  //
  // fieldKey: String, 'a4s_employee_email'
  //
  // Returns nothing
  unsetFieldKey: function(fieldKey) {
    _.each(this.where({field_key: fieldKey}), function(column){
      column.set('field_key', null)
    })
  },

  // API request to save current column match state to the UserImport
  //
  // Callback: function to call when complete
  //
  // Returns nothing
  saveMatches: function(callback){
    let data = []
    data = this.map(function(c){
      return {
        col_idx: c.get('col_idx'),
        field_key: c.isIgnored() ? 'ignored' : c.get('field_key'),
      }
    })

    const url = `/user_imports/${  this.id  }/match_columns`
    $.ajax({
      url: url,
      type: 'POST',
      dataType: 'json',
      contentType: 'application/json',
      progressBar: false,
      data: JSON.stringify({columns_map: data}),
    }).done(function(data){
      callback(data.errors)
    })
  },

  // API request to confirm the column match state, indicating that everything
  // is complete and ready to move on to the next step
  //
  // Post event when validation has started
  //
  // Returns nothing
  confirmColumns: function(){
    App.vent.trigger('admin_user_import:column_confirm_started')

    const url = `/user_imports/${  this.id  }/confirm_columns`
    $.ajax({
      url: url,
      type: 'POST',
    }).done(function(){
      App.vent.trigger('admin_user_import:employee_validation_started')
    }).fail(function(xhr){
      App.Util.showAjaxFlashNotice(xhr)
    })
  },

})

export default CollectionType
