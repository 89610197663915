<!-- eslint-disable vue/no-v-model-argument -->
<template>
  <div>
    <h2>Set up permissions for {{ firstName }}</h2>
    <PermissionsWizard
      ref="wizard"
      v-model:policy="policy"
      :firstName="firstName"
      @done="done"
    />
  </div>
</template>

<script>
  import PermissionsWizard from 'components/permissions/PermissionsWizard'
  import { mapState } from 'vuex'
  import Policy from 'models/policy_generator/policy'
  import { PolicyUnmarshaller } from 'models/policy_generator/policy_unmarshaller'

  export default {
    name: 'the-admin-permissions-wizard-root',
    components: {
      PermissionsWizard,
    },

    data: () => {
      return {
        policy: new Policy(),
      }
    },

    computed: {
      firstName() {
        return this.pageContext.user.first_name
      },

      ...mapState([
        'pageContext',
      ]),
    },

    beforeMount() {
      if (this.pageContext.permission_sets) {
        this.policy = new PolicyUnmarshaller(this.pageContext.permission_sets)
      }
    },

    mounted() {
      // Ok, so... the only way you should be getting to this wizard is via the Admin Edit form. We all admin it's a messy setup.
      // Admin Edit sets the payloadUser and a callback, then loads up this wizard, expecting the data to persist during the
      // Turbolinks load. When the wizard is finish, we send the data back the Admin Edit and populate it into the form.
      //
      // If there's no payloadUser defined in the sector, that's a problem. When the wizard is finished, the data has nowhere to
      // go. If you got here, you probably refreshed the page during the wizard. So, we're going to boot you back to whereever you
      // came from, since the end results will be unfortunately broken.
      if (!App.currentSector.payloadUser) {
        this.$refs.wizard.history.back()
      }
    },

    methods: {
      done() {
        App.vent.trigger('admin_policy_generator:wizard_finished', this.policy)
      },
    },

  }


</script>

