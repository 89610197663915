<template>
  <div>
    <PageHeader
      lead="Manage account-level feature flag settings"
    >
      <template #title>
        <span v-html="pageTitle" />
      </template>
    </PageHeader>

    <Spinner v-if="isLoading" ref="loader" />
    <template v-else>
      <div
        v-if="hasFlags"
        class="form-horizontal"
        ref="form"
      >
        <section v-for="(category, i) in categories_ordered" :key="i">
          <p class="feature-list-section-title">{{ category }}</p>
          <FormFieldCheckbox
            v-for="({ flag, enabled }, index) in categorizedFlags.get(category)"
            :disabled="updatingFlags[flag]"
            :hint="getFlagDescription(flag)"
            :key="index"
            :name="flag"
            :label="getFlagLabel(flag)"
            :value="enabled"
            gridColumns="ml-4 col-xs-12"
            @input="isEnabled => handleFlagInput(flag, isEnabled)"
          />
        </section>
      </div>
      <AlertMessage
        v-else
        message="Unable to load early access flag information"
        ref="noFlagsAlert"
      />
    </template>
  </div>

</template>
<script>
  import AlertMessage from 'components/common/AlertMessage'
  import FormFieldCheckbox from 'components/common/FormFieldCheckbox'
  import PageHeader from 'components/common/PageHeader'
  import Spinner from 'components/common/Spinner'
  import Util from 'lib/util'
  import FEATURE_FLAG_CONFIG from 'active_feature/feature_flags.yml' // eslint-disable-line import/extensions

  import {
    fetchFeatureFlags,
    patchFeatureFlag,
  } from 'services/superadmin/featureFlagsService'

  export default {
    name: 'the-feature-flags-index-root',
    components: {
      AlertMessage,
      FormFieldCheckbox,
      PageHeader,
      Spinner,
    },
    props: {
      accountId: {
        type: [String, Number],
        required: true,
      },
      accountName: {
        type: String,
        required: true,
      },
    },
    data () {
      return {
        categories_ordered: FEATURE_FLAG_CONFIG.categories_ordered,
        featureFlags: null,
        isLoading: false,
        updatingFlags: {},
      }
    },
    computed: {
      hasFlags () {
        return this.featureFlags && this.featureFlags.length > 0
      },
      categorizedFlags () {
        const categories = new Map()
        for (const featureFlag of this.featureFlags) {
          const category = this.getFlagCategory(featureFlag.flag)
          const value = categories.get(category)
          if (value) {
            value.push(featureFlag)
          } else {
            categories.set(category, [featureFlag])
          }
        }

        for (const [, value] of categories) {
          value.sort((a, b) => this.getFlagLabel(a.flag) > this.getFlagLabel(b.flag))
        }

        return categories
      },
      pageTitle () {
        return `Feature Flags for ${this.accountName}`
      },
    },
    created () {
      this.fetchData()
    },
    methods: {
      fetchData () {
        this.isLoading = true

        fetchFeatureFlags(this.accountId)
          .then(response => {
            this.featureFlags = response
          })
          .always(() => {
            this.isLoading = false
          })
      },

      getFlagCategory (flag) {
        return FEATURE_FLAG_CONFIG.flags[flag]?.category || "Other"
      },

      getFlagDescription (flag) {
        return FEATURE_FLAG_CONFIG.flags[flag]?.description
      },

      getFlagLabel (flag) {
        return FEATURE_FLAG_CONFIG.flags[flag]?.label
      },

      handleFlagInput (flag, isChecked) {
        this.updatingFlags[flag] = true

        patchFeatureFlag(this.accountId, flag, isChecked)
          .then(response => {
            Util.showFlashNotice('Feature flag updated')
            this.featureFlags = response
          })
          .fail(response => {
            console.error(response.error())
          })
          .always(() => {
            delete this.updatingFlags[flag]
          })
      },
    },
  }
</script>
